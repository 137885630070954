import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Typography,
} from "@mui/material";

const MultiSelect = ({
  label,
  value,
  onChange,
  options,
  error,
  helperText,
  fieldId,
  sx,
}) => {
  // Find the labels for the selected values
  const selectedLabels = value
    .map((selectedId) => {
      const selectedOption = options.find((option) => option.id === selectedId);
      return selectedOption ? selectedOption.label : "";
    })
    .filter((label) => label !== "");

  return (
    <FormControl
      fullWidth
      margin="dense"
      error={error}
      sx={{ mb: 1, ...sx }}
      variant="outlined"
      size="small"
    >
      <InputLabel id={`multiple-select-label-${fieldId}`}>{label}</InputLabel>
      <Select
        labelId={`multiple-select-label-${fieldId}`}
        id={`multiple-select-${fieldId}`}
        multiple
        value={value}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        renderValue={() => selectedLabels.join(", ")}
      >
        {Array.isArray(options) ? (
          options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              <Checkbox checked={value.indexOf(option.id) > -1} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No Options Available</MenuItem>
        )}
      </Select>
      {helperText && (
        <Typography variant="body2" color="error">
          {helperText}
        </Typography>
      )}
    </FormControl>
  );
};

export default MultiSelect;
