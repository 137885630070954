import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Divider, Grid, Typography } from "@mui/material";
import FieldMapComponent from "../molecules/FieldMapComponent";
import { useLocation } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { handleMessage } from "../../utils/Utilities";
import { PolygonData } from "../../models/polygonData.interface";
import { FieldData } from "../../models/fieldData.interface";
import OnBoardingFieldForm from "../organisms/onBoardingFieldDetails";
import { Organization } from "../../models/organization.interface";

const validationSchema = yup.object({
  fieldName: yup.string().required("Field Name is required"),
  farmId: yup.string().optional(),
  irrDry: yup
    .string()
    .oneOf(["Irrigated", "Dry"])
    .required("Irrigation/Dryland status is required"),
  size: yup.string().required("Size is required"),
  canSave: yup.boolean(),
});

type OnBoardingFieldManagementProps = {
  onSubmit: (data: any) => void;
  onCanSaveChange: (canSave: boolean) => void;
  onSizeChange: (size: string | undefined) => void;
  canSave: boolean;
  polygonData: any;
  selectedOrganization: Organization | undefined;
};

type OnBoardingFieldFormRef = {
  submitForm: () => void;
};

const OnBoardingField = forwardRef<
  OnBoardingFieldFormRef,
  OnBoardingFieldManagementProps
>(
  (
    {
      onSubmit,
      onCanSaveChange,
      onSizeChange,
      selectedOrganization,
      canSave,
    },
    ref
  ) => {
    const location = useLocation();
    const initialFieldData: FieldData = location.state?.fieldData;
    const [fieldData, setFieldData] = useState<FieldData | undefined>(
      initialFieldData
    );
    const formRef = useRef<OnBoardingFieldFormRef>(null);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [polygonData, setPolygonData] = useState<PolygonData>();

    const methods = useForm({
      resolver: yupResolver(validationSchema),
      defaultValues: {
        fieldName: initialFieldData?.fieldName || "",
        farmId: initialFieldData?.farmId || "",
        irrDry: initialFieldData?.irrDry || "Dry",
        size: initialFieldData?.area || "",
      },
    });

    const {
      setValue,
      control,
      formState: { errors },
    } = methods;

    useImperativeHandle(ref, () => ({
      submitForm() {
        formRef.current?.submitForm();
      },
    }));

    useEffect(() => {
      if (location.state?.fieldData !== fieldData) {
        setFieldData(location.state?.fieldData);
      }

      const onMessage = (event: MessageEvent) => {
        const data = handleMessage(event);
        if (data) {
          setPolygonData(data);
        }
      };

      window.addEventListener("message", onMessage);
      return () => window.removeEventListener("message", onMessage);
    }, [location.state?.fieldData, fieldData]);

    useEffect(() => {
      if (fieldData?.area) {
        setValue("size", `${fieldData.area} ha`);
      }
    }, [fieldData, setValue]);

    useEffect(() => {
      if (polygonData?.area) {
        setValue("size", polygonData.area);
      }
    }, [polygonData, setValue]);

    useEffect(() => {
      if (polygonData === undefined) {
        setValue("canSave", false);
        onCanSaveChange(false);
      }

      if (polygonData?.area) {
        const size = polygonData.area;
        setValue("size", size);
        onSizeChange(size);
      }

      if (polygonData?.canSave !== undefined) {
        canSave = polygonData.canSave;
        setValue("canSave", canSave);
        onCanSaveChange(canSave);
      }
    }, [polygonData, setValue, onCanSaveChange, onSizeChange, canSave]);

    return (
      <FormProvider {...methods}>
        <Grid container>
          <Grid item xs={12} padding={"10px"}>
            <Typography variant="h5">Create your first field</Typography>
            <Divider sx={{ marginTop: 1 }} />
          </Grid>
          <Grid item xs={9}>
            <FieldMapComponent
              height="500px"
              fieldData={fieldData}
              onLoad={() => setMapLoaded(true)}
            />
          </Grid>
          <Grid item xs={3}>
            <OnBoardingFieldForm
              ref={formRef}
              initialFieldData={initialFieldData}
              onFieldDataChange={onSubmit}
              polygonData={polygonData}
              control={control}
              errors={errors}
              setValue={setValue}
              selectedOrganization={selectedOrganization}
            />
          </Grid>
        </Grid>
      </FormProvider>
    );
  }
);

export default OnBoardingField;
