import { useState, useEffect, useRef } from "react";
import { AlertColor, Grid, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import ActivityDialog from "../organisms/activityDialog";
import { useFetchData } from "../../hooks/useFetchData";
import NotesDialog from "../organisms/notesDialog";
import OrganizationDialog from "../organisms/organisationDialog";
import {
  createNote,
  createOrganization,
  getLegalEntities,
  getSeasonStages,
  getActivityStatuses,
  createActivity,
} from "../../services/api-ffm-service";
import { getCrops, getProducts } from "../../services/api-car-service";
import theme from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import { LegalEntity } from "../../models/legalEntity.interface";
import { getFields } from "../../services/api-gs-service";
import { handleApiResponse } from "../../utils/Utilities";
import { useNavigate, useLocation } from "react-router-dom";
import NotificationDialog from "../organisms/notificationDialog";
import CustomSnackbar from "./CustomSnackbar";
import { useConfig } from "../../context/ConfigContextTest";

const options = ["New Organisation", "New Note", "New Activity", "New Field"];

export default function QuickAdd() {
  const [openOrg, setOpenOrg] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [openAct, setOpenAct] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<any | null>(null);
  const { selectedOrganization } = useConfig();
  const [dialogOpen, setDialogOpen] = useState({ open: false, title: "", message: ""});

  const [legalEntities, setLegalEntities] = useState<LegalEntity[]>([]);
  const [activityStatuses, setActivityStatuses] = useState<any[]>([]);
  const [seasonStages, setSeasonStages] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [fields, setFields] = useState<any[]>([]);
  const [products, setProducts] = useState<any>();
  const [crops, setCrops] = useState<any>();
  const navigate = useNavigate();
  const location = useLocation();
  const snackbarRef = useRef<{ handleOpen: (title: string, msg: string | null, sev: AlertColor) => void }>(null);

  useFetchData(getActivityStatuses, setActivityStatuses);
  useFetchData(getSeasonStages, setSeasonStages);
  useFetchData(getLegalEntities, setLegalEntities);
  useFetchData(getCrops, setCrops);
  useFetchData(getProducts, setProducts);
  useFetchData(getFields, setFields, undefined, [
    selectedOrganization?.partyIdentifier ?? 0,
  ]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (index: number) => {
    setAnchorEl(null);
    switch (index) {
      case 0:
        setOpenOrg(true);
        break;
      case 1:
        setOpenNote(true);
        break;
      case 2:
        setOpenAct(true);
        break;
      case 3:
        navigate("/fields");
        break;
      default:
        break;
    }
  };

  const handleCloseForm = () => {
    setOpenOrg(false);
    setOpenNote(false);
    setOpenAct(false);
  };

  const handleSubmitNote = async (formData: any) => {
    let response;
    const newFormData: any = {
      azureUserId: selectedOrganization?.azureUserId,
      noteId: formData.noteId,
      partyId: selectedOrganization?.partyId,
      Properties: formData.noteProperty,
      noteDocuments: [],
    };

    const convertFileToBase64 = (file: File): Promise<string> =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>
          resolve(reader.result?.toString().split(",")[1] || "");
        reader.onerror = (error) => reject(error);
      });

    // Process noteDocuments array
    if (formData.noteDocuments) {
      for (const doc of formData.noteDocuments) {
        const newDoc: any = {
          name: doc.name,
          noteDocumentId: doc.noteDocumentId,
          documentExtention: doc.documentExtention
        };

        if (doc.attachment && doc.noteDocumentId === 0) {
          newDoc.attachment = await convertFileToBase64(doc.attachment);
        }

        newFormData.noteDocuments.push(newDoc);
      }
    }

    newFormData.title = formData.title;
    newFormData.description = formData.description;
    newFormData.noteTypeId = formData.noteTypeId;
    newFormData.fieldId = formData.fieldId;

    try {
      response = await createNote(newFormData);
    } catch (error) {
      console.error("Error creating note:", error);
    }

    handleCloseForm();
    window.location.reload();
    navigate(location.pathname);
    handleApiResponse(response, showSnackbar, {title: "Note successfully added"});
  };

  const handleSubmitOrg = async (formData: any) => {
    let response;
    try {
      formData.legalEntityTypeId = legalEntities.find(
        (nt) => nt.name === formData.legalEntityTypeName
      )?.legalEntityTypeId;
  
      const transformedData = {
        Name: formData.orgName || "",
        VATNumber: formData.vatNumber || "",
        LegalEntityTypeId: formData.legalEntityTypeId || "",
        RegistrationNumber: formData.registrationNumber || "",
        ContactDetail: [
          {
            Name: formData.contactName || "",
            Surname: formData.contactSurname || "",
            Contacts: [
              { Type: "Email", Details: formData.contactEmail || "" },
              { Type: "Mobile", Details: formData.contactNumber || "" },
            ],
          },
        ],
        PhysicalAddress: {
          AddressLine1: formData.physicalAddressLine1 || "",
          AddressLine2: formData.physicalAddressLine2 || "",
          City: formData.physicalCity || "",
          Code: formData.physicalCode || "",
        },
        postalAddress: {
          AddressLine1: formData.postalAddressLine1 || "",
          AddressLine2: formData.postalAddressLine2 || "",
          City: formData.postalCity || "",
          Code: formData.postalCode || "",
        },
      };  

      response = await createOrganization(transformedData);
    } catch (error) {
      console.error("Error submitting organisation:", error);
    }

    handleCloseForm();
    window.location.reload();
    navigate(location.pathname);
    handleApiResponse(response, showSnackbar, {title: "Organisation successfully added"});
  };

  const handleSubmitAct = async (formData: any) => {
    let response;
    formData.partyId = selectedOrganization?.partyId;
    formData.statusId = formData.activityStatusId;
    
    const endDate = new Date(formData.endDate);
    const today = new Date();
    if (formData.statusId === 12 && endDate > today) {
      if (snackbarRef.current) {
        snackbarRef.current.handleOpen(
          "Create failed!",
          "Activity status can not be set to DONE if the end date is future dated.",
          "warning"
        );
      }
    } 
    else 
    {
      try {
        response = await createActivity(formData);
      } catch (error) {
        console.error("Error creating activity:", error);
      }
    }

    handleCloseForm();
    if(response){
      window.location.reload();
      navigate(location.pathname);
    }
    handleApiResponse(response, showSnackbar, {title: "Activity successfully added"});
  };

  const showSnackbar = (title, message, severity) => {
    if (snackbarRef.current) {
      snackbarRef.current.handleOpen(title, message, severity);
    }
  };

  return (
    <>
      <CustomSnackbar ref={snackbarRef} />
      <Grid>
        <NotesDialog
          isOpen={openNote}
          onClose={handleCloseForm}
          onSubmit={handleSubmitNote}
          formData={undefined}
        />
        <OrganizationDialog
          isOpen={openOrg}
          onClose={handleCloseForm}
          onSubmit={handleSubmitOrg}
          formData={undefined}
          legalEntities={legalEntities}
        />
        <ActivityDialog
          isOpen={openAct}
          onClose={handleCloseForm}
          onSubmit={handleSubmitAct}
          formData={undefined}
        />
        <NotificationDialog
            open={dialogOpen.open}
            handleClose={() => setDialogOpen({ open: false, message: "", title: "" })}
            title={dialogOpen.title}
            text={dialogOpen.message}
          />
        <Tooltip title="Quick Add">
          <IconButton
            aria-label="edit"
            sx={{
              color: "white",
              backgroundColor: theme.palette.secondary.main,
              width: "30px",
              height: "30px",
              "&:hover": {
                backgroundColor: theme.palette.secondary.light,
              },
            }}
            onClick={handleMenu}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {options?.map((option, index) => (
            <MenuItem
              key={option}
              selected={index === selectedIndex}
              onClick={() => handleMenuItemClick(index)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </Grid>
    </>
  );
}
