import { useState } from "react";
import "./App.css";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Box, ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import theme from "./theme";
import LogoutPage from "./components/pages/loggedOut";
import SignUp from "./components/organisms/signUp/signUpPage";
import { ConfigProvider } from "./context/ConfigContextTest";
import AppContent from "./components/organisms/AppContent";

const App = ({ instance }) => {
  
  const [open, setOpen] = useState(true);
  const [currentPage, setCurrentPage] = useState<"logout" | "signup">("logout");

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleSignUpClick = () => {
    setCurrentPage("signup");
  };

  const handleCancelClick = () => {
    setCurrentPage("logout");
  };

  return (
    <ThemeProvider theme={theme}>
      <MsalProvider instance={instance}>
        <ConfigProvider>
          <UnauthenticatedTemplate>
            <Box
              style={{
                backgroundColor: theme.palette.primary.main,
                minHeight: "111vh",
              }}
            >
              {currentPage === "logout" && (
                <LogoutPage onSignUpClick={handleSignUpClick} />
              )}
              {currentPage === "signup" && (
                <SignUp onCancelClick={handleCancelClick} />
              )}
            </Box>
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <BrowserRouter>
              <AppContent
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
              />
            </BrowserRouter>
          </AuthenticatedTemplate>
        </ConfigProvider>
      </MsalProvider>
    </ThemeProvider>
  );
};

export default App;