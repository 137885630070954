import React, { useEffect, useState } from "react";
import {
  Grid,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import DynamicFormDialog from "../molecules/dialog";
import { useDropzone } from "react-dropzone";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const MAX_FILE_SIZE = Number(process.env.REACT_APP_MAX_FILE_SIZE) || 10 * 1024 * 1024;

const baseSchema = yup.object().shape({
  fileName: yup.string().required("File Name is required"),
  docType: yup
    .number()
    .required("Document Type is required")
    .min(1, "Please select a Document Type"),
  file: yup.mixed(),
});

const getValidationSchema = (isEditing) => {
  return yup.object().shape({
    documents: yup.array().of(
      baseSchema.shape({
        file: isEditing
          ? yup.mixed().notRequired()
          : yup.mixed().required("File is required"),
      })
    ),
  });
};

const DocumentDialog = ({
  isOpen,
  onClose,
  onSubmit,
  docTypes,
  formData,
  isEditing = false,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      documents: [],
    },
    resolver: yupResolver(getValidationSchema(isEditing)),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [fileErrors, setFileErrors] = useState<string[]>([]);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "documents",
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.ms-excel": [".xls", ".xlsx", ".csv"],
      "application/msword": [".doc", ".docx"],
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/tiff": [".tif", ".tiff"],
      "image/svg+xml": [".svg"],
      "image/vnd.adobe.photoshop": [".psd"],
    },
    maxSize: MAX_FILE_SIZE,
    onDrop: (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) => ({
        fileName: file.name.replace(/\.[^/.]+$/, ""),
        docType: docTypes[0]?.value || 0,
        file,
      }));
      if (isEditing) {
        setValue("documents", newFiles);
      } else {
        append(newFiles);
      }
      setFileErrors([]); 
    },
    onDropRejected: (rejectedFiles) => {
      const errors = rejectedFiles.map(
        (rejectedFile) => `File ${rejectedFile.file.name} is too large. Max file size is 10MB.`
      );
      setFileErrors(errors);
    },
  });

  const handleDialogClose = () => {
    reset({ documents: [] });
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      if (formData && isEditing) {
        reset({ documents: formData.documents });
      } else {
        reset({ documents: [] });
      }
      setFileErrors([]);
    } else {
      reset({ documents: [] });
    }
  }, [isOpen, formData, reset, isEditing]);
  

  const handleFormSubmit = async (data) => {
    try {
      setIsLoading(true);
      const schema = getValidationSchema(isEditing);
      await schema.validate(data, { abortEarly: false });
      onSubmit(data);
    } catch (error) {
      console.error("Validation Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const formContent = (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <DialogContent dividers sx={{ pt: 1, pb: 1 }}>
        <Grid container spacing={2} sx={{ padding: 2 }}>
          {fields.map((field, index) => (
            <React.Fragment key={field.id}>
              <Grid item xs={!isEditing && fields.length > 1 ? 5 : 6}>
                <Controller
                  name={`documents.${index}.fileName`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="File Name"
                      fullWidth
                      size="small"
                      margin="dense"
                      error={!!errors?.documents?.[index]?.fileName}
                      helperText={errors?.documents?.[index]?.fileName?.message}
                      onChange={(e) => {
                        field.onChange(e);
                        setValue(`documents.${index}.fileName`, e.target.value);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={!isEditing && fields.length > 1 ? 5 : 6}>
                <Controller
                  name={`documents.${index}.docType`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Document Type"
                      fullWidth
                      size="small"
                      margin="dense"
                      error={!!errors?.documents?.[index]?.docType}
                      helperText={errors?.documents?.[index]?.docType?.message}
                      onChange={(e) => {
                        field.onChange(e);
                        setValue(
                          `documents.${index}.docType`,
                          parseInt(e.target.value)
                        );
                      }}
                    >
                      {docTypes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              {!isEditing && fields.length > 1 && (
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1 }}
                    onClick={() => remove(index)}
                  >
                    Remove
                  </Button>
                </Grid>
              )}
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <Box
              {...getRootProps()}
              sx={{
                border: "2px dashed #cccccc",
                padding: "20px",
                pt: "10px",
                pb: "10px",
                textAlign: "center",
                cursor: "pointer",
                borderRadius: "5px",
              }}
            >
              <input {...getInputProps()} />
              <Typography>
                {isEditing
                  ? "Replace file: Drag 'n' drop a file here, or click to select a file"
                  : "Drag 'n' drop files here, or click to select files"}
              </Typography>
            </Box>
            {fileErrors.length > 0 && (
              <Typography color="error">
                {fileErrors.join(", ")}
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          startIcon={<SaveIcon />}
          disabled={isLoading}
        >
          {isEditing ? "Update" : "Save"}
          {isLoading && (
            <CircularProgress color="inherit" size={"15px"} sx={{ ml: 2 }} />
          )}
        </Button>
      </DialogActions>
    </form>
  );

  return (
    <DynamicFormDialog
      isOpen={isOpen}
      onClose={handleDialogClose}
      onSubmit={handleSubmit(handleFormSubmit)}
      title={isEditing ? "Update Documents" : "Add Documents"}
      formContent={formContent}
    />
  );
};

export default DocumentDialog;