import React, { useState, useEffect } from "react";
import {
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Grid,
  Chip,
  Box,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DynamicFormDialog from "../molecules/dialog";
import CancelIcon from "@mui/icons-material/Cancel";
import { z } from "zod";

const MAX_FILE_SIZE = Number(process.env.REACT_APP_MAX_FILE_SIZE) || 10 * 1024 * 1024;

const noteSchema = z.object({
  Title: z.string().min(1, "Title is required"),
  Description: z.string().min(1, "Description is required"),
  Attachment: z.string().optional(),
});

interface ActivityNotesDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (note: {
    Name: string;
    Title: string;
    Description: string;
    Attachment: string | null;
    DocumentExtention: string | null;
  }) => void;
}

const ActivityNotesDialog: React.FC<ActivityNotesDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [noteTitle, setNoteTitle] = useState("");
  const [noteDescription, setNoteDescription] = useState("");
  const [attachment, setAttachment] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleAttachmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.size > MAX_FILE_SIZE) {
        setAttachment(null);
      } else {
        setAttachment(file);
      }
    }
  };

  const convertFileToBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString().split(",")[1] || "");
      reader.onerror = (error) => reject(error);
    });

  const handleNoteSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      setIsLoading(true);

      let base64Attachment: string | null = null;
      let documentExtension: string | null = null;

      if (attachment) {
        base64Attachment = await convertFileToBase64(attachment);
        documentExtension = attachment.name.split('.').pop() || "";
      }

      const newNote = {
        Name: noteTitle,
        Title: noteTitle,
        Description: noteDescription,
        Attachment: base64Attachment,
        DocumentExtention: documentExtension,
      };

      noteSchema.parse({
        Title: noteTitle,
        Description: noteDescription,
      });

      onSubmit(newNote);
      handleClose();
    } catch (error) {
      console.error("Error submitting note: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setNoteTitle("");
    setNoteDescription("");
    setAttachment(null);
    onClose();
  };

  useEffect(() => {
    if (!isOpen) {
      handleClose();
    }
  }, [isOpen]);

  const formContent = (
    <form onSubmit={handleNoteSubmit}>
      <DialogContent dividers sx={{ pt: 1, pb: 1 }}>
        <Grid container spacing={1} sx={{ padding: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            label="Title"
            value={noteTitle}
            onChange={(e) => setNoteTitle(e.target.value)}
            sx={{ mb: 2 }}
            required
          />
          <TextField
            fullWidth
            multiline
            rows={1}
            variant="outlined"
            label="Description"
            value={noteDescription}
            onChange={(e) => setNoteDescription(e.target.value)}
            sx={{ mb: 2 }}
            required
          />
          <Button variant="contained" component="label" fullWidth sx={{ mb: 2 }}>
            {attachment ? "Upload and Replace Attachment" : "Upload Attachment"}
            <input
              type="file"
              hidden
              onChange={handleAttachmentChange}
              accept=".pdf,.xls,.xlsx,.csv,.doc,.docx,.png,.jpg,.jpeg,.tif,.tiff,.svg,.psd"
            />
          </Button>
          {attachment && (
            <Box sx={{ width: '100%', mb: 2 }}>
              <Chip
                label={attachment.name}
                onDelete={() => setAttachment(null)}
                deleteIcon={<CancelIcon />}
                sx={{ width: '100%' }}
              />
            </Box>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          startIcon={<SaveIcon />}
          disabled={isLoading}
        >
          Save
          {isLoading && (
            <CircularProgress color="inherit" size={"15px"} sx={{ ml: 2 }} />
          )}
        </Button>
      </DialogActions>
    </form>
  );

  return (
    <DynamicFormDialog
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={undefined}
      title={"Add Note"}
      formContent={formContent}
    />
  );
};

export default ActivityNotesDialog;