import { useRef, useState, useCallback, useEffect } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Grid,
  Paper,
  CircularProgress,
  List,
  ListItem,
} from "@mui/material";
import theme from "../../theme";
import {
  createFarm,
  createOrganization,
  getLegalEntities,
  getOrganizations,
} from "../../services/api-ffm-service";
import { LegalEntity } from "../../models/legalEntity.interface";
import { fetchData, useFetchData } from "../../hooks/useFetchData";
import WelcomeCard from "../molecules/welcome";
import OnBoardingOrganisationForm from "./onBoardingOrganisationDialog";
import { generateGUID, handleMessage } from "../../utils/Utilities";
import { FieldMetadata } from "../../models/fieldMetadata.interface";
import { PolygonData } from "../../models/polygonData.interface";
import {
  createFarmFieldLink,
  createField,
} from "../../services/api-gs-service";
import OnBoardingField from "./onBoardingField";
import { GoogleLocation } from "../../interfaces/googleLocation.interface";

import uploadIcon from "../../assets/icons/uploadYellow.svg";
import polygonIcon from "../../assets/icons/polygonYellow.svg";
import circleIcon from "../../assets/icons/circleYellow.svg";
import sectorIcon from "../../assets/icons/sectorYellow.svg";
import segmentIcon from "../../assets/icons/segmentYellow.svg";
import { Organization } from "../../models/organization.interface";


const steps = ["Welcome", "Add Organisation & Farm", "Add Field"];

const stepDescriptions = [
  "To kick start the process, complete your Organisation details:​",
  "Creating your first fields:",
];

const stepCaptions = [
  [
    "Complete all mandatory fields",
    "If your entity has a VAT number or Registration Number, please enter a valid number.",
    "A Farm name is optional.",
    "Click NEXT to continue the onboarding process.",
  ],
  [
    "Enter the required details for your fields.",
    "Ensure the coordinates are correct.",
    "Click FINISH to complete the process.",
  ],
];

const StepperForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [legalEntities, setLegalEntities] = useState<LegalEntity[]>([]);
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [polygonData, setPolygonData] = useState<PolygonData>();
  useFetchData(getLegalEntities, setLegalEntities);
  const orgFormRef = useRef<{ submitForm: () => void }>(null);
  const fieldFormRef = useRef<{ submitForm: () => void }>(null);
  const [canSave, setCanSave] = useState(true);
  const [size, setSize] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState<Organization>();

  function roundCoordinates(coords: number[][][]): number[][][] {
    return coords.map((coordGroup) =>
      coordGroup.map(([lat, long]) => [
        parseFloat(lat.toFixed(5)),
        parseFloat(long.toFixed(5)),
      ])
    );
  }

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      const data = handleMessage(event);
      if (data) {
        setPolygonData(data);
      }
    };

    window.addEventListener("message", onMessage);
    return () => window.removeEventListener("message", onMessage);
  }, []);

  const handleFieldSubmit = async (data: any) => {
    const farmId = data.farmId === "" ? null : data.farmId;
    const cropperRef = generateGUID();
    let coords = polygonData
      ? JSON.stringify(roundCoordinates(polygonData.coordinates))
      : "";
    const updatedOrganizations = await getOrganizations();

    const fieldMetadata: FieldMetadata = {
      cropperRef,
      coords,
      partyId: updatedOrganizations[0]?.partyIdentifier,
      name: data.fieldName,
      metadata: {
        irrDry: data.irrDry,
      },
    };

    try {
      setIsLoading(true);
      const response = await createField(fieldMetadata);
      let fieldId = response.data;
      if (farmId !== null && farmId !== undefined) {
        await createFarmFieldLink(fieldId, farmId);
      }
      window.location.reload();
    } catch (error) {
      console.error("Failed to finish:", error);
    }
    setIsLoading(false);
  };

  const handleNext = useCallback(() => {
    if (activeStep === 1) {
      orgFormRef.current?.submitForm();
    } else if (activeStep === steps.length - 1) {
      fieldFormRef.current?.submitForm();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [activeStep]);

  const handleOrgSubmit = async (formData: any) => {
    try {
      setIsLoading(true);
      // console.log(formData);
      const location = formData.selectedLocation as GoogleLocation;

      const addressLine1 = location ? location.address : "";
      const addressLine2 = location
        ? `${location.coordinates.lat}, ${location.coordinates.lng}`
        : formData.physicalAddressLine2 || "";
      const city = location
        ? location.components.city
        : formData.physicalCity || "";
      const postalCode = location
        ? location.components.postalCode
        : formData.physicalCode || "";

      const transformedData: any = {
        Name: formData.orgName || "",
        VATNumber: formData.vatNumber || "",
        LegalEntityTypeId: formData.legalEntityTypeId || "",
        RegistrationNumber: formData.registrationNumber || "",
        ContactDetail: [
          {
            Name: formData.contactName || "",
            Surname: formData.contactSurname || "",
            Contacts: [
              { Type: "Email", Details: formData.contactEmail || "" },
              { Type: "Mobile", Details: formData.contactNumber || "" },
            ],
          },
        ],
      };

      if (addressLine1) {
        transformedData.PhysicalAddress = {
          AddressLine1: addressLine1,
          AddressLine2: addressLine2,
          City: city,
          Code: postalCode,
        };
      }

      // console.log("FORMDATA - ORG CREATE", transformedData);

      await createOrganization(transformedData);

      await fetchData(getOrganizations, setOrganizations);
      const updatedOrganizations = await getOrganizations();
      const createData = {
        name: formData.farmName,
        partyId: updatedOrganizations[0]?.partyId,
      };
      setSelectedOrganization(updatedOrganizations[updatedOrganizations.length-1]);
      await createFarm(createData);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } catch (error) {
      console.error("Failed to create organisation:", error);
    }
    setIsLoading(false);
  };

  const stepContent = [
    <WelcomeCard key="welcome" />,
    <OnBoardingOrganisationForm
      key="orgForm"
      ref={orgFormRef}
      onSubmit={handleOrgSubmit}
      legalEntities={legalEntities}
    />,
    <OnBoardingField
      key="fieldForm"
      ref={fieldFormRef}
      polygonData={polygonData}
      onSubmit={handleFieldSubmit}
      onCanSaveChange={setCanSave}
      onSizeChange={setSize}
      canSave={canSave}
      selectedOrganization={selectedOrganization}
    />,
  ];

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{ mt: 6, px: 3 }}
    >
      <Stepper
        activeStep={activeStep}
        sx={{
          mb: 3,
          pt: 1,
          width: "100%",
          maxWidth: "1200px",
        }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              sx={{
                fontSize: "1.25rem",
                fontWeight: "bold",
                padding: "10px",
                borderRadius: 1,
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <Grid container sx={{ height: "90vh" }}>
        {activeStep > 0 && (
          <Grid item xs={3}>
            <Paper
              elevation={3}
              sx={{
                height: "90%",
                p: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.background.paper,
              }}
            >
              <Typography variant="h4">Step {activeStep + 1}</Typography>
              <Typography variant="body2" sx={{ mt: 2 }}>
                {stepDescriptions[activeStep - 1]}
              </Typography>
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 4,
                }}
              >
                {stepCaptions[activeStep - 1].map((caption, index) => (
                  <ListItem key={index} sx={{ display: "list-item", pl: 0 }}>
                    <Typography variant="caption">{caption}</Typography>
                  </ListItem>
                ))}
              </List>
              <List
                sx={{
                  mr: 3,
                }}
              >
                {activeStep === 2 && (
                  <>
                    <Typography variant="body2" gutterBottom>
                      Field Icons Description:
                    </Typography>
                    <ListItem>
                      <img src={uploadIcon} alt="uploadIcon" />
                      <Typography variant="caption" sx={{ ml: 1 }}>
                        Upload a field file
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <img src={polygonIcon} alt="polygonIcon" />
                      <Typography variant="caption" sx={{ ml: 1 }}>
                        Polygon
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <img src={circleIcon} alt="circleIcon" />
                      <Typography variant="caption" sx={{ ml: 1 }}>
                        Pivot
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <img src={sectorIcon} alt="sectorIcon" />
                      <Typography variant="caption" sx={{ ml: 1 }}>
                        Pivot Sector
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <img src={segmentIcon} alt="segmentIcon" />
                      <Typography variant="caption" sx={{ ml: 1 }}>
                        Pivot Segment
                      </Typography>
                    </ListItem>
                  </>
                )}
              </List>
            </Paper>
          </Grid>
        )}
        <Grid
          item
          xs={activeStep > 0 ? 8.5 : 12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            px: 3,
          }}
        >
          {stepContent[activeStep]}
        </Grid>
        <Grid
          item
          xs={activeStep === 0 ? 12 : 0.5}
          sx={{
            display: "flex",
            pt: activeStep === 0 ? "10px" : "0px",
            pr: activeStep === 0 ? "0px" : "5px",
            alignItems: activeStep === 0 ? "center" : "flex-end",
            justifyContent: activeStep === 0 ? "center" : "right",
            alignContent: activeStep === 0 ? "center" : "flex-end",
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={handleNext}
            disabled={
              activeStep === steps.length - 1 && (isLoading || !canSave)
            }
          >
            {activeStep === steps.length - 1 ? "Finish" : "Next"}
            {isLoading && (
              <CircularProgress color="inherit" size={"15px"} sx={{ ml: 2 }} />
            )}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StepperForm;
