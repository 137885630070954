import React, { useState } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from "recharts";
import { Checkbox, FormGroup, FormControlLabel, Grid } from "@mui/material";

interface DataItem {
  parameter: string;
  dateTime: string;
  value: number;
}

const formatDate = (date: string): string => {
  const d = new Date(date);
  return d.toISOString().split("T")[0];
};

const WeatherLineChart: React.FC<{ data: DataItem[] }> = ({ data }) => {

  const uniqueParameters = Array.from(
    new Set(data?.map((item) => item.parameter))
  );

  const defaultParameters = ["Soil Moisture at -5cm (m³/m³)", "Soil Temperature at -5cm (°C)"];


  const [selectedParameters, setSelectedParameters] = useState(
    uniqueParameters.filter(param => defaultParameters.includes(param)) || defaultParameters
  );

  const handleCheckboxChange = (parameter: string) => {
    setSelectedParameters((prevSelectedParameters) => {
      if (prevSelectedParameters.includes(parameter)) {
        return prevSelectedParameters?.filter((item) => item !== parameter);
      } else if (prevSelectedParameters.length < 2) {
        return [...prevSelectedParameters, parameter];
      } else {
        return prevSelectedParameters;
      }
    });
  };

  const filteredData = data?.filter((item) =>
    selectedParameters.includes(item.parameter)
  );

  const uniqueDates = Array.from(
    new Set(filteredData.map((item) => formatDate(item.dateTime)))
  ).map((date) => formatDate(date));

  const chartData = uniqueDates.map((date) => {
    const entry = { date };
    selectedParameters.forEach((parameter) => {
      const item = filteredData.find(
        (d) =>
          formatDate(d.dateTime) === date &&
          d.parameter === parameter
      );
      entry[parameter] = item ? item.value : null;
    });
    return entry;
  });

  chartData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  function getMonthDayYear(dateTime) {
    const dateObj = new Date(dateTime);

    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    const year = String(dateObj.getFullYear());

    return `${month}-${day}-${year}`;
  }

  const [firstMetric, secondMetric] = selectedParameters;

  return (
    <Grid container spacing={3} sx={{ ml: 3 }}>
      <Grid item xs={3} sx={{ mt: 5 }}>
        <FormGroup>
          {uniqueParameters?.map((parameter) => (
            <FormControlLabel
              key={parameter}
              control={
                <Checkbox
                  checked={selectedParameters.includes(parameter)}
                  onChange={() => handleCheckboxChange(parameter)}
                  name={parameter}
                />
              }
              label={parameter}
            />
          ))}
        </FormGroup>
      </Grid>
      <Grid item xs={9} sx={{ mt: 2 }}>
        <LineChart
          data={chartData}
          width={1000}
          height={500}
          margin={{ top: 30, bottom: 20, right: 90 }}
          
        >
          <CartesianGrid strokeDasharray="3 3 " />
          <XAxis
            dataKey="date"
            tickFormatter={(date) => getMonthDayYear(new Date(date))}
            domain= {['auto','auto']}
          />
          {firstMetric && (
            <YAxis
              yAxisId="left"
              label={{ value: firstMetric, angle: -90, position: 'outsideLeft', dx: -22 }}
              orientation="left"
              scale="auto"
              domain= {['auto','auto']}
            />
          )}
          {secondMetric && (
            <YAxis
              yAxisId="right"
              orientation="right"
              label={{ value: secondMetric, angle: -90, position: 'outsideRight', dx: 15 }}
              scale="auto"
              domain= {['auto','auto']}
            />
          )}
          <Tooltip />
          <Legend />
          {firstMetric && (
            <Line
              yAxisId="left"
              type="monotone"
              dataKey={firstMetric}
              stroke="#0000FF"
            />
          )}
          {secondMetric && (
            <Line
              yAxisId="right"
              type="monotone"
              dataKey={secondMetric}
              stroke="#FF0000"
            />
          )}
        </LineChart>
        </Grid>
    </Grid>
  );
};


export default WeatherLineChart;
