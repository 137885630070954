import React, { useEffect, useState } from "react";
import {
  Grid,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  CircularProgress,
  Typography,
  Divider,
} from "@mui/material";
import DateSelector from "../atom/dateSelect";
import { useForm, Controller } from "react-hook-form";
import { fetchData } from "../../hooks/useFetchData";
import { getActivitiesSummary } from "../../services/api-ffm-service";
import ActivitySummaryReport from "./activitySummaryReport";
import { useConfig } from "../../context/ConfigContextTest";
import MultiSelect from "../molecules/MultiSelect";
import { Field } from "../../interfaces/field.interface";

type FormValues = {
  startDate: string;
  endDate: string;
  activityCategories: { [key: string]: boolean };
  fields: number[];
};

const ActivitySummary: React.FC = () => {
  const { control, handleSubmit, setValue, getValues } = useForm<FormValues>({
    defaultValues: {
      startDate: "",
      endDate: "",
      activityCategories: {},
      fields: [],
    },
  });

  const [showSummary, setShowSummary] = useState(false);
  const [activitiesSummary, setActivitiesSummary] = useState<any>({});
  const { selectedOrganization, mappedfields, activityCategories } = useConfig();
  const [formData, setFormData] = useState<FormValues | null>(null);
  const [checkedCategories, setCheckedCategories] = useState<boolean[]>([]);
  const [allChecked, setAllChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [dateToday, setDateToday] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCheckedCategories(new Array(activityCategories.length).fill(false));
  }, [activityCategories]);

  useEffect(() => {
    const allChecked =
      activityCategories.length > 0 &&
      activityCategories.every((_, index) => checkedCategories[index]);
    const allUnchecked = activityCategories.every(
      (_, index) => !checkedCategories[index]
    );
    setAllChecked(allChecked);
    setIndeterminate(!allChecked && !allUnchecked);
  }, [checkedCategories, activityCategories]);

  const getFormattedDate = (): string => {
    const today = new Date();
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return today.toLocaleDateString(undefined, options);
  };

  const handleGenerateSummary = async (data: FormValues) => {
    setIsLoading(true);

    const selectedCategories = Object.keys(data.activityCategories)
      .filter((key) => data.activityCategories[key])
      .map(
        (key) =>
          activityCategories.find((category) => category.name === key)
            ?.activityCategoryId ?? "defaultCategoryId"
      );

    const today = getFormattedDate();
    setDateToday(today);

    const selectedFields = data.fields
    .map((fieldId) => mappedfields.find((field) => field.fieldId === fieldId))
    .filter((field): field is any => field !== undefined);

    setFormData({
      ...data,
      fields: selectedFields, 
    });

    const formattedData = {
      startDate: data.startDate,
      endDate: data.endDate,
      azureUserId: selectedOrganization?.azureUserId,
      organizationId: selectedOrganization?.organizationId,
      activityCategory: selectedCategories,
      fields: data.fields,
    };

    await fetchData(getActivitiesSummary, setActivitiesSummary, undefined, [
      formattedData,
    ]);

    setShowSummary(true);
    setIsLoading(false);
  };

  const handleChangeParentCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    const updatedCheckedCategories = new Array(activityCategories.length).fill(
      checked
    );
    setCheckedCategories(updatedCheckedCategories);

    const updatedCategories = activityCategories.reduce((acc, category) => {
      acc[category.name] = checked;
      return acc;
    }, {} as { [key: string]: boolean });
    setValue("activityCategories", updatedCategories);
  };

  const handleChangeChildCheckbox =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedCheckedCategories = [...checkedCategories];
      updatedCheckedCategories[index] = event.target.checked;
      setCheckedCategories(updatedCheckedCategories);

      const updatedCategories = getValues("activityCategories");
      updatedCategories[activityCategories[index].name] =
        event.target.checked;
      setValue("activityCategories", updatedCategories);
    };

  return (
    <Grid sx={{ p: 1, pt: 0 }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Activity Summary report
        <Typography variant="caption" sx={{ ml: 1 }}>
          (Complete all required fields and then click the button.)
        </Typography>
        <Divider />
      </Typography>

      <form onSubmit={handleSubmit(handleGenerateSummary)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <DateSelector
                  label="Start Date"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <DateSelector
                  label="End Date"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              )}
            />
          </Grid>
          <Grid item xs={10}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Select Activity Category</FormLabel>
              <Box
                sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
              >
                <FormControlLabel
                  label="Select All"
                  control={
                    <Checkbox
                      checked={allChecked}
                      indeterminate={indeterminate}
                      onChange={handleChangeParentCheckbox}
                    />
                  }
                />
                {activityCategories.map((category, index) => (
                  <FormControlLabel
                    key={category.name}
                    control={
                      <Checkbox
                        checked={checkedCategories[index] || false}
                        onChange={handleChangeChildCheckbox(index)}
                      />
                    }
                    label={category.name}
                  />
                ))}
              </Box>
            </FormControl>
          </Grid>
          <Grid item xs={10}>
            <Controller
              name="fields"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  label="Select Fields"
                  value={field.value}
                  onChange={field.onChange}
                  options={mappedfields.map((option) => ({
                    id: option.fieldId,
                    label: option.name,
                  }))}
                  fieldId="fields"
                  error={undefined}
                  helperText={undefined}
                  sx={undefined}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} style={{ marginTop: "16px" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              Generate Summary
              {isLoading && (
                <CircularProgress
                  color="inherit"
                  size={"15px"}
                  sx={{ ml: 2 }}
                />
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
      {showSummary && formData && (
        <Grid item xs={12} style={{ marginTop: "16px" }}>
          <ActivitySummaryReport
            selectCategory={Object.keys(formData.activityCategories).filter(
              (key) => formData.activityCategories[key]
            )}
            selectedFields={formData?.fields}
            dateRange={activitiesSummary.dateRange}
            activities={activitiesSummary?.activities}
            date={dateToday}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ActivitySummary;
