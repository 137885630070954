import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import DynamicFormDialog from "../molecules/dialog";
import TextBox from "../atom/textBox";
import { z } from "zod";

const productSchema = z.object({
  Product: z.union([z.string(), z.number()]).refine((value) => {
    const parsedValue = typeof value === "string" ? parseFloat(value) : value;
    return !isNaN(parsedValue) && parsedValue > 0;
  }, "Product is required"),
  
  Quantity: z.union([z.string(), z.number()]).refine((value) => {
    const parsedValue = typeof value === "string" ? parseInt(value, 10) : value;
    return !isNaN(parsedValue) && parsedValue > 0;
  }, "Quantity must be at least 1"),
  
  CostPerQuantity: z.union([z.string(), z.number()]).refine((value) => {
    const parsedValue = typeof value === "string" ? parseFloat(value) : value;
    return !isNaN(parsedValue) && parsedValue > 0;
  }, "Cost must be greater than 0"),
});


interface ActivityProductsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  products: any[];
  onSubmit: (product: {
    productId: string;
    product: string;
    quantity: number;
    costPerQuantity: number;
  }) => void;
}

const ActivityProductsDialog: React.FC<ActivityProductsDialogProps> = ({
  isOpen,
  onClose,
  products,
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(productSchema),
    mode: "onChange",
    defaultValues: {
      Product: "",
      Quantity: 1,
      CostPerQuantity: 0,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const productOptions: any[] = [];
  const seenProductIds = new Set<number>();

  products?.forEach((product: any) => {
    if (!seenProductIds.has(product.IDs.ProductId)) {
      seenProductIds.add(product.IDs.ProductId);
      productOptions.push({
        value: product.IDs.ProductId,
        name: product.ProductName,
        label: `${product.ProductName} (${product.ProductActiveIngredient})`,
        id: product.IDs.ProductId,
      });
    }
  });

  const handleProductSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const selectedProduct = productOptions.find(
        (product) => product.id === data.Product
      );
      onSubmit({
        productId: selectedProduct?.id || "",
        product: selectedProduct?.name || "",
        quantity: Number(data.Quantity),
        costPerQuantity: Number(data.CostPerQuantity),
      });
      handleClose();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  useEffect(() => {
    if (!isOpen) {
      handleClose();
    }
  }, [isOpen]);

  return (
    <DynamicFormDialog
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmit(handleProductSubmit)}
      title={"Add Product"}
      formContent={
        <form onSubmit={handleSubmit(handleProductSubmit)}>
          <DialogContent dividers sx={{ pt: 1, pb: 1 }}>
            <Grid container spacing={2} sx={{ padding: 2 }}>
              <Grid item xs={12}>
                <Controller
                  name="Product"
                  control={control}
                  render={({ field }) => (
                    <TextBox
                      {...field}
                      label="Product"
                      select
                      fullWidth
                      error={!!errors.Product}
                      helperText={errors.Product?.message}
                    >
                      {productOptions.map((option) => (
                        <MenuItem key={option.id} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextBox>
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="Quantity"
                  control={control}
                  render={({ field }) => (
                    <TextBox
                      {...field}
                      label="Quantity"
                      type="wholeNumber"
                      fullWidth
                      error={!!errors.Quantity}
                      helperText={errors.Quantity?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="CostPerQuantity"
                  control={control}
                  render={({ field }) => (
                    <TextBox
                      {...field}
                      label="Cost"
                      value={field.value}
                      type="currency"
                      fullWidth
                      error={!!errors.CostPerQuantity}
                      helperText={errors.CostPerQuantity?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              startIcon={<SaveIcon />}
              disabled={isLoading}
            >
              Save
              {isLoading && (
                <CircularProgress color="inherit" size={"15px"} sx={{ ml: 2 }} />
              )}
            </Button>
          </DialogActions>
        </form>
      }
    />
  );
};

export default ActivityProductsDialog;
