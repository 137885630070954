import React, { useState, useEffect, useRef } from "react";
import { AlertColor, Button, CircularProgress, Grid } from "@mui/material";
import FieldMapComponent from "../molecules/FieldMapComponent";
import FieldForm from "../organisms/FieldDetails";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb } from "../atom/breadcrumbs";
import * as yup from "yup";
import { FieldData } from "../../models/fieldData.interface";
import { PolygonData } from "../../models/polygonData.interface";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { generateGUID, handleApiResponse, handleMessage, handleEditMessage } from "../../utils/Utilities";
import { getOrganizations } from "../../services/api-ffm-service";
import { FieldMetadata } from "../../models/fieldMetadata.interface";
import {
  createFarmFieldLink,
  createField,
  getFieldMetaData,
  updateField,
  deactivateField,
} from "../../services/api-gs-service";
import { fetchData } from "../../hooks/useFetchData";
import GenericConfirmDialog from "../organisms/genericConfirmDialog";
import { useConfig } from "../../context/ConfigContextTest";

const validationSchema = yup.object({
  fieldName: yup.string().required("Farm Name is required"),
  farmId: yup.string().optional(),
  farmIdentifier: yup.string().optional(),
  irrDry: yup.string().oneOf(["Irrigated", "Dry"]).optional(),
});

interface FormValues {
  fieldName: string;
  farmId?: string;
  farmIdentifier?: string;
  irrDry?: "Irrigated" | "Dry";
}

const FieldManagement: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedOrganization, refreshMappedFields } = useConfig();


  const initialFieldData: FieldData = location.state?.fieldData;

  const [fieldMetaData, setFieldMetaData] = useState<FieldMetadata>();
  const [fieldData, setFieldData] = useState<FieldData | undefined>(
    initialFieldData
  );
  const [isLoading, setIsLoading] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [polygonData, setPolygonData] = useState<PolygonData>();
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedFieldId, setSelectedFieldId] = useState<number | null>(null);
  const snackbarRef = useRef<{ handleOpen: (title: string, msg: string | null, sev: AlertColor) => void }>(null);
  const [canSave, setCanSave] = useState(true);
  const [size, setSize] = useState<string | undefined>(undefined);

  const [isEditing, setIsEditing] = useState<boolean>(
    !initialFieldData?.fieldId
  );

  const handleButtonClick = (value: boolean) => {
    setIsEditing(value);
  };

  const methods = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      fieldName: initialFieldData?.fieldName || "",
      farmId: initialFieldData?.farmId || "",
      farmIdentifier:
        initialFieldData?.farmIdentifier || initialFieldData?.farmId || "",
      irrDry: initialFieldData?.irrDry || "Dry",
    },
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const handleFieldDataChange = (updatedFieldData: FieldData) => {
    setFieldData(updatedFieldData);
  };

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  useEffect(() => {
    if (location.state?.fieldData !== fieldData) {
      setFieldData(location.state?.fieldData);
    }

    if (initialFieldData?.fieldId !== undefined) {
      fetchData(getFieldMetaData, setFieldMetaData, undefined, [
        initialFieldData?.fieldId,
      ]);
    }

    const onMessage = (event: MessageEvent) => {
      const data = handleMessage(event);
      if (data) {
        setPolygonData(data);
      }
      const editData = handleEditMessage(event);
      if (editData) {
        handleButtonClick(true);
      }
    };

    window.addEventListener("message", onMessage);
    return () => window.removeEventListener("message", onMessage);
  }, [location.state?.fieldData, fieldData, initialFieldData?.fieldId]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    navigate(-1);
  };

  const handleHome = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    navigate("/");
  };

  const roundCoordinates = (coords: number[][][]): number[][][] => {
    return coords.map((coordGroup) =>
      coordGroup.map(([lat, long]) => [
        parseFloat(lat.toFixed(5)),
        parseFloat(long.toFixed(5)),
      ])
    );
  };

  const existingFieldCoordinates = (): string => {
    if (polygonData?.coordinates) {
      const roundedCoords = roundCoordinates(polygonData.coordinates);
      return JSON.stringify(roundedCoords);
    }
    return initialFieldData?.coords || "";
  };

  const showSnackbar = (title, message, severity) => {
    if (snackbarRef.current) {
      snackbarRef.current.handleOpen(title, message, severity);
    }
  };

  const onSubmit = async (data: FormValues) => {
    let response, title;
    setIsLoading(true);

    try {
      const farmIdentifier = data.farmIdentifier;
      const coords = existingFieldCoordinates();
      const cropperRef = generateGUID();

      const partyId = selectedOrganization?.partyIdentifier || "";

      if (!initialFieldData?.fieldId) {
        const fieldMetadata: FieldMetadata = {
          cropperRef,
          coords,
          partyId,
          name: data.fieldName,
          metadata: { irrDry: data.irrDry || "dry" },
        };
        response = await createField(fieldMetadata);
        title = "Field successfully created";
        if (
          farmIdentifier !== null &&
          farmIdentifier !== undefined &&
          farmIdentifier !== ""
        ) {
          let fieldId = response.data;
          await createFarmFieldLink(fieldId, farmIdentifier);
        }
      } else {
        const fieldMetadata: FieldMetadata = {
          fieldId: initialFieldData.fieldId,
          coords,
          partyId,
          name: data.fieldName,
          metadata: { irrDry: data.irrDry || "dry" },
        };
        response = await updateField(fieldMetadata);
        console.log(response);
        title = "Field successfully updated";
        if (
          farmIdentifier !== null &&
          farmIdentifier !== undefined &&
          farmIdentifier !== ""
        ) {
          await createFarmFieldLink(initialFieldData.fieldId, farmIdentifier);
        }
      }
      if(response.status === 200){
        showSnackbar(title, "", "success");
      }else{
        showSnackbar("Action failed", "", "warning");
      }
      if(response.status === 200){
        refreshMappedFields();
        navigate("/");
        handleButtonClick(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (fieldId: number) => {
    let response;

    if (initialFieldData?.fieldId) {
      try {
        response = await deactivateField(fieldId);
        if(response.status === 200){
          refreshMappedFields();
          navigate("/");
        }
      } catch (error) {
        console.error("Error deleting field:", error);
      }
    }

    handleApiResponse(response, showSnackbar, {title: "Field has been successfully deactivated"});
  };

  const handleConfirm = async () => {
    if (selectedFieldId) {
      try {
        await handleDelete(selectedFieldId);
      } catch (error) {
        console.error("Failed to delete field:", error);
      }
    }

    setConfirmOpen(false);
  };

  const openDeleteConfirm = (
    fieldId: number,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setSelectedFieldId(fieldId);
    setConfirmOpen(true);
  };

  return (
    <FormProvider {...methods}>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumb
            crumbs={[
              { text: "<< Back", onClick: handleClick, underline: "hover" },
              { text: "My Farm", onClick: undefined, underline: "none" },
              {
                text: "Farm Manager",
                onClick: handleHome,
                underline: "hover",
              },
            ]}
            currentCrumb={"Fields"}
          />
        </Grid>
        <Grid item xs={9}>
          <FieldMapComponent
            height="650px"
            fieldData={fieldData}
            onLoad={handleMapLoad}
          />
        </Grid>
        <Grid item xs={3}>
          {mapLoaded && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <FieldForm
                initialFieldData={fieldData}
                onFieldDataChange={handleFieldDataChange}
                polygonData={polygonData}
                control={methods.control}
                errors={errors}
                showSaveButton={true}
                setValue={setValue}
                onCanSaveChange={setCanSave}
                onSizeChange={setSize}
                canSave={canSave}
                isEditing={isEditing}
              />
             <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ marginLeft: "12px" }}
                disabled={isLoading || !canSave || !size || !isEditing}
              >
                {initialFieldData?.fieldId ? "UPDATE" : "SAVE"}
                {isLoading && (
                  <CircularProgress
                    color="inherit"
                    size={"15px"}
                    sx={{ ml: 2 }}
                  />
                )}
              </Button>
              {initialFieldData?.fieldId && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event) =>
                    openDeleteConfirm(initialFieldData?.fieldId, event)
                  }
                  sx={{ marginLeft: "12px" }}
                >
                  DELETE
                </Button>
              )}
            </form>
          )}
        </Grid>
      </Grid>
      <GenericConfirmDialog
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={handleConfirm}
        title="Confirm Deletion"
        content="Are you sure you want to delete this field?"
      />
    </FormProvider>
  );
};

export default FieldManagement;
