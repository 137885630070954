import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Box,
} from "@mui/material";

import { getFields } from "../../services/api-gs-service";
import { getWeather, getRecentWeather } from "../../services/api-ws-service";
import { useFetchData } from "../../hooks/useFetchData";
import { Controller, useForm } from "react-hook-form";
import DateSelector from "../atom/dateSelect";
import dayjs from "dayjs";
import WeatherLineChart from "../molecules/weatherChart";
import FarmFieldDisplay from "../organisms/farmDisplayCard";
 
import { useConfig } from "../../context/ConfigContextTest";
import WindIcon from "../../assets/icons/weather/svg/wind_icon.svg";

const CropClimatePage: React.FC = () => {
  const { selectedOrganization } = useConfig();
  const [fields, setFields] = useState<any[]>([]);
  const [weather, setWeather] = useState<any[]>([]);
  const [recentWeather, setRecentWeather] = useState<Array<{ value: any; dateTime: string }>>([]);
  const [rotation, setRotation] = useState<string>("");
  const [mostRecentDate, setMostRecentDate] = useState<string>("");
  const [selectedFieldId, setSelectedFieldId] = useState("");


  const { control, watch, setValue } = useForm({
    defaultValues: {
      fieldID: "",
      fromDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
      toDate: dayjs().format("YYYY-MM-DD"),
    },
  });

  function handleSetFieldId(value: string): void {
    //console.log('selectedFieldId: '+value);
    setSelectedFieldId(value);
  }


  useEffect(() => {
    if (fields.length > 0) {
      const firstFieldId = fields[0].fieldId;
      setValue('fieldID', firstFieldId); 
    }
  }, [fields, setValue]);
  
  //const selectedField = parseInt(watch("fieldID"), 10); // Ensure selectedField is a number
  const fromDate = watch("fromDate");
  const toDate = watch("toDate");

  useFetchData(getFields, setFields, undefined, [selectedOrganization?.partyIdentifier ?? 0]);
/*
  useEffect(() => {
    if (fields.length > 0) {
      setValue('fieldID', fields[0].fieldId);
    }
  }, [fields, setValue]);
*/
  useEffect(() => {
    const validateDates = () => {
      if (fromDate && toDate) {
        const start = dayjs(fromDate);
        const end = dayjs(toDate);
        const maxStartDate = end.subtract(3, "month");
        const maxEndDate = start.add(3, "month");

        if (start.isBefore(maxStartDate)) {
          setValue('fromDate', maxStartDate.format("YYYY-MM-DD"));
        } else if (start.isAfter(end)) {
          setValue('fromDate', end.format("YYYY-MM-DD"));
        }

        if (end.isAfter(maxEndDate)) {
          setValue('toDate', maxEndDate.format("YYYY-MM-DD"));
        } else if (end.isBefore(start)) {
          setValue('toDate', start.format("YYYY-MM-DD"));
        }
      }
    };

    validateDates();
  }, [fromDate, toDate, setValue]);

  useEffect(() => {
    if (selectedFieldId && fromDate && toDate) {
      getWeather(
        Number(selectedFieldId),
        fromDate,
        toDate,
        "Precipitation,UV Index,Temperature at 2m,Temperature at -5cm,Temperature at -15cm,Relative Humidity at 2m,Soil Moisture at -5cm,Soil Moisture at -15cm"
      )
        .then((data) => {
          const weatherWithUnits = addUnitsToWeatherData(data);
          setWeather(weatherWithUnits);
        })
        .catch((error) => {
          console.error("Error fetching weather:", error);
        });
    }
  }, [selectedFieldId, fromDate, toDate]);

  useEffect(() => {
    
    //console.log('getRecentWeather, selectedFieldId');
    //console.log(selectedFieldId);
    
    if (selectedFieldId) {
      getRecentWeather(
        Number(selectedFieldId),
        "Precipitation,UV Index,Relative Humidity at 2m,Temperature at 2m,Temperature at -5cm,Temperature at -15cm,Wind Speed at 2m,Wind Direction at 2m,Soil Moisture at -5cm,Soil Moisture at -15cm"
      )
        .then((data) => {
          const weatherData = data.reduce((acc, item) => {
            let value = item.value;
            if (item.parameter === "Wind Direction at 2m") {
              const rotation = degreesToRotation(value);
              value = degreesToCompass(value);
              setRotation(rotation);
            }
            acc[item.parameter] = {
              value: value,
              dateTime: dayjs(item.dateTime).format("YYYY-MM-DD")
            };
            return acc;
          }, {});

          setRecentWeather(weatherData);
          const mostRecent = weatherData["Soil Temperature at -5cm"]?.dateTime;
          if (mostRecent) {
            setMostRecentDate(dayjs(mostRecent).format("YYYY-MM-DD"));
          }
        })
        .catch((error) => {
          console.error("Error fetching weather:", error);
        });
    }
  }, [selectedFieldId]);
/*
  useEffect(() => {
    if (mostRecentDate) {
      setValue("toDate", mostRecentDate);
    }
  }, [mostRecentDate, setValue]);
*/
  const addUnitsToWeatherData = (data) => {
    //console.log(data);
    const units = {
      "Accumulated Precipitation": "mm",
      "Precipitation": "mm",
      "Temperature at 2m": "°C",
      "Soil Temperature at -5cm": "°C",
      "Soil Temperature at -15cm": "°C",
      "Relative Humidity at 2m": "%",
      "Wind Speed at 2m": "m/s",
      "Soil Moisture at -5cm": "m³/m³",
      "Soil Moisture at -15cm": "m³/m³",
    };

    return data.map((entry, index) => ({
      ...entry,
      parameter: `${entry.parameter} ${units[entry.parameter] ? `(${units[entry.parameter]})` : ""}`,
      key: index,
    }));
  };

  const degreesToCompass = (degrees: number): string => {
    const directions = [' N', ' NE', ' E', ' SE', ' S', ' SW', ' W', ' NW'];
    return directions[Math.round(degrees / 45) % 8];
  };

  const degreesToRotation = (degrees: number): string => {
    const normalizedDegrees = (degrees + 360) % 360;
    return `rotate(${normalizedDegrees}deg)`;
  };

  const renderSoilCard = (data: any) => (
    <Paper elevation={2} sx={{ p: { xs: 1, sm: 2 }, m: 1, minHeight: '150px' }}>
      <Typography color="text.secondary" gutterBottom sx={{ fontSize: 18, fontWeight: 500 }}>
        Soil
      </Typography>
      {["Soil Moisture at -5cm", "Soil Moisture at -15cm", "Soil Temperature at -5cm", "Soil Temperature at -15cm"].map((item) => (
        <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{ padding: "2px 0", borderBottom: "none", }}>
              <Typography variant="subtitle1" color="text.secondary">
                {item}:
              </Typography>
            </TableCell>
            <TableCell align="right" style={{ padding: "2px 20px", borderBottom: "none", }}>
              <Typography variant="subtitle1" color="text.secondary">
              {item === "Soil Moisture at -5cm" && `${data["Soil Moisture at -5cm"]?.value} m³/m³`}         
              {item === "Soil Moisture at -15cm" && `${data["Soil Moisture at -15cm"]?.value} m³/m³`}          
              {item === "Soil Temperature at -5cm" && `${data["Soil Temperature at -5cm"]?.value} °C`}         
              {item === "Soil Temperature at -15cm" && `${data["Soil Temperature at -15cm"]?.value} °C`}         
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>    

      ))}
    </Paper>
  );
  
  const renderWeatherCard = (data: any) => (
    <Paper elevation={2} sx={{ p: { xs: 1, sm: 2 }, m: 1, minHeight: '150px' }}>
      
      <Typography color="text.secondary" gutterBottom sx={{ fontSize: 18, fontWeight: 500 }}>
        Weather
      </Typography>
      
      {["Temperature at 2m", "Precipitation", "Relative Humidity at 2m", "Wind"].map((item) => (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ padding: "2px 0", borderBottom: "none", }}>
                <Typography variant="subtitle1" color="text.secondary">
                  {item}:
                </Typography>
              </TableCell>
              <TableCell align="right" style={{ padding: "1px 20px", borderBottom: "none", }}>
                <Typography variant="subtitle1" color="text.secondary">
                {item === "Temperature at 2m" && `${data["Temperature at 2m"]?.value} °C`}         
                {item === "Precipitation" && `${data["Precipitation"]?.value} mm`}         
                {item === "Relative Humidity at 2m" && `${data["Relative Humidity at 2m"]?.value} %`}          
                {/*{item === "UV Index" && data["UV Index"]?.value}*/}          
                {item === "Wind" && (
                  <>
                    <img 
                      src={WindIcon} 
                      alt="Wind direction" 
                      style={{ width: '15px', height: '15px', transform: rotation }}
                    />
                    {data["Wind Direction at 2m"]?.value} {data["Wind Speed at 2m"]?.value} m/s
                  </>
                )}          
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>    
      ))}
    </Paper>
  );
/*
  if(weather && weather.length > 0) {
    renderChart(weather);
  } else {
    renderChart([]);
  }
*/

  return (
    <Grid container direction="column" sx={{ minHeight: "100vh", overflow: "hidden", pb: '30px', pt: '32px'}}>
      <Grid item xs={12} sx={{ mt: 2, mb: 0, ml: 2, mr: 2 }}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={2.405} sx={{ p: 2, pb: 0 }}>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Climate Insights
            </Typography>
          </Grid>
          <Grid item>
            <form>
              <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'nowrap' }}>
                {["fromDate", "toDate"].map((date) => (
                  <Box key={date} sx={{ flex: '0 0 30%' }}>
                    <Controller
                      name={date as "fromDate" | "toDate"}  // Cast 'date' to the correct type
                      control={control}
                      render={({ field }) => (
                        <DateSelector
                          label={date === "fromDate" ? "Start Date" : "End Date"}
                          value={field.value}
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                      )}
                    />
                  </Box>
                ))}
              </Box>
            </form>
          </Grid>
        </Grid>
      </Grid>
  
      <Grid container sx={{ flexGrow: 1, mb: 1 }}>
        <Grid id="farm-field-display-wrapper" item xs={2.5}>
          <FarmFieldDisplay handleSetFieldId={handleSetFieldId} /> 
        </Grid>  
        <Grid item xs={9.5}>

          <div id="chart-wrapper" style={{display: "none", width: "60%", height: "60%",}}>
          </div>

          {weather && weather.length > 0 ? (
            <WeatherLineChart data={weather} />
          ) : (<></>)}
          {mostRecentDate && (
            <Typography sx={{ mt: 4, ml: 2, fontSize: 18, fontWeight: 500 }}>
              Current Climate Conditions ({ mostRecentDate.replace(/-/g, '/') })
            </Typography>
          )}
          {mostRecentDate && (
            <Grid container sx={{ padding: "0 10px" }}>
              <Grid item xs={12} sm={6} md={6}>
                {renderSoilCard(recentWeather)}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                {renderWeatherCard(recentWeather)}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CropClimatePage;