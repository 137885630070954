import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  AlertColor,
  Button,
  CircularProgress,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import FieldMapComponent from "../molecules/FieldMapComponent";
import FieldForm from "../organisms/FieldDetails";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb } from "../atom/breadcrumbs";
import * as yup from "yup";
import { FieldData } from "../../models/fieldData.interface";
import { PolygonData } from "../../models/polygonData.interface";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  generateGUID,
  handleApiResponse,
  handleMessage,
  handleEditMessage,
} from "../../utils/Utilities";
import { FieldMetadata } from "../../models/fieldMetadata.interface";
import {
  createFarmFieldLink,
  createField,
  getFieldMetaData,
  updateField,
  deactivateField,
  createFieldIcon,
  updateFieldIcon,
} from "../../services/api-gs-service";
import { fetchData } from "../../hooks/useFetchData";
import GenericConfirmDialog from "../organisms/genericConfirmDialog";
import { useConfig } from "../../context/ConfigContextTest";
import { getFieldIcon } from "../../services/api-mt-service";
import { v4 as uuidv4 } from "uuid";
import { useTheme } from "@mui/material/styles";

const validationSchema = yup.object({
  fieldName: yup.string().required("Field Name is required"),
  farmId: yup.string().optional(),
  farmIdentifier: yup.string().required("Farm selection is required"),
  irrDry: yup.string().oneOf(["Irrigated", "Dry"]).optional(),
  size: yup
    .string()
    .required("Field size is required")
    .test("is-valid-area", "Field size must be between 1 ha and 1000 ha", (value) => {
      if (!value) return false;

      const numericValue = parseFloat(value.replace("ha", "").trim());

      return !isNaN(numericValue) && numericValue >= 1 && numericValue <= 1000;
    }),
});

interface FormValues {
  fieldName: string;
  farmId?: string;
  farmIdentifier: string;
  irrDry?: "Irrigated" | "Dry";
  size: string;
}

const FieldManagement: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { selectedOrganization, refreshMappedFields } = useConfig();

  const initialFieldData: FieldData = location.state?.fieldData;

  const [, setFieldMetaData] = useState<FieldMetadata>();
  const [fieldData, setFieldData] = useState<FieldData | undefined>(
    initialFieldData
  );
  const [isLoading, setIsLoading] = useState(false);
  const [polygonData, setPolygonData] = useState<PolygonData>();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedFieldId, setSelectedFieldId] = useState<number | null>(null);
  const snackbarRef = useRef<{
    handleOpen: (title: string, msg: string | null, sev: AlertColor) => void;
  }>(null);
  const [canSave, setCanSave] = useState(true);
  const [size, setSize] = useState<string | undefined>(undefined);

  const [isEditing, setIsEditing] = useState<boolean>(
    !initialFieldData?.fieldId
  );

  const [updateTrigger, setUpdateTrigger] = useState(0);

  const handleButtonClick = (value: boolean) => {
    setIsEditing(value);
  };

  const methods = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      fieldName: initialFieldData?.fieldName || "",
      farmId: initialFieldData?.farmId || "",
      farmIdentifier:
        initialFieldData?.farmIdentifier || initialFieldData?.farmId || "",
      irrDry: initialFieldData?.irrDry || "Dry",
      size: initialFieldData?.size || ""
    },
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const handleFieldDataChange = (updatedFieldData: FieldData) => {
    setFieldData(updatedFieldData);
  };

  useEffect(() => {
    if (location.state?.fieldData !== fieldData) {
      setFieldData(location.state?.fieldData);
    }

    if (initialFieldData?.fieldId !== undefined) {
      fetchData(getFieldMetaData, setFieldMetaData, undefined, [
        initialFieldData?.fieldId,
      ]);
    }

    const onMessage = (event: MessageEvent) => {
      const data = handleMessage(event);
      if (data) {
        setPolygonData(data);
      }
      const editData = handleEditMessage(event);
      if (editData) {
        handleButtonClick(true);
      }
    };

    window.addEventListener("message", onMessage);
    return () => window.removeEventListener("message", onMessage);
  }, [location.state?.fieldData, fieldData, initialFieldData?.fieldId]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    navigate(-1);
  };

  const handleHome = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    navigate("/");
  };

  const roundCoordinates = (coords: number[][][]): number[][][] => {
    return coords.map((coordGroup) =>
      coordGroup.map(([lat, long]) => [
        parseFloat(lat.toFixed(5)),
        parseFloat(long.toFixed(5)),
      ])
    );
  };

  const existingFieldCoordinates = (): string => {
    if (polygonData?.coordinates) {
      const roundedCoords = roundCoordinates(polygonData.coordinates);
      return JSON.stringify(roundedCoords);
    }
    return initialFieldData?.coords || "";
  };

  const showSnackbar = (title, message, severity) => {
    if (snackbarRef.current) {
      snackbarRef.current.handleOpen(title, message, severity);
    }
  };

  const onSubmit = async (data: FormValues) => {
    let response, title;
    setIsLoading(true);

    try {
      const farmIdentifier = data.farmIdentifier;
      const coords = existingFieldCoordinates();
      const cropperRef = generateGUID();

      const partyId = selectedOrganization?.partyIdentifier || "";
      
      let farmFieldLinkIdentifier = uuidv4();
      let newFieldData: FieldData | undefined;

      if (!initialFieldData?.fieldId) {
        // Creating a new field
        const fieldMetadata: FieldMetadata = {
          cropperRef,
          coords,
          partyId,
          name: data.fieldName,
          metadata: { irrDry: data.irrDry || "dry" },
          farmFieldLinkIdentifier: farmFieldLinkIdentifier
        };

        response = await createField(fieldMetadata);

        title = "Field successfully created";
        if (response.status === 200 && response.data) {
          const fieldId = response.data;
          if (farmIdentifier) {
            await createFarmFieldLink(fieldId, farmIdentifier);
          }
          const icon = await getFieldIcon(coords);
          await createFieldIcon(farmFieldLinkIdentifier, icon);

          // Create new field data object
          newFieldData = {
            fieldId,
            fieldName: data.fieldName,
            farmId: farmIdentifier,
            farmIdentifier,
            irrDry: data.irrDry || "Dry",
            size: data.size,
            coords,
            farmFieldLinkIdentifier
          };
        }
      } else {
        // Updating existing field
        const fieldMetadata: FieldMetadata = {
          fieldId: initialFieldData.fieldId,
          coords,
          partyId,
          name: data.fieldName,
          metadata: { irrDry: data.irrDry || "dry" },
        };
        response = await updateField(fieldMetadata);
        farmFieldLinkIdentifier = initialFieldData.farmFieldLinkIdentifier;
        const icon = await getFieldIcon(coords);
        await updateFieldIcon(farmFieldLinkIdentifier, icon);

        title = "Field successfully updated";
        if (farmIdentifier && farmIdentifier !== initialFieldData.farmIdentifier) {
          await createFarmFieldLink(initialFieldData.fieldId, farmIdentifier);
        }

        newFieldData = {
          ...initialFieldData,
          fieldName: data.fieldName,
          farmId: farmIdentifier,
          farmIdentifier,
          irrDry: data.irrDry || "Dry",
          size: data.size,
          coords
        };
      }

      if (response.status === 200) {
        showSnackbar(title, "", "success");
        refreshMappedFields();
        setIsEditing(false);
        setIsAddingField(false);
        setFieldData(newFieldData);
        
        // Navigate to the same page with new field data
        navigate('/fields', { 
          state: { fieldData: newFieldData },
          replace: true 
        });
      } else {
        showSnackbar("Action failed", "", "warning");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      showSnackbar("Error", "An error occurred while saving the field", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const [isAddingField, setIsAddingField] = useState(false);

  const MemoizedFieldMapComponent = useCallback(() => (
    <FieldMapComponent 
      height="100%" 
      fieldData={fieldData} 
      updateTrigger={updateTrigger}
    />
  ), [fieldData, updateTrigger]);

  useEffect(() => {
    const isAdding = location.state?.isAddingField;
    if (isAdding) {
      setIsAddingField(true);
      setFieldData(undefined);
      setPolygonData(undefined);
      setIsEditing(true);
      methods.reset({
        fieldName: "",
        farmId: "",
        farmIdentifier: "",
        irrDry: "Dry",
        size: "",
      });
    } else {
      setIsAddingField(false);
    }
  }, [location.state, methods]);

  const handleDelete = async (fieldId: number) => {
    let response;

    if (initialFieldData?.fieldId) {
      try {
        response = await deactivateField(fieldId);
        if (response.status === 200) {
          refreshMappedFields();
          navigate("/");
        }
      } catch (error) {
        console.error("Error deleting field:", error);
      }
    }

    handleApiResponse(response, showSnackbar, {
      title: "Field has been successfully deactivated",
    });
  };

  const handleConfirm = async () => {
    if (selectedFieldId) {
      try {
        await handleDelete(selectedFieldId);
      } catch (error) {
        console.error("Failed to delete field:", error);
      }
    }

    setConfirmOpen(false);
  };

  const openDeleteConfirm = (
    fieldId: number,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setSelectedFieldId(fieldId);
    setConfirmOpen(true);
  };

  const handleAddField = () => {
    navigate('/fields', { state: { isAddingField: true }, replace: true });
    setUpdateTrigger(prev => prev + 1);
  };

  return (
    <FormProvider {...methods}>
      <Grid container sx={{ height: 'calc(100vh - 125px)' }}> 
        <Grid item xs={12}>
          <Breadcrumb
            crumbs={[
              { text: "<< Back", onClick: handleClick, underline: "hover" },
              { text: "My Farm", onClick: undefined, underline: "none" },
              {
                text: "Farm Manager",
                onClick: handleHome,
                underline: "hover",
              },
            ]}
            currentCrumb={"Fields"}
          />
        </Grid>
        <Grid item xs={12} sx={{ p: 2, pb: 0 }}>
          <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
            Farm Manager
          </Typography>
        </Grid>
  
        <Grid item xs={12} md={9} sx={{ height: '100%' }}>
          <MemoizedFieldMapComponent />
        </Grid>
        <Grid item xs={12} md={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ flexGrow: 1, overflowY: "auto", minHeight: "300px" }}>
              <FieldForm
                initialFieldData={fieldData}
                onFieldDataChange={handleFieldDataChange}
                polygonData={polygonData}
                control={methods.control}
                errors={errors}
                showSaveButton={true}
                setValue={setValue}
                onCanSaveChange={setCanSave}
                onSizeChange={setSize}
                canSave={canSave}
                isEditing={isEditing}
                updateTrigger={updateTrigger}
              />
            </div>
            <Box sx={{ mt: 2, mb: 2, mx: 2 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {isAddingField ? (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isLoading || !canSave || !size}
                    fullWidth
                  >
                    SAVE
                    {isLoading && (
                      <CircularProgress
                        color="inherit"
                        size={"15px"}
                        sx={{ ml: 2 }}
                      />
                    )}
                  </Button>
                ) : (
                  <>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isLoading || !canSave || !size || !isEditing}
                        sx={{ flex: 1 }}
                      >
                        UPDATE
                        {isLoading && (
                          <CircularProgress
                            color="inherit"
                            size={"15px"}
                            sx={{ ml: 2 }}
                          />
                        )}
                      </Button>
                      {initialFieldData?.fieldId && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(event) =>
                            openDeleteConfirm(initialFieldData?.fieldId, event)
                          }
                          sx={{ flex: 1 }}
                        >
                          DELETE
                        </Button>
                      )}
                    </Box>
                    {!isAddingField && !isEditing && (
                      <Button
                        variant="contained"
                        onClick={handleAddField}
                        fullWidth
                        sx={{
                          backgroundColor: theme.palette.secondary.main,
                          '&:hover': {
                            backgroundColor: theme.palette.secondary.dark,
                          },
                        }}
                      >
                        ADD FIELD
                      </Button>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </form>
        </Grid>
      </Grid>
      <GenericConfirmDialog
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={handleConfirm}
        title="Confirm Deletion"
        content="Are you sure you want to delete this field?"
      />
    </FormProvider>
  );
};

export default FieldManagement;