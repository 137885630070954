import React, { useState, useEffect } from "react";
import { Box, Divider, Typography } from "@mui/material";
import {
  getNdviMetadata,
  getNdviParameterRange,
} from "../../services/api-mt-service";
import { getNDVIData } from "../../utils/geometryUtils";

export interface ParameterRange {
  Label: string;
  Value: number;
  Max: number;
  Color: string;
}

interface NDVIParameterRangeProps {
  fieldId: string;
  eosViewId: string;
}

const NDVIParameterRange: React.FC<NDVIParameterRangeProps> = ({
  fieldId,
  eosViewId,
}) => {
  const [ndviRanges, setNdviRanges] = useState<ParameterRange[]>([]);
  const [metadata, setMetadata] = useState<{
    Max: number;
    Value: number;
    Min: number;
    Cloud: number;
  } | null>(null);

  useEffect(() => {
    const fetchNdviData = async () => {
      try {
        const rangesResponse = await getNdviParameterRange();
        if (Array.isArray(rangesResponse.data)) {
          // Reverse the data
          const reversedRanges = [...rangesResponse.data].reverse();
          //getNDVIData(Number(fieldId), eosViewId, 16, reversedRanges);
          setNdviRanges(reversedRanges);

        } else {
          console.error("Expected an array but received:", rangesResponse.data);
        }

        const metadataResponse = await getNdviMetadata(
          Number(fieldId),
          eosViewId
        );

        setMetadata(metadataResponse.data);
      } catch (error) {
        console.error("Failed to fetch NDVI data:", error);
      }
    };
    fetchNdviData();
  }, [fieldId, eosViewId]);

  if (ndviRanges.length === 0) return null;

  const dh = 320 / ndviRanges.length;
  console.log('ndviRanges.length: '+ndviRanges.length);
  console.log('dh: '+dh);

  return (
    <Box
      id="ndvi-legend"
      sx={{
        width: 90,
        height: 500,
        padding: 1,
        border: "1px solid #d0d0d0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography
          sx={{
            color: "#444",
            fontSize: 11,
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          NDVI Scale
        </Typography>

        <Box sx={{ position: "relative", fontSize: 10 }}>
          <Box sx={{ color: "#888", position: "absolute", top: 10 }}>
            <div key={0} style={{ height: `${dh}px` }}> { Number(ndviRanges[0].Max).toFixed(2) } </div>
            {ndviRanges.map((r, ri) => (
              <div key={ri+1} style={{ height: `${dh}px` }}> { Number(r.Value).toFixed(2) } </div>
            ))}
          </Box>

          <Box sx={{ left: 28, position: "absolute", top: 17 }}>
            {ndviRanges.map((r, ri) => (
              <div key={ri}>
                {ri === 0 && (
                  <Box
                    sx={{
                      width: 20,
                      height: dh / 2,
                      backgroundColor: r.Color,
                    }}
                  />
                )}
                {ri < ndviRanges.length - 1 && (
                  <Box
                    sx={{
                      width: 20,
                      height: dh,
                      background: `linear-gradient(${r.Color}, ${
                        ndviRanges[ri + 1].Color
                      })`,
                    }}
                  />
                )}
                {ri === ndviRanges.length - 1 && (
                  <Box
                    sx={{
                      width: 20,
                      height: dh / 2,
                      backgroundColor: r.Color,
                    }}
                  />
                )}
              </div>
            ))}
          </Box>
        </Box>
      </Box>

      {metadata && metadata[0] && (
  <Box sx={{ textAlign: "left", marginTop: "auto" }}>
    <Divider sx={{ marginY: 1 }} />

    {typeof metadata[0].ThirdQuartile !== 'undefined' && (
      <>
        <Typography variant="caption">
          Max: {Number(metadata[0].ThirdQuartile).toFixed(3)}
        </Typography>
        <br />
      </>
    )}

    {typeof metadata[0].Mean !== 'undefined' && (
      <>
        <Typography variant="caption">
          Mean: {Number(metadata[0].Mean).toFixed(3)}
        </Typography>
        <br />
      </>
    )}

    {typeof metadata[0].Minimum !== 'undefined' && (
      <>
        <Typography variant="caption">
          Min: {Number(metadata[0].Minimum).toFixed(3)}
        </Typography>
        <br />
      </>
    )}

    {typeof metadata[0].Cloud !== 'undefined' && (
      <>
        <Typography variant="caption">
          Cloud: {Number(metadata[0].Cloud).toFixed(1)}%
        </Typography>
      </>
    )}
  </Box>
)}

    </Box>
  );
};

export default NDVIParameterRange;
