import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, Controller } from "react-hook-form";
import {
  generateMfaCode,
  verifyMfaCode,
} from "../../../services/api-ffm-service";

const emailSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
});

const codeSchema = z.object({
  code: z.string().min(4, { message: "Code must be at least 4 digits" }),
});

interface VerifyEmailProps {
  onVerificationSuccess: (email: string) => void;
}

const VerifyEmail: React.FC<VerifyEmailProps> = ({ onVerificationSuccess }) => {
  const [email, setEmail] = useState<string>("");
  const [firstStep, setFirstStep] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const emailForm = useForm({
    mode: "onChange",
    resolver: zodResolver(emailSchema),
    defaultValues: { email: "" },
  });

  const codeForm = useForm({
    mode: "onChange",
    resolver: zodResolver(codeSchema),
    defaultValues: { code: "" },
  });

  const handleSendCode = async (data: { email: string }) => {
    setIsLoading(true);
    try {
      await generateMfaCode(data.email);
      setEmail(data.email);
      setFirstStep(false);
    } catch (error: any) {
      setError(error.message);
    }
    setIsLoading(false);
  };

  const handleVerifyCode = async (data: { code: string }) => {
    setIsLoading(true);
    try {
      await verifyMfaCode(Number(data.code), email);
      onVerificationSuccess(email);
    } catch (error: any) {
      setError(error.message);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Typography variant="h6">Verify your Email</Typography>
      {firstStep ? (
        <form onSubmit={emailForm.handleSubmit(handleSendCode)}>
          <Controller
            name="email"
            control={emailForm.control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Email"
                fullWidth
                margin="normal"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={!emailForm.formState.isValid || isLoading}
          >
            Send Verification Code
            {isLoading && (
              <CircularProgress color="inherit" size={"15px"} sx={{ ml: 2 }} />
            )}
          </Button>
        </form>
      ) : (
        <form onSubmit={codeForm.handleSubmit(handleVerifyCode)}>
          <TextField
            label="Email"
            value={email}
            fullWidth
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
          />
          <Controller
            name="code"
            control={codeForm.control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Verification Code"
                fullWidth
                margin="normal"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={!codeForm.formState.isValid || isLoading}
          >
            Verify Code
            {isLoading && (
              <CircularProgress color="inherit" size={"15px"} sx={{ ml: 2 }} />
            )}
          </Button>{" "}
          <Typography
            variant="caption"
            display="block"
            sx={{ marginTop: 1 }}
            color={"red"}
          >
            {error}
          </Typography>
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            sx={{ marginTop: 2 }}
          >
            Didn't receive a code?{" "}
            <Link href="#" onClick={() => handleSendCode({ email })}>
              Send a new code
            </Link>
          </Typography>
        </form>
      )}
    </>
  );
};

export default VerifyEmail;
