import { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb } from "../atom/breadcrumbs";
import { getNdviDates } from "../../services/api-gs-service";
import { Controller, useForm } from "react-hook-form";
import { useConfig } from "../../context/ConfigContextTest";
import NDVIParameterRange from "../molecules/ndviScale";
import Dropdown from "../atom/dropdown";

const CropDataPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const initialFieldData = location.state?.fieldData;
  const [isLoading, setIsLoading] = useState(false);
  const [fieldData, setFieldData] = useState(initialFieldData);
  const [iframeUrl, setIframeUrl] = useState("");
  const [ndviDates, setNdviDates] = useState<any[]>([]);
  const { selectedOrganization, mappedfields } = useConfig();

  const {
    control,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({});

  const selectedFieldId = watch("fieldId");
  const selectedViewId = watch("viewId");

  const cloudMaxCoverage = 5;

  useEffect(() => {
    if (location.state?.fieldData !== fieldData) {
      setFieldData(location.state?.fieldData);
    }
  }, [location.state?.fieldData, fieldData]);

  useEffect(() => {
    if (selectedFieldId) {
      fetchNdviDates(selectedFieldId);
    }
  }, [selectedFieldId]);

  const fetchNdviDates = async (fieldId) => {
    const dates = await getNdviDates(fieldId, cloudMaxCoverage);
    setNdviDates(dates);

    if (dates && dates.length > 0) {
      setValue("viewId", dates[0]?.viewId || "");
    }
  };

  const handleDateChange = (event: SelectChangeEvent<string | number>) => {
    setValue("viewId", event.target.value);
  };

  const handleFieldChange = (event: SelectChangeEvent<string | number>) => {
    setValue("fieldId", event.target.value);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    navigate(-1);
  };

  const handleGenerate = async (data) => {
    setIsLoading(true);

    try {
      const eosViewId = selectedViewId;
      const url = `${process.env.REACT_APP_MAPPING_TOOL}/ndvi/${selectedOrganization?.partyIdentifier}/${selectedFieldId}?EOSViewId=${eosViewId}`;

      setIframeUrl(url);
    } catch (error) {
      console.error("Failed to fetch EOSViewId:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Breadcrumb
          crumbs={[
            { text: "<< Back", onClick: handleClick, underline: "hover" },
            { text: "Insights", onClick: undefined, underline: "none" },
          ]}
          currentCrumb={"Crop"}
        ></Breadcrumb>
      </Grid>
      <Grid item xs={12} sx={{ p: 2, pb: 0 }}>
        <Typography variant="h6" sx={{ mb: 1 }}>
          Crop Insights
          <Typography variant="caption" sx={{ ml: 1 }}>
            (Complete all required fields and then click the button.)
          </Typography>
          <Divider />
        </Typography>
      </Grid>

      <Paper
        className="overlay"
        sx={{ width: "540px", pl: 1, pb: 0.5, pt: 0, pr: 0 }}
      >
        <form onSubmit={handleSubmit(handleGenerate)}>
          <Grid container direction={"row"}>
            <Grid item xs={4.5} sx={{ pr: 1 }}>
              <Controller
                name="fieldId"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    label={"Select Field"}
                    name="fieldId"
                    value={field.value || ""}
                    items={mappedfields?.map((field) => ({
                      value: field.fieldId,
                      label: field.name,
                    }))}
                    onChange={(event) => {
                      handleFieldChange(event);
                      field.onChange(event);
                    }}
                    sx={{ minWidth: 120 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4.5} sx={{ pr: 1 }}>
              <Controller
                name="viewId"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    label={"Select Date"}
                    name="viewId"
                    value={field.value || ""}
                    items={ndviDates?.map((date) => ({
                      value: date.viewId,
                      label: date.date,
                    }))}
                    onChange={(event) => {
                      handleDateChange(event);
                      field.onChange(event);
                    }}
                    sx={{ minWidth: 120 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
                sx={{ mt: "10px" }}
              >
                Display NDVI
                {isLoading && (
                  <CircularProgress
                    color="inherit"
                    size={"15px"}
                    sx={{ ml: 1 }}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      {iframeUrl && (
        <Grid container>
          <Grid item xs={11}>
            <iframe
              width="100%"
              height="500"
              style={{ border: 0 }}
              src={iframeUrl}
              aria-hidden="false"
            ></iframe>
          </Grid>
          <Grid item xs={1}>
            <NDVIParameterRange
              fieldId={selectedFieldId}
              eosViewId={selectedViewId}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CropDataPage;
