import { useState, useEffect } from "react";
import {
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb } from "../atom/breadcrumbs";
import { getFields } from "../../services/api-gs-service";
import { useFetchData } from "../../hooks/useFetchData";
import { Controller, useForm } from "react-hook-form";
import DateSelector from "../atom/dateSelect";
import { getWeather, getRecentWeather } from "../../services/api-ws-service";
import CheckboxesTags from "../atom/checkboxesSelect";
import WeatherLineChart from "../molecules/weatherChart";
 
import dayjs from "dayjs";
import { useConfig } from "../../context/ConfigContextTest";

import WindIcon from "../../assets/icons/weather/svg/wind_icon.svg"; 
 
const CropClimatePage = () => {
  const location = useLocation();
  const initialFieldData = location.state?.fieldData;
 
  const [isLoading, setIsLoading] = useState(false);
  const [fieldData, setFieldData] = useState(initialFieldData);
  const [fields, setFields] = useState<any[]>([]);
  const { selectedOrganization } = useConfig();

  const [rotation, setRotation] = useState<string>("");
 
  useFetchData(getFields, setFields, undefined, [
    selectedOrganization?.partyIdentifier ?? 0,
  ]);
 
  const [weather, setWeather] = useState<any>([]);
 
  useFetchData(getWeather, setWeather, undefined, [
    selectedOrganization?.partyIdentifier ?? "",
  ]);
 
  const [recentWeather, setRecentWeather] = useState<any>([]);
 
  useFetchData(getRecentWeather, setRecentWeather, undefined, [
    selectedOrganization?.partyIdentifier ?? "",
  ]);
 
 
  const [polygonData, setPolygonData] = useState();
 
 
  const handleMessage = (event) => {
    if (event.origin !== process.env.REACT_APP_MAPPING_TOOL) {
      console.warn("Ignoring message from unexpected origin:", event.origin);
      return;
    }
    const { data } = event;
    if (data.type === "updatedPolygon") {
      setPolygonData(data);
    }
  };
 
  useEffect(() => {
    if (location.state?.fieldData !== fieldData) {
      setFieldData(location.state?.fieldData);
    }
 
    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [location.state?.fieldData, fieldData]);
 
  const navigate = useNavigate();
  function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();
    navigate(-1);
  }
 
  function handleHome(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();
    navigate("/");
  }
 
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fieldID: fields[0]?.fieldId,
      fromDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
      toDate: dayjs().format("YYYY-MM-DD"),
    },
  });
 
  useEffect(() => {
    if (fields.length > 0) {
      const firstFieldId = fields[0].fieldId;
      setValue('fieldID', firstFieldId);
    }
  }, [fields, setValue]);
 
  const selectedField = watch("fieldID");
  const fromDate = watch("fromDate");
  const toDate = watch("toDate");
 
  const [mostRecentDate, setMostRecentDate] = useState<string>("");
 
  useEffect(() => {
    if (fromDate && toDate) {
      const start = dayjs(fromDate);
      const end = dayjs(toDate);
 
      const maxStartDate = end.subtract(3, "month");
 
      if (start.isBefore(maxStartDate)) {
        setValue('fromDate', maxStartDate.format("YYYY-MM-DD"));
      } else if (start.isAfter(end)) {
        setValue('fromDate', end.format("YYYY-MM-DD"));
      }
    }
  }, [toDate, fromDate, setValue]);
 
  useEffect(() => {
    if (fromDate && toDate) {
      const start = dayjs(fromDate);
      const end = dayjs(toDate);
 
      const maxEndDate = start.add(3, "month");
 
      if (end.isAfter(maxEndDate)) {
        setValue('toDate', maxEndDate.format("YYYY-MM-DD"));
      } else if (end.isBefore(start)) {
        setValue('toDate', start.format("YYYY-MM-DD"));
      }
    }
  }, [fromDate, toDate, setValue]);
 
  useEffect(() => {
    if (selectedField && fromDate && toDate) {
      setIsLoading(true);
      getWeather(
        selectedField,
        fromDate,
        toDate,
        "Precipitation,UV Index,Temperature at 2m,Temperature at -5cm,Temperature at -15cm,Relative Humidity at 2m,Soil Moisture at -5cm,Soil Moisture at -15cm"
      ).then((data) => {
          setWeather(data);
          addUnitsToWeatherData(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching weather:", error);
          setIsLoading(false);
        });
    }
  }, [selectedField, fromDate, toDate]);
 
  useEffect(() => {
    if (selectedField) {
      setIsLoading(true);
      getRecentWeather(
        selectedField,
        "Precipitation,UV Index,Temperature at -5cm,Temperature at -15cm,Wind Speed at 2m,Wind Direction at 2m,Soil Moisture at -5cm,Soil Moisture at -15cm"
      )
      .then((data) => {
        const weatherData = {};
 
        data.forEach((item) => {
          let value = item.value;
 
          if (item.parameter === "Wind Direction at 2m") {
            
            const rotation = degreesToRotation(value);
            value = degreesToCompass(value);
            setRotation(rotation);
          }
 
          weatherData[item.parameter] = {
            value: value,
            dateTime: dayjs(item.dateTime).format("YYYY-MM-DD")
          };
         
        });
 
        setRecentWeather(weatherData);
 
        const mostRecent = weatherData["Soil Temperature at -5cm"]?.dateTime;
        if (mostRecent) {
          setMostRecentDate(dayjs(mostRecent).format("YYYY-MM-DD"));
        }
 
        setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching weather:", error);
          setIsLoading(false);
        });
    }
  }, [selectedField]);
 
  useEffect(() => {
    if (mostRecentDate) {
      setValue("toDate", mostRecentDate);
    }
  }, [mostRecentDate, setValue]);
 
  const addUnitsToWeatherData = (data) => {
    const units = {
      "Precipitation": "mm",
      "Temperature at 2m": "°C",
      "Soil Temperature at -5cm": "°C",
      "Soil Temperature at -15cm": "°C",
      "Relative Humidity at 2m": "%",
      "Wind Speed at 2m": "m/s",
      "Soil Moisture at -5cm": "m³/m³",
      "Soil Moisture at -15cm": "m³/m³",
    };
 
    const weatherWithUnits = data.map((entry, index) => ({
      ...entry,
      parameter: `${entry.parameter} ${units[entry.parameter] ? `(${units[entry.parameter]})` : ""}`,
      key: index,
    }));
 
    setWeather(weatherWithUnits);
  };
 
  const degreesToCompass = (degrees: number): string => {
    const directions = [' N', ' NE', ' E', ' SE', ' S', ' SW', ' W', ' NW'];
    return directions[Math.round(degrees / 45) % 8];
  };

  const degreesToRotation = (degrees: number): string => {
    const normalizedDegrees = (degrees + 360) % 360;
    return `rotate(${normalizedDegrees}deg)`;
  };
  
 
 
  return (
    <Grid container>
      <Grid item xs={12}>
        <Breadcrumb
          crumbs={[
            { text: "<< Back", onClick: handleClick, underline: "hover" },
            { text: "Insights", onClick: undefined, underline: "none" },
          ]}
          currentCrumb={"Climate"}
        ></Breadcrumb>
      </Grid>
      <Grid item xs={12} sx={{ p: 2, pb: 0 }}>
        <Typography variant="h6" sx={{ mb: 1 }}>
          Climate Insights
          <Divider />
        </Typography>
      </Grid>
      <form>
        <Grid container spacing={1} m={1}>
          <Grid item xs={4}>
            <Controller
              name="fieldID"
              control={control}
              defaultValue={fields.length > 0 ? [fields[0].fieldId] : []}
              render={({ field }) => (
                <CheckboxesTags
                  {...field}
                  types={fields}
                  label={"Select Field"}
                  value={field.value}
                  onChange={(event, value) => field.onChange(value?.fieldId)}
                ></CheckboxesTags>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="fromDate"
              control={control}
              render={({ field }) => (
                <DateSelector
                  label="Start Date"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="toDate"
              control={control}
              render={({ field }) => (
                <DateSelector
                  label="End Date"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>

      <Grid container m={1}>
        <Grid item xs={12} sx={{ ml: 2 }}>
          {weather && weather.length > 0 ? (
                <WeatherLineChart data={weather} />
              ) : (
                <Typography variant="subtitle1" color="text.secondary">
                  No data to display for the dates selected.
                </Typography>
              )}
            </Grid>
          </Grid>

      {mostRecentDate && (
      <Grid container m={1}>
        <Grid item xs={12} sx={{ ml: 2 }}>
          <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>
            CURRENT CLIMATE CONDITIONS ({mostRecentDate})
          </Typography>
        </Grid>
       
        <Grid item xs={4}>
          <Paper elevation={3} sx={{ p: 4, m: 1 }}>
            <Typography variant="h6" color="text.secondary" gutterBottom>
              Soil Temperature
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="right">Cm</TableCell>
                    <TableCell align="right">°C</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key={"1"}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="right">-5cm</TableCell>
                    <TableCell align="right">
                      {recentWeather["Soil Temperature at -5cm"]?.value}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key={"2"}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="right">-15cm</TableCell>
                    <TableCell align="right">
                      {recentWeather["Soil Temperature at -15cm"]?.value}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
 
        <Grid item xs={4}>
          <Paper elevation={3} sx={{ p: 4, m: 1 }}>
            <Typography variant="h6" color="text.secondary" gutterBottom>
              Soil Moisture
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="right">Cm</TableCell>
                    <TableCell align="right">m³/m³</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key={"1"}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="right">-5cm</TableCell>
                    <TableCell align="right" >
                      {recentWeather["Soil Moisture at -5cm"]?.value}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key={"2"}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="right">-15cm</TableCell>
                    <TableCell align="right">
                      {recentWeather["Soil Moisture at -15cm"]?.value}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
 
        <Grid item xs={4}>
          <Paper elevation={3} sx={{ p: 2, m: 1 }}>
            <Typography variant="h6" color="text.secondary" gutterBottom>
              UV Index
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {recentWeather["UV Index"]?.value}
            </Typography>
 
            <Typography variant="h6" color="text.secondary" gutterBottom sx={{ mt: 2 }}>
              Rainfall
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {recentWeather["Precipitation"]?.dateTime}: {recentWeather["Precipitation"]?.value} mm
            </Typography>
 
            <Typography variant="h6" color="text.secondary" gutterBottom sx={{ mt: 2 }}>
              Wind
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
            <img 
                src={WindIcon} 
                alt="Wind direction" 
                style={{ width: '15px', height: '15px', transform: rotation }}
              />
              {recentWeather["Wind Direction at 2m"]?.value} {recentWeather["Wind Speed at 2m"]?.value} m/s
            </Typography>
          </Paper>
        </Grid>
 
      </Grid>
                  )}
    </Grid>
  );
};
 
export default CropClimatePage;