import { useState, useEffect } from "react";
import { useConfig } from "../../context/ConfigContextTest";
import Loading from "../pages/loading";
import { styled } from "@mui/material/styles";
import StepperForm from "../organisms/stepperForm";
import Header from "../organisms/header";
import NavigationDrawer from "../organisms/navigationDrawer";
import Routing from "../../routes/router";
import Footer from "../organisms/footer";

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})<{ open?: boolean }>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 55,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 240,
  }),
}));

const AppContent = ({ open, handleDrawerOpen, handleDrawerClose }) => {
  const { selectedOrganization, configLoaded } = useConfig();
  const [isLoading, setIsLoading] = useState(true);
  console.log("selectedOrganization - APPCONTENT", selectedOrganization);
  console.log("configLoaded - APPCONTENT", configLoaded);

  useEffect(() => {
    if (configLoaded) {
      setIsLoading(false);
    }
  }, [configLoaded]);

  if (isLoading) {
    return <Loading />;
  }

  if (configLoaded && !selectedOrganization) {
    return <StepperForm />;
  }

  return (
    <>
      <Header />
      <NavigationDrawer
        openDrawer={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      />
      <Main
        open={selectedOrganization ? open : false}
        sx={{
          //minHeight: "102vh",
          height: "calc(100vh - 30px)",
          marginTop: 0,
          padding: 0,
        }}
      >
        <Routing />
      </Main>
      {selectedOrganization && <Footer open={open} />}
    </>
  );
};

export default AppContent;