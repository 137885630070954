import axios from "axios";

// Set up the axios instance with the base URL and default headers
const api = axios.create({
  baseURL: process.env.REACT_APP_MAPPING_TOOL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const getNdviParameterRange = async (): Promise<any> => {
  try {
    const response = await api.get<any>("ndvi/internal/parameter/range", {});
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to fetch fields: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      console.error("Something went wrong while fetching fields", error);
      return null;
    }
  }
};

export const getNdviMetadata = async (
  eosLocationCropperRefId: number,
  eosViewId: string
): Promise<any> => {
  try {
    const response = await api.get<any>("ndvi/internal/metadata", {
      params: { eosLocationCropperRefId, eosViewId },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to fetch fields: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      console.error("Something went wrong while fetching fields", error);
      return null;
    }
  }
};



export const getNdviData = async (
  eosLocationCropperRefId: number,
  eosViewId: string,
  zoom: number
): Promise<any> => {
  try {
    const response = await api.get<any>("ndvi/internal/data", {
      params: { eosLocationCropperRefId, eosViewId, zoom },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to fetch fields: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      console.error("Something went wrong while fetching fields", error);
      return null;
    }
  }
};
