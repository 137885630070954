// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  font-family: "Poppins" !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  margin-top: 20vh;
  max-height: 35vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10rgb (37, 46, 62) min);
  color: #3c4f1e;
  font-family: "Poppins";
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 600px) {
  .css-hyum1k-MuiToolbar-root {
    min-height: 50px !important;
  }
  .css-ymzihv {
    min-height: 50px !important;
  }
}

.title {
  font-size: 20px;
  color: #333;
  margin-top: 0;
  margin-bottom: 17px;
}

.subtitle {
  font-size: 10px;
  color: #777;
}

/* Overlay.css */
.overlay {
  position: absolute;
  top: 160px;
  margin-left: 15px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 1;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iCAAiC;AACnC;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,uCAAuC;EACvC,cAAc;EACd,sBAAsB;AACxB;;;AAGA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE;IACE,2BAA2B;EAC7B;EACA;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE,eAAe;EACf,WAAW;EACX,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA,gBAAgB;AAChB;EACE,kBAAkB;EAClB,UAAU;EACV,iBAAiB;EACjB,oCAAoC,EAAE,2BAA2B;EACjE,UAAU;AACZ","sourcesContent":[".App {\r\n  text-align: center;\r\n  font-family: \"Poppins\" !important;\r\n}\r\n\r\n.App-logo {\r\n  height: 40vmin;\r\n  pointer-events: none;\r\n}\r\n\r\n.App-header {\r\n  margin-top: 20vh;\r\n  max-height: 35vh;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  font-size: calc(10rgb (37, 46, 62) min);\r\n  color: #3c4f1e;\r\n  font-family: \"Poppins\";\r\n}\r\n\r\n\r\n.App-link {\r\n  color: #61dafb;\r\n}\r\n\r\n@keyframes App-logo-spin {\r\n  from {\r\n    transform: rotate(0deg);\r\n  }\r\n  to {\r\n    transform: rotate(360deg);\r\n  }\r\n}\r\n\r\n@media (min-width: 600px) {\r\n  .css-hyum1k-MuiToolbar-root {\r\n    min-height: 50px !important;\r\n  }\r\n  .css-ymzihv {\r\n    min-height: 50px !important;\r\n  }\r\n}\r\n\r\n.title {\r\n  font-size: 20px;\r\n  color: #333;\r\n  margin-top: 0;\r\n  margin-bottom: 17px;\r\n}\r\n\r\n.subtitle {\r\n  font-size: 10px;\r\n  color: #777;\r\n}\r\n\r\n/* Overlay.css */\r\n.overlay {\r\n  position: absolute;\r\n  top: 160px;\r\n  margin-left: 15px;\r\n  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */\r\n  z-index: 1;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
