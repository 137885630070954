import { ActivityCategory } from '../interfaces/activityCategory.interface';
import { NoteType } from '../interfaces/noteType.interface';

export const parseNoteTypeProperties = (noteTypes: NoteType[]): NoteType[] => {
  return noteTypes.map(noteType => {
    const parsedProperties = typeof noteType.properties === 'string'
      ? JSON.parse(noteType.properties)
      : noteType.properties;

    return {
      ...noteType,
      properties: parsedProperties,
    };
  });
};

export const parseActivityCategoryProperties = (activityCategories: ActivityCategory[]): ActivityCategory[] => {
  return activityCategories.map(category => {
    const parsedProperties = typeof category.properties === 'string'
      ? JSON.parse(category.properties)
      : category.properties;

    return {
      ...category,
      properties: parsedProperties,
    };
  });
};