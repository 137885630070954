import React, { useEffect, useState } from "react";
import {
  Grid,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  MenuItem,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import DynamicFormDialog from "../molecules/dialog";
import { useDropzone } from "react-dropzone";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useConfig } from "../../context/ConfigContextTest";
import TextBox from "../atom/textBox";
import MultiSelect from "../molecules/MultiSelect";
import { addNoneOption } from "../../utils/commonUtils";

const MAX_FILE_SIZE = Number(process.env.REACT_APP_MAX_FILE_SIZE) || 10 * 1024 * 1024;

const Circle = ({ color }) => (
  <Box
    style={{
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      backgroundColor: color,
      marginLeft: "auto",
    }}
  />
);

interface NoteDocuments {
  documentId?: number | null;
  noteDocumentId?: number | null;
  name: string;
  extention: string;
  attachment: File | null | string;
}

interface NotesFormData {
  noteId?: number;
  documents?: NoteDocuments[];
  fieldId?: number;
  noteTypeId?: number;
  title: string;
  description?: string;
  noteProperty?: any;
  [key: string]: any;
}

interface NotesDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: NotesFormData) => void;
  formData?: NotesFormData;
}

const NotesDialog: React.FC<NotesDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  formData,
}) => {
  const { noteTypes, mappedfields } = useConfig();
  const [fileErrors, setFileErrors] = useState<string[]>([]);
  const [dynamicFields, setDynamicFields] = useState<any[]>([]);
  const [updatedMappedFields, setUpdatedMappedFields] = useState<any[]>([]);

  const generalNoteTypeId = noteTypes[0].noteTypeId;

  const baseSchema = z.object({
    noteTypeId: z.number().nonnegative("Note type is required"),
    title: z.string().min(3, "Title must be at least 3 characters long"),
    description: z.string().max(200, "Description must be at most 200 characters long").optional(),
    fieldId: z.number().optional(),
    documents: z.array(
      z.object({
        name: z.string().min(1, "File name is required"),
        extention: z.string(),
        attachment: z.any(),
        documentId: z.number(),
        noteDocumentId: z.number(),
      })
    ).optional(),
  });

  const dynamicSchema = dynamicFields.reduce((acc, field) => {
    if (field.required) {
      if (field.type === "multiSelect") {
        acc[field.id] = z.array(z.string()).min(1, `${field.label} is required`);
      } else {
        acc[field.id] = z.string().min(1, `${field.label} is required`);
      }
    }
    return acc;
  }, {} as any);

  const combinedSchema = baseSchema.extend(dynamicSchema).superRefine((data, ctx) => {
    if (data.noteTypeId !== generalNoteTypeId && (!data.fieldId || data.fieldId <= 0)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Field is required for note type",
        path: ["fieldId"],
      });
    }
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
  } = useForm<NotesFormData>({
    mode: "onChange",
    resolver: zodResolver(combinedSchema),
    defaultValues: {
      noteId: 0,
      noteTypeId: noteTypes[0]?.noteTypeId,
      fieldId: formData?.fieldId ?? (updatedMappedFields.length > 0 ? updatedMappedFields[0].fieldId : -1),
      title: "",
      description: "",
      Properties: {},
      documents: [],
    },
  });  

  const noteTypeId = watch("noteTypeId");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "documents",
  });

  useEffect(() => {
    if (isOpen) {
      if (formData) {
        const parsedNoteProperty = formData.properties ? JSON.parse(formData.properties) : {};

        reset({
          ...formData,
          ...parsedNoteProperty,
          fieldId: formData.fieldId ?? (updatedMappedFields.length > 0 ? updatedMappedFields[0].fieldId : -1),
          documents: formData.documents || [],
        });
  
        Object.keys(parsedNoteProperty).forEach((key) => {
          setValue(key, parsedNoteProperty[key]);
        });
      } else {
        reset({
          noteTypeId: noteTypes[0]?.noteTypeId || 0,
          fieldId: updatedMappedFields.length > 0 ? updatedMappedFields[0].fieldId : -1,
          title: "",
          description: "",
          properties: {},
          documents: [],
        });
      }
  
      setFileErrors([]);
    } else {
      // Clear documents and errors when the dialog is closed
      reset({
        noteTypeId: noteTypes[0]?.noteTypeId || 0,
        fieldId: updatedMappedFields.length > 0 ? updatedMappedFields[0].fieldId : -1,
        title: "",
        description: "",
        properties: {},
        documents: [],
      });
      setFileErrors([]);
      setDynamicFields([]);
    }
  }, [isOpen, reset, formData, noteTypes, setValue, updatedMappedFields]);
  
  useEffect(() => {
    const selectedNoteType = noteTypes.find((type) => type.noteTypeId === noteTypeId);
    if (selectedNoteType) {
      const parsedFields = Array.isArray(selectedNoteType?.properties?.fields)
        ? selectedNoteType.properties.fields
        : [];
  
      setDynamicFields(parsedFields);
  
      if (!formData) {
        parsedFields.forEach((field) => {
          const existingValue = formData?.[field.id];
    
          if (existingValue !== undefined && existingValue !== null) {
            setValue(field.id, existingValue);
          } else if (field.required && field.options && field.options.length > 0) {
            setValue(field.id, field.type === 'multiSelect' ? [field.options[0].id] : field.options[0].id);
          }
        });
      }

      const currentFieldId = watch('fieldId');
      if (!formData || !formData.fieldId) {
        setValue(
          'fieldId',
          currentFieldId && currentFieldId !== -1 ? currentFieldId : updatedMappedFields.length > 0 ? updatedMappedFields[0].fieldId : -1
        );
      }

      clearErrors('fieldId'); 
    }
  }, [noteTypeId, noteTypes, setValue, clearErrors, formData, updatedMappedFields, watch]);
  
  useEffect(() => {
    const mappedFieldsWithNone = addNoneOption(mappedfields, "None", "fieldId", {
      cropperRef: '',
      coords: '',
      partyId: '',
    });

    setUpdatedMappedFields(mappedFieldsWithNone);
  }, [mappedfields]);
  
  const handleFormSubmit = (data: any) => {
    console.log(data);

    const property: { [key: string]: any } = {};

    dynamicFields.forEach((field) => {
      const value = data[field.id];
      if (value !== undefined && value !== null) {
        property[field.id] = value;
      }
    });

    const updatedData = {
      ...data,
      noteId: formData?.noteId ?? data.noteId,
      Properties: JSON.stringify(property),
    };
    
    console.log(updatedData);
    onSubmit(updatedData);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
      "application/vnd.ms-excel": [".xls", ".xlsx", ".csv"],
      "application/msword": [".doc", ".docx"],
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/tiff": [".tif", ".tiff"],
      "image/svg+xml": [".svg"],
      "image/vnd.adobe.photoshop": [".psd"],
    },
    maxSize: MAX_FILE_SIZE,
    onDrop: (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) => ({
        documentId: 0,
        noteDocumentId: 0,
        name: file.name.replace(/\.[^/.]+$/, ""),
        extention: file.name.split(".").pop() || "unknown",
        attachment: file,
      }));
      append(newFiles);
      setFileErrors([]);
    },
    onDropRejected: (rejectedFiles) => {
      const errors = rejectedFiles.map(
        (rejectedFile) => `File ${rejectedFile.file.name} is too large. Max file size is 10MB.`
      );
      setFileErrors(errors);
    },
  });

  const formContent = (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <DialogContent dividers sx={{ pl: 5 }}>
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid item xs={12}>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <TextBox
                  {...field}
                  label="Note Title"
                  error={!!errors.title}
                  helperText={errors.title?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextBox
                  {...field}
                  label="Description"
                  error={!!errors.description}
                  helperText={errors.description?.message}
                  multiline
                  rows={4}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="fieldId"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextBox
                  select
                  id="fieldId"
                  label="Field"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.fieldId}
                  helperText={errors.fieldId?.message}
                  inputRef={ref}
                  fullWidth
                  sx={{ mb: 1 }}
                >
                {Array.isArray(updatedMappedFields) ? (
                  updatedMappedFields.map((type) => (
                    <MenuItem key={type.fieldId} value={type.fieldId}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {type.name}
                      </div>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Options Available</MenuItem>
                )}
                </TextBox>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="noteTypeId"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextBox
                  select
                  id="noteTypeId"
                  label="Note Type"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.noteTypeId}
                  helperText={errors.noteTypeId?.message}
                  inputRef={ref}
                  fullWidth
                  sx={{ mb: 1 }}
                >
                  {Array.isArray(noteTypes) ? (
                    noteTypes.map((type) => (
                      <MenuItem key={type.noteTypeId} value={type.noteTypeId}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          {type.name}
                        </div>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No Options Available</MenuItem>
                  )}
                </TextBox>
              )}
            />
          </Grid>

          {dynamicFields.map((field) => (
            <Grid item xs={6} key={field.id}>
              <Controller
                name={field.id}
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => {
                  if (field.type === "multiSelect") {
                    return (
                      <MultiSelect
                        label={field.label}
                        value={value || []}
                        onChange={onChange}
                        options={field.options}
                        error={!!errors[field.id]}
                        helperText={errors[field.id]?.message}
                        fieldId={field.id}
                        sx={{ mb: 1 }}
                      />
                    );
                  } else if (field.type === "select") {
                    return (
                      <TextBox
                        select
                        id={field.id}
                        label={field.label}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors[field.id]}
                        helperText={errors[field.id]?.message}
                        inputRef={ref}
                        fullWidth
                        sx={{ mb: 1 }}
                        disabled={field.disabled}
                      >
                        {Array.isArray(field.options) ? (
                          field.options
                            .filter((option) => option.value !== "")
                            .map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  {option.label}
                                  {option.color && <Circle color={option.color} />}
                                </div>
                              </MenuItem>
                            ))
                        ) : (
                          <MenuItem disabled>No Options Available</MenuItem>
                        )}
                      </TextBox>
                    );
                  } else {
                    return (
                      <TextBox
                        id={field.id}
                        label={field.label}
                        placeholder={field.placeholder}
                        type={field.type}
                        value={value}
                        min={field.min}
                        max={field.max}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!errors[field.id]}
                        disabled={field.disabled}
                        helperText={errors[field.id]?.message}
                        readOnly={field.readOnly}
                        sx={{ mb: 0 }}
                        inputRef={ref}
                      />
                    );
                  }
                }}
              />
            </Grid>
          ))}

          <Grid item xs={12}>
            {fields.map((field, index) => (
              <Grid container spacing={2} key={field.id} sx={{ marginBottom: 2 }}>
                <Grid item xs={9}>
                  <Controller
                    name={`documents.${index}.name`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="File Name"
                        fullWidth
                        size="small"
                        margin="dense"
                        error={!!errors?.documents?.[index]?.name}
                        helperText={errors?.documents?.[index]?.name?.message}
                        disabled={!!fields[index]?.documentId}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1 }}
                    onClick={() => remove(index)}
                  >
                    Remove
                  </Button>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Box
              {...getRootProps()}
              sx={{
                border: "2px dashed #cccccc",
                padding: "20px",
                textAlign: "center",
                cursor: "pointer",
                borderRadius: "5px",
                marginTop: 2,
              }}
            >
              <input {...getInputProps()} />
              <Typography>
                Drag 'n' drop files here, or click to select files
              </Typography>
            </Box>
            {fileErrors.length > 0 && (
              <Typography color="error">{fileErrors.join(", ")}</Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          startIcon={<SaveIcon />}
          disabled={false}
        >
          {formData ? "Update" : "Save"}
        </Button>
      </DialogActions>
    </form>
  );

  return (
    <DynamicFormDialog
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(handleFormSubmit)}
      title={formData ? "Update Note" : "Add Note"}
      formContent={formContent}
    />
  );
};

export default NotesDialog;
