import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Snackbar,
  Alert,
  Button,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AlertColor } from "@mui/material/Alert";

const CustomSnackbar = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState<string | null>(null);
  const [severity, setSeverity] = useState<AlertColor>("success");
  const [showMessage, setShowMessage] = useState(false);

  useImperativeHandle(ref, () => ({
    handleOpen: (title: string, msg: string | null, sev: AlertColor) => {
      setTitle(title);
      setMessage(msg);
      setShowMessage(true);
      setSeverity(sev);
      setOpen(true);
    },
  }));

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleDismiss = () => {
    setOpen(false);
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ top: "55px" }}
      >
        <Alert
          severity={severity}
          variant="filled"
          action={
            <>
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleDismiss}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
          onClose={handleClose}
        >
          <Box>
            <Typography variant="subtitle1" component="div">
              <strong>{title}</strong>
            </Typography>
            {showMessage && message && (
              <Typography variant="body2" component="div">
                {message}
              </Typography>
            )}
          </Box>
        </Alert>
      </Snackbar>
    </>
  );
});

export default CustomSnackbar;
