import React, { useRef, useState } from "react";
import { Grid, Button, AlertColor } from "@mui/material";
import KanbanBoard from "../organisms/kanbanBoard/kanbanOverview";
import { Breadcrumb } from "../atom/breadcrumbs";
import { fetchData, useFetchData } from "../../hooks/useFetchData";
import { createActivity, getActivities, updateActivity } from "../../services/api-ffm-service";
import ActivityDialog from "../organisms/activityDialog";
import Loading from "./loading";
import GenericConfirmDialog from "../organisms/genericConfirmDialog";
import { useNavigate } from "react-router-dom";
import { handleApiResponse } from "../../utils/Utilities";
import CustomSnackbar from "../atom/CustomSnackbar";
import { useConfig } from "../../context/ConfigContextTest";

const Activities: React.FC = () => {
  const [activities, setActivities] = useState<any[]>([]);
  const [formOpen, setFormOpen] = useState(false);
  const { selectedOrganization } = useConfig();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const snackbarRef = useRef<{ handleOpen: (title: string, msg: string | null, sev: AlertColor) => void }>(null);

  useFetchData(getActivities, setActivities, setIsLoading, [
    selectedOrganization?.organizationId ?? 0,
  ]);

  const handleOpenForm = () => {
    setFormOpen(true);
    setSelectedActivity(null);
  };

  const handleCloseForm = () => {
    setSelectedActivity(null);
    setFormOpen(false);
    setConfirmOpen(false);
    setIsLoading(false);
  };

  const handleDelete = () => {
    setSelectedActivity(selectedActivity);
    setConfirmOpen(true);
  };

  const handleSubmit = async (formData: any) => {
    setIsLoading(true);
    formData.partyId = selectedOrganization?.partyId;
    formData.statusId = formData.activityStatusId;
    let response;

    const endDate = new Date(formData.endDate);
    const today = new Date();
    if (formData.statusId === 12 && endDate > today) {
      if (snackbarRef.current) {
        snackbarRef.current.handleOpen(
          "Failed!",
          "Activity status can not be set to DONE if the end date is future dated.",
          "warning"
        );
      }
    } 
    else 
    {
      if (selectedActivity) {
        try {
          response = await updateActivity(formData);
        } catch (error) {
          console.error("Error updating activity:", error);
        }
      } else {
        try {
          response = await createActivity(formData);
        } catch (error) {
          console.error("Error creating activity:", error);
        }
      }
    }

    fetchData(getActivities, setActivities, setIsLoading, [
      selectedOrganization?.organizationId ?? 0,
    ]);

    handleCloseForm();
    
    handleApiResponse(response, showSnackbar);
  };

  const showSnackbar = (title, message, severity) => {
    if (snackbarRef.current) {
      snackbarRef.current.handleOpen(title, message, severity);
    }
  };

  const navigate = useNavigate();
  function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();
    navigate(-1);
  }

  return (
    <>
      <CustomSnackbar ref={snackbarRef} />
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <Grid item xs={12}>
            <Breadcrumb
              crumbs={[
                { text: "<< Back", onClick: handleClick, underline: "hover" },
                {
                  text: "My Farm",
                  onClick: undefined,
                  underline: "none",
                },
              ]}
              currentCrumb={"Activity Manager"}
            ></Breadcrumb>
          </Grid>
          <Grid container spacing={2} padding={"10px"}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={() => handleOpenForm()}
                color="primary"
              >
                Add Activity
              </Button>
              <ActivityDialog
                isOpen={formOpen}
                onClose={handleCloseForm}
                onSubmit={handleSubmit}
                formData={selectedActivity}
                handleDelete={handleDelete}
              />
              <KanbanBoard></KanbanBoard>
              <GenericConfirmDialog
                open={confirmOpen}
                onCancel={() => setConfirmOpen(false)}
                onConfirm={() => {}}
                title="Confirm Deletion"
                content="Are you sure you want to delete this activity?"
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default Activities;
