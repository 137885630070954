import React, { useState, useEffect } from "react";
import {
  Grid,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  MenuItem,
  Box,
  Chip,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import Delete from "@mui/icons-material/Delete";
import DynamicFormDialog from "../molecules/dialog";
import { useConfig } from "../../context/ConfigContextTest";
import TextBox from "../atom/textBox";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import MultiSelect from "../molecules/MultiSelect";
import DateSelector from "../atom/dateSelect";
import theme from "../../theme";
import ActivityNotesDialog from "./ActivityNotesDialog";
import ActivityProductsDialog from "./ActivityProductsDialog";
import { getCultivars } from "../../services/api-car-service";
import DynamicFieldRenderer from "../molecules/DynamicFieldRenderer";

interface FormData {
  activityId: number;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  fields: number[];
  cost: string;
  contractWorkCost: string;
  Properties: any;
  activityCategoryId: number;
  activityStatusId: number;
  seasonStageId: number;
  ActivityNotes: Array<{
    NoteId?: number;
    Name: string;
    Title: string;
    Description: string;
    Attachment: string | null;
    DocumentExtention: string | null;
  }>;
  ProductDetail: Array<{
    ProductId: string;
    Product: string;
    Quantity: string;
    CostPerQuantity: string;
  }>;
  [key: string]: any;
}

interface ActivitiesDialogProps {
  isOpen: any;
  onClose: any;
  onSubmit: any;
  formData: any;
  handleDelete?: () => void;
}

const ActivityDialog: React.FC<ActivitiesDialogProps> = ({
  isOpen,
  onClose,
  onSubmit,
  formData,
  handleDelete,
}) => {
  const { activityCategories, activityStatuses, seasonStages, mappedfields, crops, products } = useConfig();
  const [isLoading, setIsLoading] = useState(false);
  const [dynamicFields, setDynamicFields] = useState<any[]>([]);
  const [subFields, setSubFields] = useState<any[]>([]);
  const [subSubFields, setSubSubFields] = useState<any[]>([]);
  const [isSeasonStageDisabled, setIsSeasonStageDisabled] = useState(false);
  const generalActivityCategoryId = activityCategories[0].activityCategoryId;
  const [activityNotes, setActivityNotes] = useState<FormData["ActivityNotes"]>([]);
  const [activityProducts, setActivityProducts] = useState<FormData["ProductDetail"]>([]);
  const [openDialogId, setOpenDialogId] = useState<string | null>(null);
  const [totalCost, setTotalCost] = useState<number>(0);
  
const baseSchema = z.object({
  activityCategoryId: z.number().nonnegative("Activity Category is required"),
  seasonStageId: z.number().nonnegative("Season stage is required"),
  activityStatusId: z.number().nonnegative("Status is required"),
  description: z.string().max(200, "Description must be at most 200 characters long").optional(),
  name: z.string().min(3, "Title must be at least 3 characters long"),
  endDate: z.string().min(1, "End date must be selected"),
  fields: z.array(z.number()).optional(),
  startDate: z.string().min(1, "Start date must be selected"),
  contractWorkCost: z.string().refine((val) => !isNaN(parseFloat(val)) && parseFloat(val) >= 0, {
    message: "Contract Work Cost must be zero or greater",
  }),
}).passthrough();

const dynamicSchema = dynamicFields.reduce((acc, field) => {
  if (field.required) {
    if (field.type === "multiSelect") {
      acc[field.id] = z.array(z.string()).min(1, `${field.label} is required`);
    } else {
      acc[field.id] = z.string().min(1, `${field.label} is required`);
    }
  }
  return acc;
}, {} as Record<string, z.ZodTypeAny>);

const extendedSchema = baseSchema.extend(dynamicSchema);

const schemaWithDateValidation = extendedSchema.refine(
  (data) => new Date(data.startDate) <= new Date(data.endDate),
  {
    message: "Start date must be earlier than or equal to the end date",
    path: ["startDate"],
  }
);

const combinedSchema = schemaWithDateValidation.superRefine((data, ctx) => {
  if (data.activityCategoryId !== generalActivityCategoryId && (!data.fields || data.fields.length === 0)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Field(s) are required for the selected activity category",
      path: ["fields"],
    });
  }

  dynamicFields.forEach(field => {
    if (field.required && !data[field.id]) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `${field.label} is required`,
        path: [field.id],
      });
    }
  
    if (field.conditionalRequired) {
      const { field: conditionField, value: conditionValue } = field.conditionalRequired;
      if (data[conditionField] === conditionValue && !data[field.id]) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `${field.label} is required`, // when ${conditionField} is ${conditionValue}`,
          path: [field.id],
        });
      }
    }
  });
});
 const {
    control,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
    setValue,
    watch,
  } = useForm<FormData>({
    mode: "onChange",
    resolver: zodResolver(combinedSchema),
    defaultValues: {
      activityCategoryId: activityCategories[0]?.activityCategoryId,
      seasonStageId: seasonStages[0]?.key,
      activityStatusId: activityStatuses[0]?.key,
      description: "",
      startDate: "",
      endDate: "",
      cost: "0",
      contractWorkCost: "0",
    },
  });

  const activityCategoryId = watch("activityCategoryId");
  const cropTypeOptions = crops.map((crop) => ({
    id: `cropType_${crop.CropId}`,
    label: crop.Name,
    value: crop.CropId,
  }));

  useEffect(() => {
    if (isOpen) {
      if (formData) {
        const parsedProperties = formData.property ? JSON.parse(formData.property) : {};
        const formattedStartDate = formData.startDate ? formData.startDate.split('T')[0] : "";
        const formattedEndDate = formData.endDate ? formData.endDate.split('T')[0] : "";
  
        const convertedActivityNotes = formData.notes?.map((note) => ({
          NoteId: note.noteId,
          Name: note.noteDocuments[0]?.name || '',
          Title: note.title,
          Description: note.description,
          Attachment: note.noteDocuments[0]?.documentLink || '',
          DocumentExtention: note.noteDocuments[0]?.documentExtention || '',
        })) || [];
  
        const productDetail = parsedProperties.ProductDetail || [];
        setActivityNotes(convertedActivityNotes);
        setActivityProducts(productDetail);
  
        reset({
          ...formData,
          ...parsedProperties,
          activityStatusId: formData.statusId ?? activityStatuses[0]?.key ?? "",
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          contractWorkCost: String(formData.contractWorkCost) ?? "0",
          ActivityNotes: convertedActivityNotes,
          ProductDetail: productDetail,
        });
  
        const selectedCategory = activityCategories.find((type) => type.activityCategoryId === formData.activityCategoryId);
        if (selectedCategory) {
          const parsedFields = Array.isArray(selectedCategory?.properties?.fields) ? selectedCategory.properties.fields : [];
          setDynamicFields(parsedFields);
  
          parsedFields.forEach((field) => {
            const existingValue = parsedProperties[field.id] || formData[field.id];
            setValue(field.id, existingValue ?? '');
  
            if (field.options && field.options.length > 0) {
              const selectedOption = field.options.find(option => option.id === (existingValue || field.options![0].id));
              if (selectedOption && selectedOption.properties) {
                const subFields = selectedOption.properties;
                setSubFields(subFields);
  
                subFields.forEach((subField) => {
                  const subFieldValue = parsedProperties[subField.id] || '';
                  setValue(subField.id, subFieldValue);
  
                  if (subField.options && subField.options.length > 0) {
                    const selectedSubOption = subField.options.find(option => option.id === (subFieldValue || subField.options![0].id));
                    if (selectedSubOption && selectedSubOption.properties) {
                      const subSubFields = selectedSubOption.properties;
                      setSubSubFields(subSubFields);
  
                      subSubFields.forEach((subSubField) => {
                        const subSubFieldValue = parsedProperties[subSubField.id] || '';
                        setValue(subSubField.id, subSubFieldValue);
                      });
                    }
                  }
                });
              }
            }
          });
        }
  
        clearErrors('fields');
        const matchingSeasonStage = seasonStages.find((stage) => stage.value === selectedCategory?.name);
        if (matchingSeasonStage) {
          setValue("seasonStageId", matchingSeasonStage.key);
          setIsSeasonStageDisabled(true);
        } else {
          setIsSeasonStageDisabled(false);
        }
      }else {
        const selectedCategory = activityCategories.find((type) => type.activityCategoryId === activityCategoryId);
      
        if (selectedCategory && !formData) {
          const parsedFields = Array.isArray(selectedCategory?.properties?.fields)
            ? selectedCategory.properties.fields
            : [];
        
          setDynamicFields(parsedFields);
          setSubFields([]);
          setSubSubFields([]);
          setActivityProducts([]);
        
          parsedFields.forEach((field) => {
            if (field.id === 'cropType') {
              field.options = cropTypeOptions;
      
              if (field.options.length > 0) {
                setValue('cropType', field.options[0].id);
              }
            }
      
            const existingValue = formData?.[field.id];
      
            if (existingValue !== undefined && existingValue !== null) {
              setValue(field.id, existingValue);
            } else if (field.required && field.options && field.options.length > 0) {
              const defaultOptionValue = field.options[0].id;
              setValue(field.id, field.type === 'multiSelect' ? [defaultOptionValue] : defaultOptionValue);
      
              if (field.options[0].properties) {
                setSubFields(field.options[0].properties);
      
                field.options[0].properties.forEach(subField => {
                  if (subField.required && subField.options && subField.options.length > 0) {
                    const defaultSubOptionValue = subField.options[0].id;
                    setValue(subField.id, subField.type === 'multiSelect' ? [defaultSubOptionValue] : defaultSubOptionValue);
      
                    if (subField.options[0].properties) {
                      setSubSubFields(subField.options[0].properties);
      
                      subField.options[0].properties.forEach(subSubField => {
                        const defaultSubSubOptionValue = subSubField.options?.[0]?.id ?? '';
                        setValue(subSubField.id, subSubField.type === 'multiSelect' ? [defaultSubSubOptionValue] : defaultSubSubOptionValue);
                      });
                    }
                  } else {
                    setValue(subField.id, subField.type === 'multiSelect' ? [] : '');
                  }
                });
              }
            }
          });
      
          clearErrors('fields');
      
          const matchingSeasonStage = seasonStages.find((stage) => stage.value === selectedCategory.name);
      
          if (matchingSeasonStage) {
            setValue("seasonStageId", matchingSeasonStage.key);
            setIsSeasonStageDisabled(true);
          } else {
            setIsSeasonStageDisabled(false);
          }
        }
      }
    }
  }, [isOpen, reset, formData, activityCategories, seasonStages, activityStatuses, setValue,
    activityCategoryId, clearErrors]);
  
  useEffect(() => {
    const cropType = watch('cropType');
  
    if (cropType) {
      const cropId = cropType.split('_')[1];
  
      getCultivars(parseInt(cropId, 10))
        .then((response) => {
          const cultivarOptions = response.Variants.map((variant, index) => ({
            id: `cultivar_${index}`,
            label: variant,
          }));
  
          const updatedFields = dynamicFields.map((field) => {
            if (field.id === 'cultivar') {
              return {
                ...field,
                options: cultivarOptions,
              };
            }
            return field;
          });
  
          setDynamicFields(updatedFields);
  
          const existingCultivar = formData?.cultivar || watch('cultivar');
          const cultivarToSet = existingCultivar || (cultivarOptions[0]?.id || '');
          setValue('cultivar', cultivarToSet);
        })
        .catch((error) => {
          console.error('Error fetching cultivars:', error);
        });
    }
  }, [watch('cropType'), formData]);
   
  const handleFieldChange = (fieldId: string, selectedValue: string) => {
    const selectedField = dynamicFields.find(field => field.id === fieldId) 
      || subFields.find(field => field.id === fieldId) 
      || subSubFields.find(field => field.id === fieldId);
    const selectedOption = selectedField?.options?.find(option => option.id === selectedValue);
  
    if (!selectedOption) return;
  
    if (dynamicFields.some(field => field.id === fieldId)) {
      const newSubFields = selectedOption?.properties || [];
      setSubFields(newSubFields);
      setSubSubFields([]);
  
      newSubFields.forEach(subField => {
        if (subField.options && subField.options.length > 0) {
          const defaultValue = subField.type === 'multiSelect' ? [subField.options[0].id] : subField.options[0].id;
          setValue(subField.id, defaultValue);
  
          handleFieldChange(subField.id, defaultValue);
        } else {
          setValue(subField.id, subField.type === 'multiSelect' ? [] : '');
        }
      });

      setActivityProducts([]);
      setValue("ProductDetail", []);
    }
  
    else if (subFields.some(field => field.id === fieldId)) {
      const newSubSubFields = selectedOption?.properties || [];
      setSubSubFields(newSubSubFields);
      
      newSubSubFields.forEach(subSubField => {
        if (subSubField.options && subSubField.options.length > 0) {
          const subSubDefaultValue = subSubField.type === 'multiSelect' ? [subSubField.options[0].id] : subSubField.options[0].id;
          setValue(subSubField.id, subSubDefaultValue);
        } else {
          setValue(subSubField.id, subSubField.type === 'multiSelect' ? [] : '');
        }
      });
    }
  };
  
  const handleOpenDialog = (dialogId: string) => {
    setOpenDialogId(dialogId);
  };
  
  const handleCloseDialog = () => {
    setOpenDialogId(null);
  };

  const handleCloseMainDialog = () => {
    onClose();
    reset();
    setActivityNotes([]);
    setActivityProducts([]);
    setDynamicFields([]);
    setSubFields([]);
    setSubSubFields([]);
  };

  const handleRequestDeleteNote = (index: number) => {
    const updatedNotes = [...activityNotes];
    updatedNotes.splice(index, 1);
    setActivityNotes(updatedNotes);
    setValue("ActivityNotes", updatedNotes);
  };

  const handleRequestDeleteProduct = (index: number) => {
    const updatedProducts = [...activityProducts];
    updatedProducts.splice(index, 1);
    setActivityProducts(updatedProducts);
    setValue("ProductDetail", updatedProducts);
  };

  //SUBMIT FUNCTIONS
  const handleNoteSubmit = (note: {
    Name: string;
    Title: string;
    Description: string;
    Attachment: string | null;
    DocumentExtention: string | null;
  }) => {
    setActivityNotes((prevNotes) => {
      const updatedNotes = [...prevNotes, note];
      setValue("ActivityNotes", updatedNotes);
      return updatedNotes;
    });
  };

  const handleProductSubmit = (product: {
    productId: string;
    product: string;
    quantity: number;
    costPerQuantity: number;
  }) => {
    const newProduct = {
      ProductId: product.productId,
      Product: product.product,
      Quantity: product.quantity.toString(),
      CostPerQuantity: product.costPerQuantity.toString(),
    };
  
    setActivityProducts((prevProducts) => {
      const updatedProducts = [...prevProducts, newProduct];
      setValue("ProductDetail", updatedProducts);
      return updatedProducts;
    });
  };

  const calculateTotalCost = (
    productDetails: FormData["ProductDetail"],
    contractWorkCost: number,
    dynamicFields: any[],
    watch: (name: string) => any,
    mappedfields: any[]
  ) => {
    let quantity = 0;
    let costPerQuantity = 0;
    let isHectareQuantityType = false;
  
    dynamicFields.forEach((field) => {
      if (field.id === "quantity") {
        quantity = parseFloat(watch(field.id)) || 0;
      } else if (field.id === "cost") {
        costPerQuantity = parseFloat(watch(field.id)) || 0;
      } else if (field.id === "quantityType") {
        const quantityType = watch(field.id);
        isHectareQuantityType = ["hectare_seeds", "hectare_g", "hectare_kg", "hectare_tonne"].includes(quantityType);
      }
    });
  
    let dynamicCost = quantity * costPerQuantity;
  
    if (isHectareQuantityType) {
      const selectedFields = watch("fields") || [];
      const totalHectarage = selectedFields.reduce((total, fieldId) => {
        const field = mappedfields.find((f) => f.fieldId === fieldId);
        return total + (field?.hectarage || 0);
      }, 0);
  
      dynamicCost *= totalHectarage;
    }
  
    const productCost = productDetails.reduce((total, product) => {
      const productQuantity = parseFloat(product.Quantity) || 0;
      const productCostPerQuantity = parseFloat(product.CostPerQuantity) || 0;
      return total + productQuantity * productCostPerQuantity;
    }, 0);
  
    const totalCost = dynamicCost + productCost + contractWorkCost;
  
    return parseFloat(totalCost.toFixed(2));
  };
  
  useEffect(() => {
    if (isOpen) {
      const contractWorkCost = parseFloat(watch("contractWorkCost") || "0");
      const productDetails = watch("ProductDetail") || [];
      const fieldsData = mappedfields || []; 
  
      const total = calculateTotalCost(productDetails, contractWorkCost, dynamicFields, watch, fieldsData);
  
      setTotalCost(total);
    }
  }, [
    watch("ProductDetail"),
    watch("contractWorkCost"),
    ...dynamicFields.map((field) => watch(field.id)),
    watch("fields"),
    isOpen,
  ]);
  
  
  const handleFormSubmit = (data: FormData) => {
    setIsLoading(true);
    const properties: { [key: string]: any } = {};

    dynamicFields.forEach((field) => {
      const value = data[field.id];
      if (value !== undefined && value !== null) {
        properties[field.id] = value;
      }
    });

    subFields.forEach((field) => {
      const value = data[field.id];
      if (value !== undefined && value !== null) {
        properties[field.id] = value;
      }
    });

    subSubFields.forEach((field) => {
      const value = data[field.id];
      if (value !== undefined && value !== null) {
        properties[field.id] = value;
      }
    });

    if (data.ProductDetail && data.ProductDetail.length > 0) {
      properties["ProductDetail"] = data.ProductDetail;
    }
  
    console.log(data);

    const filteredActivityNotes = activityNotes.filter((note) => !note.NoteId);
  
    if (watch('applicationType') != 'applicationType_chemical') {
      setActivityProducts([]);
    }
  
    const finalData: FormData = {
      activityId: data.activityId,
      name: data.name,
      description: data.description,
      startDate: data.startDate,
      endDate: data.endDate,
      fields: data.fields,
      cost: totalCost.toString(),
      contractWorkCost: data.contractWorkCost,
      Properties: JSON.stringify(properties),
      activityCategoryId: data.activityCategoryId,
      seasonStageId: data.seasonStageId,
      partyId: data.partyId,
      activityStatusId: data.activityStatusId,
      property: undefined,
      ActivityNotes: filteredActivityNotes,
      notes: undefined,
      ProductDetail: data.ProductDetail,
    };
  
    console.log('Final Data to Submit:', finalData);
    onSubmit(finalData);
    setIsLoading(false);
  };

  const formContent = (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <DialogContent dividers sx={{ pt: 1, pb: 1, maxHeight: '70vh', overflowY: 'auto' }}>
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid item xs={6}>
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextBox
                  label="Activity Name"
                  value={value}
                  onChange={onChange}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
  
          <Grid item xs={6}>
            <Controller
              name="activityCategoryId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextBox
                  select
                  label="Activity Category"
                  value={value}
                  onChange={onChange}
                  error={!!errors.activityCategoryId}
                  helperText={errors.activityCategoryId?.message}
                  fullWidth
                  disabled={!!formData}
                >
                  {activityCategories?.map((type) => (
                    <MenuItem key={type.activityCategoryId} value={type.activityCategoryId}>
                      {type.name}
                    </MenuItem>
                  ))}
                </TextBox>
              )}
            />
          </Grid>
          
          <Grid item xs={6}>
            <Controller
              name="startDate"
              control={control}
              render={({ field: { onChange, value, onBlur } }) => (
                <DateSelector
                  label="Start Date"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.startDate}
                  helperText={errors.startDate?.message}
                />
              )}
            />
          </Grid>
  
          <Grid item xs={6}>
            <Controller
              name="endDate"
              control={control}
              render={({ field: { onChange, value, onBlur } }) => (
                <DateSelector
                  label="End Date"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!errors.endDate}
                  helperText={errors.endDate?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="fields"
              control={control}
              render={({ field: { onChange, value } }) => (
                <MultiSelect
                  label="Field(s)"
                  value={value || []}
                  onChange={onChange}
                  options={mappedfields?.map((field) => ({
                    value: field.fieldId,
                    label: field.name,
                    id: field.fieldId,
                  }))}
                  error={!!errors.fields}
                  helperText={errors.fields?.message}
                  fieldId="fields"
                  sx={{ mb: 1 }}
                />
              )}
            />
          </Grid>
  
          <Grid item xs={6}>
            <Controller
              name="activityStatusId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextBox
                  select
                  label="Activity Status"
                  value={value}
                  onChange={onChange}
                  error={!!errors.activityStatusId}
                  helperText={errors.activityStatusId?.message}
                  fullWidth
                >
                  {activityStatuses?.map((type) => (
                    <MenuItem key={type.key} value={type.key}>
                      {type.value}
                    </MenuItem>
                  ))}
                </TextBox>
              )}
            />
          </Grid>
  
          <Grid item xs={6}>
              <Controller
                name="seasonStageId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextBox
                    select
                    label="Season Stages"
                    value={value}
                    onChange={onChange}
                    error={!!errors.seasonStageId}
                    helperText={errors.seasonStageId?.message}
                    fullWidth
                    disabled={isSeasonStageDisabled}
                  >
                    {seasonStages?.map((type) => (
                      <MenuItem key={type.key} value={type.key}>
                        {type.value}
                      </MenuItem>
                    ))}
                  </TextBox>
                )}
              />
            </Grid>

            <DynamicFieldRenderer
              fields={dynamicFields}
              control={control}
              errors={errors}
              handleFieldChange={handleFieldChange}
              handleOpenDialog={handleOpenDialog}
              watch={watch}
              setValue={setValue}
            />

            <DynamicFieldRenderer
              fields={subFields}
              control={control}
              errors={errors}
              handleFieldChange={handleFieldChange}
              handleOpenDialog={handleOpenDialog}
              watch={watch}
              setValue={setValue}
            />

            <DynamicFieldRenderer
              fields={subSubFields}
              control={control}
              errors={errors}
              handleFieldChange={handleFieldChange}
              handleOpenDialog={handleOpenDialog}
              watch={watch}
              setValue={setValue}
            />

          {activityProducts.length > 0 && watch('applicationType') === "applicationType_chemical" && (
            <Grid item xs={12} sx={{ mt: 2 }}>
              {activityProducts.map((product, index) => (
                <Chip
                  key={index}
                  label={`${product.Product} (${product.Quantity})`}
                  onDelete={() => {
                    handleRequestDeleteProduct(index);
                  }}
                  deleteIcon={<CancelIcon />}
                  sx={{
                    mr: 1,
                    mb: 1,
                    backgroundColor: theme.palette.secondary.main,
                  }}
                />
              ))}
            </Grid>
          )}
  
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextBox
                  label="Description"
                  value={value}
                  onChange={onChange}
                  multiline
                  rows={4}
                  error={!!errors.description}
                  helperText={errors.description?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
  
          <Grid item xs={6}>
            <Controller
              name="contractWorkCost"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextBox
                  label="Contract Work Cost"
                  value={value}
                  onChange={onChange}
                  type="currency"
                  error={!!errors.contractWorkCost}
                  helperText={errors.contractWorkCost?.message}
                  fullWidth
                />
              )}
            />
          </Grid>
  
          <Grid item xs={6}>
            <Controller
              name="cost"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextBox
                  label="Total Cost"
                  value={totalCost}
                  onChange={onChange}
                  type="currency"
                  error={!!errors.cost}
                  helperText={errors.cost?.message}
                  fullWidth
                  disabled
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<NoteAddIcon />}
              onClick={() => handleOpenDialog("ActivityNotesDialog")}
              fullWidth
              sx={{ mt: 2 }}
            >
              Add Note
            </Button>
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            {activityNotes.map((note, index) => (
              <Chip
                key={index}
                label={
                  !note.NoteId ? note.Title : (
                    note.Attachment ? (
                      <a
                        href={note.Attachment}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        {note.Title}
                      </a>
                    ) : (
                      note.Title
                    )
                  )
                }
                onDelete={
                  !note.NoteId ? () => handleRequestDeleteNote(index) : undefined
                }
                deleteIcon={<CancelIcon />}
                sx={{
                  mr: 1,
                  mb: 1,
                  backgroundColor: !note.NoteId ? theme.palette.secondary.main : 'default',
                }}
              />
            ))}
          </Grid>
  
        </Grid>
      </DialogContent>
  
      <DialogActions>
        {formData && (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<Delete />}
            onClick={handleDelete}
          >
            Delete
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          type="submit"
          startIcon={<SaveIcon />}
          disabled={isLoading}
        >
          {formData ? "Update" : "Save"}
          {isLoading && (
            <CircularProgress color="inherit" size={"15px"} sx={{ ml: 2 }} />
          )}
        </Button>
      </DialogActions>
    </form>
  );
  
  return (
<>
  <DynamicFormDialog
    isOpen={isOpen}
    onClose={handleCloseMainDialog}
    onSubmit={handleSubmit(handleFormSubmit)}
    title={formData ? "Update Activity" : "Add Activity"}
    formContent={formContent}
  />

  <ActivityNotesDialog
    isOpen={openDialogId === "ActivityNotesDialog"}
    onClose={handleCloseDialog}
    onSubmit={handleNoteSubmit}
  />

  <ActivityProductsDialog
    isOpen={openDialogId === "ActivityProductsDialog"}
    onClose={handleCloseDialog}
    products={products}
    onSubmit={handleProductSubmit}
  />
</>
  );
};

export default ActivityDialog;