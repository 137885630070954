import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";

import { getForecastWeather } from "../../services/api-ws-service";
import WindIcon from "../../assets/icons/weather/svg/wind_icon.svg"; 
import RainfallIcon from "../../assets/icons/weather/svg/rainfall.svg"; 

const importIcon = (symbolCode: string) => {
  try {
    return require(`../../assets/icons/weather/svg/${symbolCode}.svg`);
  } catch (error) {
    console.error(`Icon for symbol code ${symbolCode} not found.`);
    return null;
  }
};

interface WeatherForecastDialogProps {
  lat: any;
  long: any;
}

const WeatherForecastDialog: React.FC<WeatherForecastDialogProps> = ({ 
  lat, 
  long 
}) => {
  const [weatherData, setWeatherData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [currentWeather, setCurrentWeather] = useState<string>("Loading...");

  const [weatherIcon, setWeatherIcon] = useState<string>("");


  useEffect(() => {
    if (lat !== null && long !== null) {
      fetchWeatherData();
    }
  }, [lat, long]);


  const fetchWeatherData = async () => {
    try {
      setIsLoading(true);
      const data = await getForecastWeather(lat, long);
      const timeseries = data?.properties?.timeseries || [];
      const now = new Date();
      const next24HoursData = timeseries.filter((item: any) => {
        const itemDate = new Date(item.time);
        return itemDate > now && itemDate <= new Date(now.getTime() + 24 * 60 * 60 * 1000);
      });

      setWeatherData(next24HoursData);

      if (next24HoursData.length > 0) {
        const latestWeather = next24HoursData[0].data.instant.details.air_temperature;
        const latestPrecipitation = next24HoursData[0].data.next_1_hours.details.precipitation_amount;
        const symbolCode = next24HoursData[0].data.next_1_hours.summary.symbol_code;
        const currentIcon = importIcon(symbolCode);
        setCurrentWeather(`${latestWeather}\n${latestPrecipitation} mm`);
        setWeatherIcon(currentIcon); 
      } else {
        setCurrentWeather("No data");
        setWeatherIcon("");
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch weather data:", error);
      setCurrentWeather("Error");
      setWeatherIcon("");
      setIsLoading(false);
    }
  };

  const handleButtonClick = () => {
    if (!isLoading && weatherData.length > 0) {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  
  const degreesToCompass = (degrees: number): string => {
    const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
    return directions[Math.round(degrees / 45) % 8];
  };

  const degreesToRotation = (degrees: number): string => {
    const normalizedDegrees = (degrees + 360) % 360;
    return `rotate(${normalizedDegrees}deg)`;
  };
  

  const times = weatherData.map((item: any) => item.time.split("T")[1].split(":00")[0] + ":00");

  return (
    <>
      <Button 
        onClick={handleButtonClick} 
        disabled={isLoading}
        sx={{
          backgroundColor: "rgba(25, 118, 210, 0.7)",
          color: "#fff",
          padding: "12px 16px",
          fontSize: "16px",
          borderRadius: "8px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          backdropFilter: "blur(10px)",
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          textTransform: "none",
          "&:hover": {
            backgroundColor: "rgba(21, 101, 192, 0.8)",
          },
        }}
        >
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
              {weatherIcon && (
                <img src={weatherIcon} alt="Weather icon" style={{ width: '30px', height: '30px' }} />
              )}
              <span>
                {Math.round(parseFloat(currentWeather.split('\n')[0]))} °C
              </span>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
              {RainfallIcon && (
                  <img 
                  src={RainfallIcon} 
                  alt="Umbrella icon" 
                  style={{ width: '26px', height: '30px' }} 
                />
              )}
              <span>
                {currentWeather.split('\n')[1]}
              </span>
            </Box>
          </>
        )}
        </Button>

      <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>Weather Forecast</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Table sx={{ textAlign: "center" }}>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {times.map((time, index) => (
                    <TableCell key={index}>{time}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
              <TableRow>
                  <TableCell>Temperature (°C)</TableCell>
                  {weatherData.map((hourData: any, index: number) => (
                    <TableCell key={index} sx={{ minWidth: 100 }}>{Math.round(parseFloat(hourData.data.instant.details.air_temperature))}</TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  {weatherData.map((hourData: any, index: number) => {
                    const symbolCode = hourData.data.next_1_hours.summary.symbol_code;
                    const Icon = importIcon(symbolCode);
                    return (
                      <TableCell key={index} sx={{ minWidth: 100 }}>
                        {Icon ? <img src={Icon} alt={symbolCode} style={{ width: '25px', height: '25px' }} /> : "No icon"}
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell>Rainfall (mm)</TableCell>
                  {weatherData.map((hourData: any, index: number) => (
                    <TableCell key={index}>{hourData.data.next_1_hours.details.precipitation_amount}</TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell>Wind (m/s)</TableCell>
                  {weatherData.map((hourData: any, index: number) => {
                    const windSpeed = hourData.data.instant.details.wind_speed;
                    const windDirection = degreesToCompass(hourData.data.instant.details.wind_from_direction);
                    return (
                      <TableCell key={index}>
                        {`${windSpeed}`} {windDirection}
                      </TableCell>
                    );
                  })}
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  {weatherData.map((hourData: any, index: number) => {
                    const windDirection = hourData.data.instant.details.wind_from_direction;
                    const rotation = degreesToRotation(windDirection);
                    return (
                      <TableCell key={index}>
                        <img 
                          src={WindIcon} 
                          alt="Wind direction" 
                          style={{ width: '30px', height: '30px', transform: rotation }} 
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableBody>
            </Table>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WeatherForecastDialog;