export const addNoneOption = <T extends Record<string, any>>(
  options: T[],
  noneLabel: string = 'None Selected',
  noneIdField: keyof T = 'id',
  defaultValues: Partial<T> = {}
): T[] => {
  const noneOption = {
    [noneIdField]: -1,
    ...defaultValues,
    name: noneLabel,
  } as unknown as T;

  return [noneOption, ...options];
};

export const removeNoneOption = <T extends Record<string, any>>(
  options: T[],
  noneIdField: keyof T = 'id'
): T[] => {
  return options.filter(option => option[noneIdField] !== -1);
};
