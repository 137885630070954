import React from "react";
import ActivitySummary from "../organisms/activitySummary";
import { Breadcrumb } from "../atom/breadcrumbs";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, SelectChangeEvent} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Dropdown from "../atom/dropdown";

const ReportPage = () => {
  const navigate = useNavigate();

  const {
    control,
    setValue,
  } = useForm({});


  let ReportTypes = [{ "id": 1, "reportType": "Activity Summary Report" }];
  
  setValue("reportTypeId", ReportTypes[0]?.id || "");

  const handlerReportTypeChange = (event: SelectChangeEvent<string | number>) => {
    setValue("reportTypeId", event.target.value);
  };

  function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();
    navigate(-1);
  }

  return (
    <>
    <Grid container>
      <Grid item xs={12}>
        <Breadcrumb
          crumbs={[
            { text: "<< Back", onClick: handleClick, underline: "hover" },
            { text: "Administration", onClick: undefined, underline: "none" },
          ]}
          currentCrumb={"Reports"}
        ></Breadcrumb>
      </Grid>
      <Grid container>
        <Grid item xs={2.5} sx={{ p: 2, pb: 0 }}>
          <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
            Reports
          </Typography>
        </Grid>        
        <Grid item xs={9.5} sx={{ pr: 1 }}>
          <Controller
            name="reportTypeId"
            control={control}
            render={({ field }) => (
              <Dropdown
                label={""}
                name="reportTypeId"
                value={field.value || ""}
                items={ReportTypes?.map((rt) => ({
                  value: rt.id,
                  label: rt.reportType
                }))}
                disabled={true}
                    onChange={(event) => {
                      handlerReportTypeChange(event);
                      field.onChange(event);
                    }}
                    sx={{ mt: 2, width: 280 }}
                  />
                )}
              />
            </Grid>
      </Grid>
      <Grid item xs={12} margin={2}>
        <ActivitySummary></ActivitySummary>
      </Grid>
    </Grid>
    </>      
  );
};

export default ReportPage;
