import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Button,
  Paper,
  Typography,
  Chip,
  AlertColor,
} from "@mui/material";
import ActionButtons from "../molecules/actionButtons";
import {
  addDocuments,
  updatedDocuments,
  deleteDocument,
  getDocumentTypes,
  getDocuments,
} from "../../services/api-ffm-service";
import GenericConfirmDialog from "../organisms/genericConfirmDialog";
import Loading from "./loading";
import { fetchData, useFetchData } from "../../hooks/useFetchData";
import { Breadcrumb } from "../atom/breadcrumbs";
import { useNavigate } from "react-router-dom";
import DocumentDialog from "../organisms/DocumentDialog";
import {
  handleApiResponse,
  setChipColors,
} from "../../utils/Utilities";
import CustomSnackbar from "../atom/CustomSnackbar";
import moment from "moment";
import CardList from "../organisms/cardTiles";
import { useConfig } from "../../context/ConfigContextTest";

interface DataItem {
  id: string;
  [key: string]: any;
}

interface ColumnConfig {
  label: string;
  dataKey: keyof DataItem;
  renderCell: (item: DataItem) => React.ReactNode;
}

interface DocumentFormData {
  documents: Array<{
    fileName: string;
    docType: string | number;
    file: File | null;
  }>;
}

const Documentation: React.FC = () => {
  const [documents, setDocuments] = useState<any[]>([]);
  const [documentTypes, setDocumentTypes] = useState<any[]>([]);
  const { selectedOrganization } = useConfig();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState<any | null>(null);
  const snackbarRef = useRef<{
    handleOpen: (title: string, msg: string | null, sev: AlertColor) => void;
  }>(null);

  useFetchData(getDocuments, setDocuments, setIsLoading, [
    selectedOrganization?.organizationId,
  ]);
  useFetchData(getDocumentTypes, setDocumentTypes, setIsLoading);

  useEffect(() => {
    if (documents.length > 0) {
      const coloredDocuments = setChipColors(documents, "documentTypeId");
      setDocuments((prevDocuments) => {
        if (
          JSON.stringify(prevDocuments) !== JSON.stringify(coloredDocuments)
        ) {
          return coloredDocuments;
        }
        return prevDocuments;
      });
    }
  }, [documents]);

  const handleOpenForm = () => {
    setSelectedDoc(null);
    setIsEditing(false);
    setFormOpen(true);
  };

  const handleCloseForm = () => {
    setSelectedDoc(null);
    setFormOpen(false);
    setIsLoading(false);
    setConfirmOpen(false);
  };

  const handleEdit = (doc) => {
    const editFormData = {
      documents: [
        {
          fileName: doc.documentName,
          docType: doc.documentTypeId,
          file: null,
          documentId: doc.documentId,
          extension: doc.extention,
        },
      ],
    };
    setSelectedDoc(editFormData);
    setIsEditing(true);
    setFormOpen(true);
  };

  const handleDelete = (doc) => {
    setSelectedDoc(doc);
    setConfirmOpen(true);
  };

  const showSnackbar = (title, message, severity) => {
    console.log(snackbarRef.current);
    if (snackbarRef.current) {
      snackbarRef.current.handleOpen(title, message, severity);
    }
  };

  const handleSubmit = async (formData: DocumentFormData) => {
    setIsLoading(true);
    let response, title;

    const convertFileToBase64 = (file: File): Promise<string> =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>
          resolve(reader.result?.toString().split(",")[1] || "");
        reader.onerror = (error) => reject(error);
      });

    try {
      const documentsPayload = await Promise.all(
        formData.documents.map(async (doc) => {
          let base64File = "";
          let extension = "";
          if (doc.file) {
            base64File = await convertFileToBase64(doc.file);
            extension = doc.file.name.split(".").pop() || "";
          }

          return {
            Name: doc.fileName,
            DocumentTypeId: parseInt(doc.docType.toString(), 10),
            DocumentId: selectedDoc
              ? selectedDoc.documents[0].documentId
              : undefined,
            Attachment: base64File,
            DocumentExtention: extension,
          };
        })
      );

      const payload = {
        PartyId: selectedOrganization?.partyId ?? 0,
        AzureUserId: selectedOrganization?.azureUserId  ?? "",
        Documents: documentsPayload,
      };

      if (selectedDoc) {
        response = await updatedDocuments(payload);
        title = "Update successful";
      } else {
        response = await addDocuments(payload);
        title = "Upload successful";
      }

      fetchData(getDocuments, setDocuments, setIsLoading, [
        selectedOrganization?.organizationId,
      ]);
    } catch (error) {
      console.error("Error submitting documents:", error);
    }
    handleCloseForm();
    handleApiResponse(response, showSnackbar, { title: title });
  };

  const handleConfirm = async () => {
    let response;

    if (selectedDoc) {
      setIsLoading(true);
      try {
        response = await deleteDocument(selectedDoc.documentId);
        fetchData(getDocuments, setDocuments, setIsLoading, [
          selectedOrganization?.organizationId,
        ]);
      } catch (error) {
        console.error("Failed to delete document:", error);
      }
    }
    handleCloseForm();
    handleApiResponse(response, showSnackbar, {
      title: "Document successfully deleted",
    });
  };

  const getDocumentTypeName = (docTypeId) => {
    const docType = documentTypes.find(
      (type) => type.documentTypeId === docTypeId
    );
    return docType ? docType.documentType : "";
  };

  const myColumns: ColumnConfig[] = [
    {
      label: "Organisation Name",
      dataKey: "organisationName",
      renderCell: (doc) => <>{doc.organisationName}</>,
    },
    {
      label: "Document Type",
      dataKey: "documentType",
      renderCell: (doc) => <>{getDocumentTypeName(doc.documentTypeId)}</>,
    },
    {
      label: "Document",
      dataKey: "document",
      renderCell: (doc) => (
        <Chip
          label={`${doc.documentName}.${doc.extention}`}
          onClick={() => window.open(doc.documentLink, "_blank")}
          clickable
          sx={{ backgroundColor: doc.chipColor, color: "#fff" }}
        />
      ),
    },
    {
      label: "Uploaded By",
      dataKey: "fullNames",
      renderCell: (doc) => <>{doc.fullNames}</>,
    },
    {
      label: "Created At",
      dataKey: "createdDate",
      renderCell: (doc) => (
        <>{moment(doc.createdDate).format("DD MMMM YYYY")}</>
      ),
    },
    {
      label: "Action Buttons",
      dataKey: "action",
      renderCell: (doc) => (
        <ActionButtons
          onEdit={() => handleEdit(doc)}
          onDelete={() => handleDelete(doc)}
        ></ActionButtons>
      ),
    },
  ];

  const navigate = useNavigate();
  function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();
    navigate(-1);
  }

  return (
    <>
      <CustomSnackbar ref={snackbarRef} />
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <Grid item xs={12}>
            <Breadcrumb
              crumbs={[
                { text: "<< Back", onClick: handleClick, underline: "hover" },
                {
                  text: "Administration",
                  onClick: undefined,
                  underline: "none",
                },
              ]}
              currentCrumb={"Documentation"}
            ></Breadcrumb>
          </Grid>
          <Grid container spacing={2} padding={"10px"}>
            <Grid item xs={12}>
              {documents.length === 0 && (
                <Paper
                  sx={{
                    padding: "20px",
                    margin: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ m: 2 }}>
                    You do not have any documents. Please click the button below
                    to add a document.
                  </Typography>
                  <Button
                    sx={{ m: 2 }}
                    variant="contained"
                    onClick={handleOpenForm}
                    color="primary"
                  >
                    Add Documents
                  </Button>
                </Paper>
              )}
            </Grid>
            {documents.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Button
                    sx={{ ml: 2 }}
                    variant="contained"
                    onClick={handleOpenForm}
                    color="primary"
                  >
                    Add Documents
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <CardList
                    data={documents}
                    columns={myColumns}
                    rowsPerPage={8}
                    notes={false}
                  />
                  <GenericConfirmDialog
                    open={confirmOpen}
                    onCancel={() => setConfirmOpen(false)}
                    onConfirm={handleConfirm}
                    title="Confirm Deletion"
                    content="Are you sure you want to delete this document?"
                  />
                </Grid>
              </>
            )}
          </Grid>
          <DocumentDialog
            isOpen={formOpen}
            onClose={handleCloseForm}
            onSubmit={handleSubmit}
            docTypes={documentTypes.map((type) => ({
              label: type.documentType,
              value: type.documentTypeId,
            }))}
            formData={selectedDoc}
            isEditing={isEditing}
          />
        </>
      )}
    </>
  );
};

export default Documentation;
