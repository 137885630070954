import axios from "axios";
import { LegalEntity } from "../models/legalEntity.interface";
import { ResponseApi } from "../models/ResponseApi.interface";
import { UserProfile } from "../models/userProfile.interface";
import { Activity } from "../models/activity.interface";
import { Farm } from "../models/farm.interface";
import { Note } from "../models/note.interface";
import { Organization } from "../models/organization.interface";
import { UploadDocument } from "../models/document.interface";
import { ActivitySummaryReport } from "../models/activitySummaryReport.interface";
import {
  parseActivityCategoryProperties,
  parseNoteTypeProperties,
} from "../utils/dataParser";
import { NoteType } from "../interfaces/noteType.interface";
import { FieldDetail } from "../models/fieldDetail.interface";

const api = axios.create({
  baseURL: process.env.REACT_APP_FFM_BASE_URL + "/api/",
  headers: {
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_FFM_API_KEY,
  },
});
const apiSignUp = axios.create({
  baseURL: process.env.REACT_APP_FFP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_FFM_API_KEY,
  },
});

let azureUserId = "";

export const setAzureUserId = (userId) => {
  api.interceptors.request.use(
    (config) => {
      config.headers["X-AzureUserId"] = userId;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  azureUserId = userId;
};

//User Profile CRUD APIs
export const getUserProfile = async (): Promise<UserProfile> => {
  try {
    const response = await api.get<ResponseApi<UserProfile>>("UserDetails");

    if (
      response.data.statusCode !== 200 ||
      response.data.message !== "SUCCESS"
    ) {
      throw new Error(`API call unsuccessful: ${response.data.message}`);
    }
    return response.data.details;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to retrieve user details: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      throw new Error("Something went wrong while retrieving user details");
    }
  }
};

export const updateUserProfile = async (
  userProfile: Partial<UserProfile>
): Promise<ResponseApi<string>> => {
  try {
    userProfile.azureUserId = azureUserId ? azureUserId : "";
    const response = await api.put<ResponseApi<string>>(
      "/UpdateUserProfile",
      userProfile
    );

    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(`Failed to update user details: ${error.response.data}`);
    } else {
      throw new Error("Something went wrong while updating the user details");
    }
  }
};

//Organisation CRUD APIs
export const createOrganization = async (organization: any): Promise<any> => {
  try {
    organization.azureUserId = azureUserId ? azureUserId : "";
    return await api.post("/CreateOrganization", organization);
  } catch (error: any) {
    throw new Error(`Error during the updating process: ${error.message}`);
  }
};

export const getOrganizations = async (): Promise<Organization[]> => {
  try {
    const response = await api.get<ResponseApi<Organization[]>>(
      "Organizations"
    );
    if (
      response.data.statusCode !== 200 ||
      response.data.message !== "SUCCESS"
    ) {
      throw new Error(`API call unsuccessful: ${response.data.message}`);
    }

    return response.data.details;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to retrieve organizations: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      throw new Error("Something went wrong while retrieving organizations");
    }
  }
};

export const getFieldDetails = async (
  partyIdentifier: string
): Promise<FieldDetail[]> => {
  try {
    const config = {
      headers: {
        "x-PartyIdentifier": partyIdentifier,
      },
    };

    const response = await api.get<ResponseApi<FieldDetail[]>>(
      "FieldDetails",
      config
    );
    if (
      response.data.statusCode !== 200 ||
      response.data.message !== "SUCCESS"
    ) {
      throw new Error(`API call unsuccessful: ${response.data.message}`);
    }

    return response.data.details;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to retrieve organizations: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      throw new Error("Something went wrong while retrieving organizations");
    }
  }
};

export const updateOrganization = async (organization: any): Promise<any> => {
  try {
    organization.azureUserId = azureUserId ? azureUserId : "";
    return await api.put("/UpdateOrganization", organization);
  } catch (error: any) {
    throw new Error(`Error during the updating process: ${error.message}`);
  }
};

export const deleteOrganization = async (partyId: number): Promise<any> => {
  try {
    return await api.delete(`RemoveOrganization`, {
      data: {
        PartyId: partyId,
        AzureUserId: azureUserId,
      },
    });
  } catch (error: any) {
    throw new Error(`Error during the updating process: ${error.message}`);
  }
};

export const getOrganizationById = async (
  OrganizationId: number
): Promise<Organization[]> => {
  try {
    const config = {
      headers: {
        "x-OrganizationId": OrganizationId,
      },
    };
    console.log(OrganizationId);
    const response = await api.get<ResponseApi<Organization[]>>(
      "OrganizationById",
      config
    );
    console.log(response);

    if (
      response.data.statusCode !== 200 ||
      response.data.message !== "SUCCESS"
    ) {
      throw new Error(`API call unsuccessful: ${response.data.message}`);
    }

    return response.data.details;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to retrieve organization: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      throw new Error("Something went wrong while retrieving organization");
    }
  }
};

//Farm CRUD APIs
export const createFarm = async (farm: Partial<Farm>): Promise<any> => {
  try {
    farm.azureUserId = azureUserId;
    return await api.post("/CreateFarm", farm);
  } catch (error: any) {
    throw new Error(`Error during the updating process: ${error.message}`);
  }
};

export const getOrganizationFarms = async (
  organizationId: number
): Promise<Farm[]> => {
  try {
    const config = {
      headers: {
        "x-OrganizationId": organizationId,
      },
    };

    const response = await api.get<ResponseApi<Farm[]>>(
      "OrganizationFarms",
      config
    );
    return response.data.details;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(`Failed to retrieve farm: ${error.response.data}`);
    } else {
      throw new Error("Something went wrong while retrieving the farm");
    }
  }
};

export const updateFarm = async (farm: Partial<Farm>): Promise<any> => {
  try {
    farm.azureUserId = azureUserId;
    return await api.put("/UpdateFarm", farm);
  } catch (error: any) {
    throw new Error(`Error during the updating process: ${error.message}`);
  }
};

export const deleteFarm = async (farmId: number): Promise<any> => {
  try {
    return await api.delete(`RemoveFarm`, {
      data: {
        FarmId: farmId,
        AzureUserId: azureUserId,
      },
    });
  } catch (error: any) {
    throw new Error(`Error during the updating process: ${error.message}`);
  }
};

export const getOrganizationFarmById = async (
  farmId: number
): Promise<Farm> => {
  try {
    const config = {
      headers: {
        "x-FarmId": farmId,
      },
    };

    const response = await api.get<ResponseApi<Farm>>(
      "OrganizationFarmById",
      config
    );
    if (
      response.data.statusCode !== 200 ||
      response.data.message !== "SUCCESS"
    ) {
      throw new Error(`API call unsuccessful: ${response.data.message}`);
    }
    return response.data.details;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to retrieve organiszation farm details: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      throw new Error(
        "Something went wrong while retrieving organiszation farm details"
      );
    }
  }
};

//Note CRUD APIs
export const createNote = async (note: Partial<any>): Promise<any> => {
  try {
    note.azureUserId = azureUserId;
    return await api.post("/AddNote", note);
  } catch (error: any) {
    throw new Error(`Error during the creating process: ${error.message}`);
  }
};

export const getNotes = async (organizationId: number): Promise<Note[]> => {
  try {
    const config = {
      headers: {
        "x-OrganizationId": organizationId,
      },
    };

    const response = await api.get<ResponseApi<Note[]>>("Notes", config);
    if (
      response.data.statusCode === 200 &&
      response.data.message === "SUCCESS"
    ) {
      return response.data.details;
    } else {
      throw new Error(`Failed to fetch notes: ${response.data.message}`);
    }
  } catch (error: any) {
    if (error.response) {
      throw new Error(
        `API Error: ${error.response.status} ${error.response.data}`
      );
    } else if (error.request) {
      throw new Error("No response received");
    } else {
      throw new Error("Error setting up request");
    }
  }
};

export const getNoteById = async (noteId: number): Promise<Note> => {
  try {
    const config = {
      headers: {
        "x-NoteId": noteId,
      },
    };

    const response = await api.get<ResponseApi<Note>>("NoteById", config);
    if (
      response.data.statusCode === 200 &&
      response.data.message === "SUCCESS"
    ) {
      return response.data.details;
    } else {
      throw new Error(`Failed to fetch notes: ${response.data.message}`);
    }
  } catch (error: any) {
    if (error.response) {
      throw new Error(
        `API Error: ${error.response.status} ${error.response.data}`
      );
    } else if (error.request) {
      throw new Error("No response received");
    } else {
      throw new Error("Error setting up request");
    }
  }
};

export const updateNote = async (note: Partial<any>): Promise<any> => {
  try {
    note.azureUserId = azureUserId;
    return await api.put("/UpdateNote", note);
  } catch (error: any) {
    throw new Error(`Error during the updating process: ${error.message}`);
  }
};

export const deleteNote = async (noteId: number): Promise<any> => {
  try {
    return await api.delete(`RemoveNote`, {
      data: {
        NoteId: noteId,
        AzureUserId: azureUserId,
      },
    });
  } catch (error: any) {
    throw new Error(`Error during the deletion process: ${error.message}`);
  }
};

//NoteType CRUD APIs
export const getNoteTypes = async (): Promise<NoteType[]> => {
  try {
    const response = await api.get<ResponseApi<NoteType[]>>("NoteTypes");
    if (
      response.data.statusCode !== 200 ||
      response.data.message !== "SUCCESS"
    ) {
      throw new Error(`API call unsuccessful: ${response.data.message}`);
    }
    const parsedNoteTypes = parseNoteTypeProperties(response.data.details);
    return parsedNoteTypes;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to fetch note types: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      throw new Error("Something went wrong while fetching note types");
    }
  }
};

export const getNoteTypeById = async (
  noteTypeId: number
): Promise<NoteType> => {
  try {
    const config = {
      headers: {
        "x-NoteTypeId": noteTypeId,
      },
    };

    const response = await api.get<ResponseApi<NoteType>>(
      "NoteTypeById",
      config
    );
    if (
      response.data.statusCode !== 200 ||
      response.data.message !== "SUCCESS"
    ) {
      throw new Error(`API call unsuccessful: ${response.data.message}`);
    }

    return response.data.details;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to fetch note type: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      throw new Error("Something went wrong while fetching note type");
    }
  }
};

//LegalEntities CRUD APIs
export const getLegalEntities = async (): Promise<LegalEntity[]> => {
  try {
    const response = await api.get<ResponseApi<LegalEntity[]>>("LegalEntities");

    if (
      response.data.statusCode !== 200 ||
      response.data.message !== "SUCCESS"
    ) {
      throw new Error(`API call unsuccessful: ${response.data.message}`);
    }

    return response.data.details || [];
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to fetch legal entity types: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      console.error(
        "Something went wrong while fetching legal entity types",
        error
      );
      return [];
    }
  }
};

//Activity CRUD APIs
export const getActivityCategories = async (): Promise<any[]> => {
  try {
    const response = await api.get<ResponseApi<any[]>>("ActivityCategories");

    if (
      response.data.statusCode !== 200 ||
      response.data.message !== "SUCCESS"
    ) {
      throw new Error(`API call unsuccessful: ${response.data.message}`);
    }

    const parsedActivityCategory = parseActivityCategoryProperties(
      response.data.details
    );
    return parsedActivityCategory;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to fetch legal entity types: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      console.error(
        "Something went wrong while fetching legal entity types",
        error
      );
      return [];
    }
  }
};

export const getSeasonStages = async (): Promise<any[]> => {
  try {
    const response = await api.get<ResponseApi<any[]>>("SeasonStages");

    if (
      response.data.statusCode !== 200 ||
      response.data.message !== "SUCCESS"
    ) {
      throw new Error(`API call unsuccessful: ${response.data.message}`);
    }

    return response.data.details || [];
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to fetch legal entity types: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      console.error(
        "Something went wrong while fetching legal entity types",
        error
      );
      return [];
    }
  }
};

export const getActivityStatuses = async (): Promise<any[]> => {
  try {
    const response = await api.get<ResponseApi<any[]>>("ActivityStatuses");

    if (
      response.data.statusCode !== 200 ||
      response.data.message !== "SUCCESS"
    ) {
      throw new Error(`API call unsuccessful: ${response.data.message}`);
    }

    return response.data.details || [];
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to fetch legal entity types: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      console.error(
        "Something went wrong while fetching legal entity types",
        error
      );
      return [];
    }
  }
};

export const getActivityById = async (): Promise<any[]> => {
  try {
    const response = await api.get<ResponseApi<any[]>>("ActivityById");

    if (
      response.data.statusCode !== 200 ||
      response.data.message !== "SUCCESS"
    ) {
      throw new Error(`API call unsuccessful: ${response.data.message}`);
    }

    return response.data.details || [];
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to fetch legal entity types: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      console.error(
        "Something went wrong while fetching legal entity types",
        error
      );
      return [];
    }
  }
};

export const getActivities = async (
  organizationId: number
): Promise<Activity[]> => {
  try {
    const config = {
      headers: {
        "x-OrganizationId": organizationId,
      },
    };

    const response = await api.get<ResponseApi<Activity[]>>(
      "Activities",
      config
    );

    if (
      response.data.statusCode !== 200 ||
      response.data.message !== "SUCCESS"
    ) {
      throw new Error(`API call unsuccessful: ${response.data.message}`);
    }

    return response.data.details || [];
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to fetch activities: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      console.error("Something went wrong while fetching activities", error);
      return [];
    }
  }
};

export const createActivity = async (
  activity: Partial<Activity>
): Promise<any> => {
  try {
    activity.azureUserId = azureUserId;
    return await api.post("CreateActivity", activity);
  } catch (error: any) {
    throw new Error(`Error during the creating process: ${error.message}`);
  }
};

export const updateActivity = async (
  activity: Partial<Activity>
): Promise<any> => {
  try {
    activity.azureUserId = azureUserId;
    activity.statusId = activity.activityStatusId;

    return await api.put("UpdateActivity", activity);
  } catch (error: any) {
    throw new Error(`Error during the updating process: ${error.message}`);
  }
};

export const deleteActivity = async (activityId: number): Promise<any> => {
  try {
    return await api.delete(`RemoveActivity`, {
      data: {
        ActivityId: activityId,
        AzureUserId: azureUserId,
      },
    });
  } catch (error: any) {
    throw new Error(`Error during the deleting process: ${error.message}`);
  }
};

export const updateActivityStatus = async (
  activityStatus: any
): Promise<any> => {
  try {
    activityStatus.azureUserId = azureUserId;
    return await api.post("CreateActivityStatus", activityStatus);
  } catch (error: any) {
    throw new Error(`Error during the deleting process: ${error.message}`);
  }
};

export const getActivitiesSummary = async (
  summaryParameters: any
): Promise<any> => {
  try {
    const response = await api.post<ResponseApi<any>>(
      "ActivitySummary",
      summaryParameters
    );

    if (
      response.data.statusCode !== 200 ||
      response.data.message !== "SUCCESS"
    ) {
      throw new Error(`API call unsuccessful: ${response.data.message}`);
    }

    return response.data.details || {};
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to fetch Activity Summary: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      console.error(
        "Something went wrong while fetching Activity Summary",
        error
      );
      return [];
    }
  }
};

//Document CRUD APIs
export const getDocumentById = async (): Promise<any[]> => {
  try {
    const response = await api.get<ResponseApi<any[]>>("ActivityById");

    if (
      response.data.statusCode !== 200 ||
      response.data.message !== "SUCCESS"
    ) {
      throw new Error(`API call unsuccessful: ${response.data.message}`);
    }

    return response.data.details || [];
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to fetch legal entity types: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      console.error(
        "Something went wrong while fetching legal entity types",
        error
      );
      return [];
    }
  }
};

export const getDocuments = async (
  organizationId: number
): Promise<UploadDocument[]> => {
  try {
    const config = {
      headers: {
        "x-OrganizationId": organizationId,
        "x-AzureUserId": azureUserId,
      },
    };

    console.log(config);
    const response = await api.get<ResponseApi<UploadDocument[]>>(
      "Documents",
      config
    );
    console.log(response);

    if (
      response.data.statusCode !== 200 ||
      response.data.message !== "SUCCESS"
    ) {
      throw new Error(`API call unsuccessful: ${response.data.message}`);
    }

    return response.data.details || [];
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to fetch documents: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      console.error("Something went wrong while fetching documents", error);
      return [];
    }
  }
};

export const getDocumentTypes = async (): Promise<any[]> => {
  try {
    const response = await api.get<ResponseApi<any[]>>("DocumentTypes");

    if (
      response.data.statusCode !== 200 ||
      response.data.message !== "SUCCESS"
    ) {
      throw new Error(`API call unsuccessful: ${response.data.message}`);
    }

    return response.data.details || [];
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to fetch documents: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      console.error("Something went wrong while fetching documents", error);
      return [];
    }
  }
};

export const addDocuments = async (documents: any): Promise<any> => {
  try {
    return await api.post("UploadDocuments", documents);
  } catch (error: any) {
    throw new Error(`Error during the creating process: ${error.message}`);
  }
};

export const updatedDocuments = async (documents: any): Promise<any> => {
  try {
    return await api.put("UpdateDocument", documents);
  } catch (error: any) {
    throw new Error(`Error during the updating process: ${error.message}`);
  }
};

export const deleteDocument = async (documentId: number): Promise<any> => {
  try {
    return await api.delete(`RemoveDocument`, {
      data: {
        DocumentId: documentId,
        AzureUserId: azureUserId,
      },
    });
  } catch (error: any) {
    throw new Error(`Error during the deleting process: ${error.message}`);
  }
};

export const generateMfaCode = async (email: string): Promise<any> => {
  try {
    const response = await apiSignUp.post<ResponseApi<any>>("GenerateMfaCode", {
      EmailAddress: email,
    });

    if (
      response.data.statusCode !== 200 ||
      response.data.message !== "SUCCESS"
    ) {
      throw new Error(`API call unsuccessful: ${response.data.message}`);
    }
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to generate MFA code: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      throw new Error("Something went wrong while generating MFA code");
    }
  }
};

export const verifyMfaCode = async (
  code: number,
  email: string
): Promise<ResponseApi<void>> => {
  try {
    const response = await apiSignUp.post<ResponseApi<void>>("VerifyMfaCode", {
      Code: code,
      EmailAddress: email,
    });

    if (
      response.data.statusCode !== 200 ||
      response.data.message !== "SUCCESS"
    ) {
      throw new Error(`API call unsuccessful: ${response.data.message}`);
    }
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        `Failed to verify MFA code: ${
          error.response.data.message || error.message
        }`
      );
    } else {
      throw new Error("Something went wrong while verifying MFA code");
    }
  }
};

export const addUser = async (
  email: string,
  name: string,
  surname: string,
  password: string
): Promise<ResponseApi<any>> => {
  try {
    const response = await apiSignUp.post<ResponseApi<any>>("AddUser", {
      EmailAddress: email,
      Name: name,
      Surname: surname,
      Password: password,
    });

    if (
      response.data.statusCode !== 200 ||
      response.data.message !== "SUCCESS"
    ) {
      throw new Error(`${response.data.details.Error}`);
    }
    return response.data;
  } catch (error: any) {
    if (error.message) {
      throw new Error(` ${error}`);
    } else {
      throw new Error("Something went wrong while adding the user");
    }
  }
};

export const getActivitySummaryReport = async (
  organizationId: string,
  startDate: string,
  endDate: string,
  sortColumn: string,
  sortColumnDirection: string
): Promise<ActivitySummaryReport[]> => {
  try {
    const data = {
      AzureUserId: azureUserId,
      OrganizationId: organizationId,
      Categories: [0],
      StartDate: startDate,
      EndDate: endDate,
      Fields: [0],
      SortColumn: sortColumn,
      SortColumnDirection: sortColumnDirection,
    };

    const response = await api.post<ResponseApi<ActivitySummaryReport[] | any>>(
      "ActivitySummaryReport",
      data
    );

    // Check if the response status and message indicate success
    if (
      response.data.statusCode !== 200 ||
      response.data.message !== "SUCCESS"
    ) {
      throw new Error(
        `API Error: ${response.data.details.Error || "Unknown error"}`
      );
    }

    // Return the report details
    return response.data.details;
  } catch (error: any) {
    // Enhanced error handling with message logging
    console.error("Error fetching activity summary report:", error);

    // Re-throw the error with a more meaningful message
    throw new Error(
      error.response?.data?.message ||
        "An error occurred while fetching the activity summary report."
    );
  }
};
export const getFieldIcon = async (
  farmFieldLinkingIdentifier: string
): Promise<Blob> => {
  try {
    const response = await api.get(
      `fieldicon?farmFieldLinkingIdentifier=${farmFieldLinkingIdentifier}`,
      {
        responseType: 'blob', // Specify that we're expecting a Blob response
      }
    );

    // Return the Blob directly if the response is successful
    return response.data;
  } catch (error: any) {
    console.error("Error fetching field icon", error);

    const fallbackSvg = "../../assets/icons/fieldIcon.svg"
    return new Blob([fallbackSvg], { type: "image/svg+xml" });
  }
};


