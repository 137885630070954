import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import { getOrganizations,setAzureUserId } from "../../services/api-ffm-service";
import { useLocation } from "react-router-dom";
import { fetchData } from "../../hooks/useFetchData";
import { useConfig } from "../../context/ConfigContextTest";

import { Box } from "@mui/material";
import WeatherForecastDialog from "../organisms/weatherForecastDialog";
import { useMsal } from "@azure/msal-react";

interface FieldMapProps {
  height?: string;
  fieldData?: any;
  onLoad: () => void;
  isFarmOverviewPage?: boolean;
}

const FieldMapComponent: React.FC<FieldMapProps> = ({
  height = "650px",
  fieldData,
  onLoad,
  isFarmOverviewPage,
}) => {
  const { selectedOrganization } = useConfig();
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [mapUrl, setMapUrl] = useState<string>("");
  const { instance } = useMsal();
  const [coords, setCoords] = useState({ long: null, lat: null });

  const fieldId = fieldData?.fieldId;


  useEffect(() => {
    fetchData(getOrganizations, setOrganizations);
  }, []);  

  // NB CHECK BEFORE FIELD PAGE IS COMPLETED
  // useEffect(() => {
  //  if (fieldData) {
  //   const coordinates = fieldData?.Coords; 
  //   const [long, lat] = coordinates[0][0];
  //   setCoords({ long, lat });
  // }, []);

  const location = useLocation();

  useEffect(() => {
    const pathnames = location.pathname.split("/").filter((x) => x);
    const lastSegment = pathnames[pathnames.length - 1];

    let azureUserId = instance.getActiveAccount()?.localAccountId;

    if (azureUserId) {
      setAzureUserId(azureUserId);
    }

    let newMapUrl = "";
    let lastEdittedUrl = ""

    if (lastSegment === "fields" && fieldId) {
      newMapUrl = `${process.env.REACT_APP_MAPPING_TOOL}/field/${selectedOrganization?.partyIdentifier}/${fieldId}`;
    } else if (!lastSegment && selectedOrganization) {
      console.log("selectedOrganization", selectedOrganization);
      console.log("azureUserId", azureUserId);

      newMapUrl = `${process.env.REACT_APP_MAPPING_TOOL}/field/${selectedOrganization.partyIdentifier}/view/all?azureUserId=${azureUserId}`;
      lastEdittedUrl = `${process.env.REACT_APP_MAPPING_TOOL}/field/${selectedOrganization.partyIdentifier}/lastEditted`;
 
      fetch(lastEdittedUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        const coordinates = JSON.parse(data.Coords);
        const [long, lat] = coordinates[0][0];
        setCoords({ lat, long });
      })
      .catch(error => {
        console.error('There was an error with the fetch operation:', error);
      });

    } else if (selectedOrganization) {
      newMapUrl = `${process.env.REACT_APP_MAPPING_TOOL}/field/${selectedOrganization.partyIdentifier}?azureUserId=${azureUserId}`;
    } else if (organizations.length > 0) {
      newMapUrl = `${process.env.REACT_APP_MAPPING_TOOL}/field/${organizations[0].partyIdentifier}?azureUserId=${azureUserId}`;
    }

    setMapUrl(newMapUrl);

  }, [location, fieldId, selectedOrganization, organizations]);

  return (
    <div style={{ position: "relative" }}>
      <Iframe
        url={mapUrl}
        width="100%"
        height={height}
        display="initial"
        position="relative"
        frameBorder={0}
        onLoad={onLoad}
      />
      {isFarmOverviewPage && coords.lat && coords.long && (
        <Box
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 10,
          }}
        >
          <WeatherForecastDialog 
            lat={coords.lat} 
            long={coords.long} 
          />
        </Box>
      )}
    </div>
  );
};

export default FieldMapComponent;
