import React from "react";
import { Autocomplete, TextField, IconButton, Box } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import LocationOnIcon from '@mui/icons-material/LocationOn';

interface SearchBarProps {
  handleInputChange: (event: React.ChangeEvent<{}>, value: string, reason: any) => void;
  handleSuggestionSelected: (event: React.ChangeEvent<{}>, value: any) => void;
  suggestions: any[];
  selectedSuggestion: any;
  inputValue: string;
  label: string;
}

const GoogleMapsSearchBar: React.FC<SearchBarProps> = ({
  handleInputChange,
  handleSuggestionSelected,
  suggestions,
  selectedSuggestion,
  inputValue,
  label,
}) => {
  const handlePinClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const locationString = `${latitude.toFixed(5)}, ${longitude.toFixed(5)}`;

          const syntheticEvent = {
            target: { value: locationString }
          } as React.ChangeEvent<HTMLInputElement>;

          handleInputChange(syntheticEvent, locationString, 'input');
        },
        (error) => {
          console.error("Error fetching location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <Autocomplete
      fullWidth
      value={selectedSuggestion}
      onChange={handleSuggestionSelected}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      options={suggestions}
      getOptionLabel={(option) => option.description}
      freeSolo
      disablePortal
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          size="small"
          margin="dense"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {params.InputProps.endAdornment}
                <Box ml={1} />
                <IconButton onClick={handlePinClick} edge="end" style={{ color: 'darkgreen' }}>
                  <LocationOnIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          {option.description}
        </li>
      )}
    />
  );
};

export default GoogleMapsSearchBar;
