import React from "react";
import { Routes, Route } from "react-router-dom";
import OrganizationSettings from "../components/pages/OrganisationSettings";
import Notes from "../components/pages/notesPage";
import Activities from "../components/pages/ActivityPage";
import FieldManagement from "../components/pages/FieldsPage";
import FarmManagement from "../components/pages/FarmPage";
import ReportPage from "../components/pages/ReportPage";
import Documentation from "../components/pages/Documentation";
import CropClimatePage from "../components/pages/CropClimatePage";
import CropDataPage from "../components/pages/CropDataPage";
import TutorialPage from "../components/pages/TutorialPage"

const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<FarmManagement />} />
      <Route path="/organisation" element={<OrganizationSettings />} />
      <Route path="/notes" element={<Notes />} />
      <Route path="/fields" element={<FieldManagement />} />
      <Route path="/activity" element={<Activities />} />
      <Route path="/reports" element={<ReportPage />} />
      <Route path="/documentation" element={<Documentation />} />
      <Route path="/cropClimate" element={<CropClimatePage />} />
      <Route path="/cropData" element={<CropDataPage />} />
      <Route path="/tutorials" element={<TutorialPage />} />
    </Routes>
  );
};

export default Router;
