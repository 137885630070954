import {
  FormControlLabel,
  Checkbox,
  Paper,
  Typography,
  Box,
  Chip,
  AlertColor,
  Grid,
} from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import {
  getActivities,
  updateActivity,
  getNotes,
  updateNote,
  getOrganizationFarms,
} from "../../services/api-ffm-service";
import { fetchData, useFetchData } from "../../hooks/useFetchData";
import { Farm } from "../../models/farm.interface";
import { Controller } from "react-hook-form";
import FormSection from "../molecules/DynamicFormSection";
import { FieldFormProps } from "../../models/fieldFormProps.interface";
import { handleApiResponse } from "../../utils/Utilities";
import NotesDialog from "../organisms/notesDialog";
import ActivityDialog from "../organisms/activityDialog";
import { useConfig } from "../../context/ConfigContextTest";
import { calculateArea } from "../../utils/geometryUtils";

import { useTheme } from "@mui/material/styles";

const FieldForm: React.FC<FieldFormProps> = ({
  initialFieldData,
  polygonData,
  control,
  errors,
  setValue,
  onCanSaveChange,
  onSizeChange,
  canSave,
  isEditing,
}) => {
  const { selectedOrganization } = useConfig();
  const [farms, setFarms] = useState<Farm[]>([]);
  const [fieldData, setFieldData] = useState(initialFieldData || {});

  const [activities, setActivities] = useState<any[]>([]);
  const [activitiesField, setActivitiesField] = useState<any[]>([]);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [isActivityDialogOpen, setIsActivityDialogOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [notes, setNotes] = useState<any[]>([]);
  const [notesField, setNotesField] = useState<any[]>([]);
  const [selectedNote, setSelectedNote] = useState<any | null>(null);
  const [isNotesDialogOpen, setIsNotesDialogOpen] = useState(false);

  const theme = useTheme();


  const snackbarRef = useRef<{
    handleOpen: (title: string, msg: string | null, sev: AlertColor) => void;
  }>(null);

  const [isLoading, setIsLoading] = useState(false);

  useFetchData(getOrganizationFarms, setFarms, undefined, [
    selectedOrganization?.organizationId ?? 0,
  ]);
  useFetchData(getActivities, setActivities, setIsLoading, [
    selectedOrganization?.organizationId ?? 0,
  ]);

  useFetchData(getNotes, setNotes, setIsLoading, [
    selectedOrganization?.organizationId ?? 0,
  ]);

  useEffect(() => {
    setFieldData(initialFieldData || {});
  }, [initialFieldData]);

  useEffect(() => {
    if (initialFieldData?.coords) {
  
      const coords = JSON.parse(initialFieldData.coords);
      //console.log('initialFieldData.coords');
      //console.log(initialFieldData.coords)
      //console.log('Array.isArray(coords): '+Array.isArray(coords));
      //console.log('coords.every(coord => Array.isArray(coord) && Array.isArray(coord[0])): '+coords.every(coord => Array.isArray(coord) && Array.isArray(coord[0])));


      if (Array.isArray(coords) && coords.every(coord => Array.isArray(coord) && Array.isArray(coord[0]))) {
        const size = calculateArea(coords).toString();
        setValue("size", `${size} ha`);
        onSizeChange(size);
      } else {
        console.error('coords is not in the expected format of [number, number][][]');
      }
    }
  }, [initialFieldData, setValue, onSizeChange]);

  useEffect(() => {
    const filterActivitiesByField = activities.filter((activity) =>
      activity?.fields?.includes(fieldData?.fieldId)
    );
    const filterNotesByField = notes.filter(
      (note) => note.fieldId === fieldData?.fieldId
    );

    setActivitiesField(filterActivitiesByField);
    setNotesField(filterNotesByField);
  }, [fieldData, setValue, activitiesField, notesField]);

  useEffect(() => {
    if (polygonData?.area) {
      const size = polygonData.area;
      setValue("size", size);
      onSizeChange(size);
    }
    if (polygonData?.canSave !== undefined) {
      canSave = polygonData.canSave;
      setValue("canSave", canSave);
      onCanSaveChange(canSave);
    }
  }, [polygonData, setValue, onCanSaveChange, onSizeChange, canSave]);

  const handleActivityClick = (activity) => {
    setSelectedActivity(activity);
    setIsActivityDialogOpen(true);
  };

  const handleCloseActivity = () => {
    setSelectedActivity(null);
    setIsActivityDialogOpen(false);
    setConfirmOpen(false);
    setIsLoading(false);
  };

  const handleNoteClick = (noteId) => {
    const note = notesField.find((note) => note.noteId === noteId);
    setSelectedNote(note);
    setIsNotesDialogOpen(true);
  };

  const handleCloseNote = () => {
    setSelectedNote(null);
    setIsNotesDialogOpen(false);
    setIsLoading(false);
    setConfirmOpen(false);
  };

  const handleDelete = () => {
    setSelectedActivity(selectedActivity);
    setConfirmOpen(true);
  };

  const showSnackbar = (title, message, severity) => {
    if (snackbarRef.current) {
      snackbarRef.current.handleOpen(title, message, severity);
    }
  };

  const handleSubmitActivity = async (formData: any) => {
    setIsLoading(true);
    formData.partyId = selectedOrganization?.partyId;
    formData.statusId = formData.activityStatusId;
    let response;
    if (selectedActivity) {
      try {
        response = await updateActivity(formData);
      } catch (error) {
        console.error("Error updating activity:", error);
      }
    }

    fetchData(getActivities, setActivities, setIsLoading, [
      selectedOrganization?.organizationId ?? 0,
    ]);

    handleCloseActivity();

    handleApiResponse(response, showSnackbar);
  };

  const handleSubmitNote = async (formData: any) => {
    setIsLoading(true);

    const newFormData: any = {
      azureUserId: selectedOrganization?.azureUserId,
      noteId: formData.noteId,
      partyId: selectedOrganization?.partyId,
      Properties: formData.noteProperty,
      noteDocuments: [],
    };

    const convertFileToBase64 = (file: File): Promise<string> =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>
          resolve(reader.result?.toString().split(",")[1] || "");
        reader.onerror = (error) => reject(error);
      });

    // Process noteDocuments array
    if (formData.noteDocuments) {
      for (const doc of formData.noteDocuments) {
        const newDoc: any = {
          name: doc.name,
          noteDocumentId: doc.noteDocumentId,
          documentExtention: doc.documentExtention,
        };

        if (doc.attachment && doc.noteDocumentId === 0) {
          newDoc.attachment = await convertFileToBase64(doc.attachment);
        }

        newFormData.noteDocuments.push(newDoc);
      }
    }

    newFormData.title = formData.title;
    newFormData.description = formData.description;
    newFormData.noteTypeId = formData.noteTypeId;
    newFormData.fieldId = formData.fieldId;

    let response, title;

    if (selectedNote) {
      try {
        response = await updateNote(newFormData);
        title = "Note successfully updated";
      } catch (error) {
        console.error("Error updating note:", error);
      }
    }

    fetchData(getNotes, setNotes, setIsLoading, [
      selectedOrganization?.organizationId ?? 0,
    ]);

    handleApiResponse(response, showSnackbar, { title: title });
    handleCloseNote();
  };

  const fieldDefinitions = {
    fieldDetails: [
      {
        id: "fieldName",
        label: "Field Name",
        type: "text",
        disabled: !isEditing,
      },
      { id: "size", label: "Size", type: "text", disabled: !isEditing },
    ],
    irrDry: [
      {
        id: "irrDry",
        label: "Irrigated",
        type: "checkbox",
        disabled: !isEditing,
      },
    ],
    seasonalField: [
      {
        id: "seasonalField",
        label: "Seasonal",
        type: "checkbox",
        disabled: !isEditing,
      },
    ],
    farm: [
      {
        id: "farmIdentifier",
        label: "Farm",
        type: "select",
        options: farms.map((farm) => ({
          value: farm.farmIdentifier,
          label: farm.farm,
          id: farm.farmIdentifier,
        })),
        disabled: !isEditing,
      },
    ],
    fieldHistory: [
      {
        id: "cropHistory",
        label: "Crop History",
        type: "ChipList",
        disabled: !isEditing,
      },
    ],
  };

  return (
    <Paper elevation={0} sx={{ mt: 0, padding: "8px 16px 16px 16px", height: "calc(100vh - 196px)" }}>
      <Grid container>
        <Grid item xs={2}>
          {fieldData.fieldIconUrl ? (
            <img 
              src={fieldData.fieldIconUrl} 
              style={{ width: 40, height: 40, borderRadius: 4 }} 
              alt="Field icon"
            />
          ) : (
            <Box 
              sx={{ 
                width: 40, 
                height: 40, 
                borderRadius: 1, 
                backgroundColor: theme.palette.primary.main,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
            </Box>
          )}
        </Grid>
        <Grid item xs={10}>  
          <Typography variant="h6" style={{ marginTop: 4 }}>Field Details</Typography>
        </Grid>
      </Grid>


      <Box sx={{ overflow: "visible" }}>
        <FormSection
          fields={fieldDefinitions.fieldDetails}
          control={control}
          errors={errors}
          columns={1}
          title=""
        />
        <Controller
          name="irrDry"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  checked={field.value === "Irrigated"}
                  onChange={(e) =>
                    field.onChange(e.target.checked ? "Irrigated" : "Dry")
                  }
                  color="primary"
                  disabled={!isEditing}
                />
              }
              label="Irrigated"
            />
          )}
        />
        <Controller
          name="seasonalField"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  color="primary"
                  disabled={!isEditing}
                />
              }
              label="Seasonal"
            />
          )}
        />
        <FormSection
          title=""
          fields={fieldDefinitions.farm}
          control={control}
          errors={errors}
          columns={1}
          defaultValue={fieldData.farmIdentifier}
        />
        <Typography variant="h6" sx={{ mt: 1 }}>
          Field History
        </Typography>
        <Box sx={{ mt: 0.5, mb: 0.5 }}>
          <Typography>Activities</Typography>
          {activitiesField.length === 0 ? (
            <Typography variant="caption">No Options Available</Typography>
          ) : (
            activitiesField?.map((activity) => (
              <Chip
                key={activity.activityId}
                label={activity.name}
                onClick={() => handleActivityClick(activity)}
                style={{ margin: 2, cursor: "pointer" }}
              />
            ))
          )}
        </Box>
        <FormSection
          fields={fieldDefinitions.fieldHistory}
          control={control}
          errors={errors}
          columns={1}
          title=""
        />
        <Box sx={{ mt: 0.5 }}>
          <Typography>Notes</Typography>
          {notesField.length === 0 ? (
            <Typography variant="caption">No Options Available</Typography>
          ) : (
            notesField?.map((note) => (
              <Chip
                key={note.noteId}
                label={note.title}
                onClick={() => handleNoteClick(note.noteId)}
                style={{ margin: 2, cursor: "pointer" }}
              />
            ))
          )}
        </Box>
      </Box>

      <ActivityDialog
        isOpen={isActivityDialogOpen}
        onClose={handleCloseActivity}
        onSubmit={handleSubmitActivity}
        formData={selectedActivity}
        handleDelete={handleDelete}
      />
      <NotesDialog
        isOpen={isNotesDialogOpen}
        onClose={handleCloseNote}
        onSubmit={handleSubmitNote}
        formData={selectedNote}
      />
    </Paper>
  );
};

export default FieldForm;
