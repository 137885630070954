import React from 'react';
import { Tooltip } from '@mui/material';
import PictureAsPdfSharpIcon from '@mui/icons-material/PictureAsPdfSharp';
import InsertDriveFileSharpIcon from '@mui/icons-material/InsertDriveFileSharp';
import ImageSharpIcon from '@mui/icons-material/ImageSharp';
import TextFieldsSharpIcon from '@mui/icons-material/TextFieldsSharp';
import DescriptionSharpIcon from '@mui/icons-material/DescriptionSharp';
import SlideshowSharpIcon from '@mui/icons-material/SlideshowSharp';
import TableChartSharpIcon from '@mui/icons-material/TableChartSharp';

interface FileDisplayProps {
    fileName?: string; // Made optional
    fileType: string;
    fileUrl: string;
    showTooltip?: boolean; // New boolean prop to control tooltip display
}

const FileDisplay: React.FC<FileDisplayProps> = ({ fileName, fileType, fileUrl, showTooltip = false }) => {
    const getFileIcon = (type: string) => {
        switch (type) {
            case 'pdf':
                return <PictureAsPdfSharpIcon style={{ color: '#D32F2F' }} />; // Red for PDF files
            case 'gif':
            case 'png':
            case 'jpeg':
            case 'jpeg2000':
            case 'jpg':
                return <ImageSharpIcon style={{ color: '#FF9800' }} />; // Orange for image files
            case 'tif':
            case 'tiff':
                return <ImageSharpIcon style={{ color: '#9C27B0' }} />; // Purple for TIFF files
            case 'txt':
                return <TextFieldsSharpIcon style={{ color: '#3F51B5' }} />; // Indigo for TXT files
            case 'doc':
            case 'docx':
                return <DescriptionSharpIcon style={{ color: '#2196F3' }} />; // Blue for DOC files
            case 'ppt':
            case 'pptx':
                return <SlideshowSharpIcon style={{ color: '#FF5722' }} />; // Deep Orange for PPT files
            case 'xls':
            case 'xlsx':
                return <TableChartSharpIcon style={{ color: '#4CAF50' }} />; // Green for XLS files
            default:
                return <InsertDriveFileSharpIcon style={{ color: '#607D8B' }} />; // Blue Grey for other files
        }
    };

    const iconElement = (
        <a href={fileUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
            {getFileIcon(fileType)}
            {!showTooltip && fileName && <span style={{ marginLeft: '8px', color: '#000000' }}>{fileName}</span>}
        </a>
    );

    return (
        <>
            {showTooltip ? (
                <Tooltip title={fileName || ''}>
                    {iconElement}
                </Tooltip>
            ) : (
                iconElement
            )}
        </>
    );
};

export default FileDisplay;
