import { Button, Grid, MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";
import TextBox from "../atom/textBox";
import MultiSelect from "../molecules/MultiSelect";

const DynamicFieldRenderer = ({
  fields,
  control,
  errors,
  handleFieldChange,
  handleOpenDialog,
  watch,
  setValue,
}) => {
  return (
    <>
      {fields.map((field) => (
        <Grid item xs={6} key={field.id}>
          <Controller
            name={field.id}
            control={control}
            render={({ field: { onChange, value, onBlur, ref } }) => {
              if (field.type === "multiSelect") {
                return (
                  <MultiSelect
                    label={field.label}
                    value={value || []}
                    onChange={onChange}
                    options={field.options}
                    error={!!errors[field.id]}
                    helperText={errors[field.id]?.message}
                    fieldId={field.id}
                    sx={{ mb: 1 }}
                  />
                );
              } else if (field.type === "select") {
                return (
                  <TextBox
                    select
                    id={field.id}
                    label={field.label}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      handleFieldChange(field.id, e.target.value);
                    }}
                    onBlur={onBlur}
                    error={!!errors[field.id]}
                    helperText={errors[field.id]?.message}
                    inputRef={ref}
                    fullWidth
                    sx={{ mb: 1 }}
                    disabled={field.disabled}
                  >
                    {Array.isArray(field.options) ? (
                      field.options.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            {option.label}
                          </div>
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No Options Available</MenuItem>
                    )}
                  </TextBox>
                );
              } else if (field.type === "button") {
                return (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => handleOpenDialog(field.dialogId)}
                    sx={{ mt: 2 }}
                  >
                    {field.label}
                  </Button>
                );
              } else if (field.type === "decimal" || field.type === "currency" || field.type === "wholeNumber") {
                return (
                  <TextBox
                    label={field.label}
                    value={value}
                    onChange={onChange}
                    type={field.type}
                    error={!!errors[field.id]}
                    helperText={errors[field.id]?.message}
                    fullWidth
                  />
                );
              } else {
                return (
                  <TextBox
                    id={field.id}
                    label={field.label}
                    placeholder={field.placeholder}
                    type={field.type}
                    value={value}
                    min={field.min}
                    max={field.max}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors[field.id]}
                    disabled={field.disabled}
                    helperText={errors[field.id]?.message}
                    readOnly={field.readOnly}
                    sx={{ mb: 0 }}
                    inputRef={ref}
                  />
                );
              }
            }}
          />
        </Grid>
      ))}
    </>
  );
};

export default DynamicFieldRenderer;
