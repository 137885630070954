import React, { useState, useRef } from "react";
import { Grid, Button, Paper, Typography, AlertColor } from "@mui/material";
import ActionButtons from "../molecules/actionButtons";
import OrganizationDialog from "../organisms/organisationDialog";
import NotificationDialog from "../organisms/notificationDialog";
import DynamicTable from "../organisms/table";
import {
  getOrganizations,
  deleteOrganization,
  createOrganization,
  updateOrganization,
  getLegalEntities,
} from "../../services/api-ffm-service";
import { LegalEntity } from "../../models/legalEntity.interface";
import GenericConfirmDialog from "../organisms/genericConfirmDialog";
import { Contacts } from "../../models/contacts.interface";
import Loading from "./loading";
import { fetchData, useFetchData } from "../../hooks/useFetchData";
import { Breadcrumb } from "../atom/breadcrumbs";
import { useNavigate } from "react-router-dom";
import { handleApiResponse } from "../../utils/Utilities";
import CustomSnackbar from "../atom/CustomSnackbar";
import { useConfig } from "../../context/ConfigContextTest";
import { GoogleLocation } from "../../interfaces/googleLocation.interface";

interface DataItem {
  id: string;
  [key: string]: any;
}

interface ColumnConfig {
  label: string;
  dataKey: keyof DataItem;
  renderCell: (item: DataItem) => React.ReactNode;
}

const OrganizationSettings: React.FC = () => {
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationTitle, setNotificationTitle] = useState("");

  const [legalEntities, setLegalEntities] = useState<LegalEntity[]>([]);
  const { setSelectedOrganization, refreshOrganizations, selectedOrganization } = useConfig();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState<any | null>(null);
  const snackbarRef = useRef<{
    handleOpen: (title: string, msg: string | null, sev: AlertColor) => void;
  }>(null);
  useFetchData(getOrganizations, setOrganizations, setIsLoading);
  useFetchData(getLegalEntities, setLegalEntities, setIsLoading);

  const handleOpenForm = () => {
    setSelectedOrg(null);
    setFormOpen(true);
  };

  const handleCloseForm = () => {
    setSelectedOrg(null);
    setFormOpen(false);
    setIsLoading(false);
    setConfirmOpen(false);
  };

  const handleEdit = (org) => {
    setSelectedOrg(org);
    setFormOpen(true);
  };

  const handleDelete = (org) => {
    setSelectedOrg(org);
    setConfirmOpen(true);
  };

  const showSnackbar = (title, message, severity) => {
    if (snackbarRef.current) {
      snackbarRef.current.handleOpen(title, message, severity);
    }
  };

  const handleSubmit = async (formData: any) => {
    setFormOpen(false);
    setIsLoading(true);
    formData.legalEntityTypeId = legalEntities.find(
      (nt) => nt.name === formData.legalEntityTypeName
    )?.legalEntityTypeId;
  
    const location = formData.selectedLocation as GoogleLocation;
  
    const addressLine1 = location ? location.address : "";
    const addressLine2 = location ? `${location.coordinates.lat}, ${location.coordinates.lng}` : formData.physicalAddressLine2 || "";
    const city = location ? location.components.city : formData.physicalCity || "";
    const postalCode = location ? location.components.postalCode : formData.physicalCode || "";
  
    let response, title;
    let transformedData;
  
    try {
      if (selectedOrg) {
        const contactIdEmail = selectedOrg.contactPerson[0]?.contacts.find(contact => contact.type === "Email")?.contactId || null;
        const contactIdMobile = selectedOrg.contactPerson[0]?.contacts.find(contact => contact.type === "Mobile")?.contactId || null;
        const addressIdPhysical = selectedOrg.physicalAddress[0]?.addressId || 0;
  
        transformedData = {
          PartyId: selectedOrg.partyId,
          OrganizationId: selectedOrg.organizationId,
          Name: formData.orgName || "",
          VATNumber: formData.vatNumber || "",
          LegalEntityTypeId: formData.legalEntityTypeId || "",
          RegistrationNumber: formData.registrationNumber || "",
          ContactDetail: [
            {
              Name: formData.contactName || "",
              Surname: formData.contactSurname || "",
              Contacts: [
                { Type: "Email", Details: formData.contactEmail || "", ContactId: contactIdEmail },
                { Type: "Mobile", Details: formData.contactNumber || "", ContactId: contactIdMobile },
              ],
            },
          ],
        };
  
        if (addressLine1) {
          transformedData.PhysicalAddress = [
            {
              AddressId: addressIdPhysical,
              AddressLine1: addressLine1,
              AddressLine2: addressLine2,
              City: city,
              Code: postalCode,
            },
          ];
        }
  
        response = await updateOrganization(transformedData);
        title = "Organisation successfully updated";
        fetchData(getOrganizations, setOrganizations, setIsLoading);
        await refreshOrganizations();
      } else {
        transformedData = {
          Name: formData.orgName || "",
          VATNumber: formData.vatNumber || "",
          LegalEntityTypeId: formData.legalEntityTypeId || "",
          RegistrationNumber: formData.registrationNumber || "",
          ContactDetail: [
            {
              Name: formData.contactName || "",
              Surname: formData.contactSurname || "",
              Contacts: [
                { Type: "Email", Details: formData.contactEmail || "" },
                { Type: "Mobile", Details: formData.contactNumber || "" },
              ],
            },
          ],
        };
  
        if (addressLine1) {
          transformedData.PhysicalAddress = {
            AddressLine1: addressLine1,
            AddressLine2: addressLine2,
            City: city,
            Code: postalCode,
          };
        }
  
        response = await createOrganization(transformedData);
        title = "Organisation successfully created";
        fetchData(getOrganizations, setOrganizations, setIsLoading);
        await refreshOrganizations();
        setSelectedOrganization(organizations[organizations.length-1]);
      }
  
      handleCloseForm();
    } catch (error) {
      console.error("Error submitting organisation:", error);
    } finally {
      setIsLoading(false);
    }
  
    handleApiResponse(response, showSnackbar, { title: title });
  };  
  
  const handleConfirm = async () => {
    let response;

    if (selectedOrg) {
      setIsLoading(true);
      setConfirmOpen(false);
      try {
        response = await deleteOrganization(selectedOrg.partyId);

        if (response.status === 200) {
          fetchData(getOrganizations, setOrganizations, setIsLoading);
          await refreshOrganizations();

          if(organizations.length === 0){
            window.location.reload();
          }
          else if(selectedOrg.partyId === selectedOrganization?.partyId){
            setSelectedOrganization(organizations[0]);
          }
        }
      } catch (error) {
        console.error("Failed to delete organisation:", error);
      }
    }
    handleCloseForm();
    handleApiResponse(response, showSnackbar, {
      title: "Organisation successfully deleted",
    });
  };

  const myColumns: ColumnConfig[] = [
    {
      label: "Company name",
      dataKey: "name",
      renderCell: (item) => <>{item.name}</>,
    },
    {
      label: "Registration number",
      dataKey: "registrationNumber",
      renderCell: (item) => <>{item.registrationNumber}</>,
    },
    {
      label: "VAT number",
      dataKey: "VAT",
      renderCell: (item) => <>{item.vatNumber}</>,
    },
    {
      label: "Contact Information",
      dataKey: "contactPerson",
      renderCell: (item) => (
        <>
          {item.contactPerson[0].name + " " + item.contactPerson[0].surname}
          {item.contactPerson[0].contacts.map((contact: Contacts) => (
            <p key={contact.contactId}>
              <p>
                {contact.type}: {contact.details}
              </p>
            </p>
          ))}
        </>
      ),
    },
    {
      label: "Action Buttons",
      dataKey: "action",
      renderCell: (item) => (
        <ActionButtons
          onEdit={() => handleEdit(item)}
          onDelete={() => handleDelete(item)}
        ></ActionButtons>
      ),
    },
  ];

  const navigate = useNavigate();
  function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();
    navigate(-1);
  }

  return (
    <>
      <CustomSnackbar ref={snackbarRef} />
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <Grid item xs={12}>
            <Breadcrumb
              crumbs={[
                { text: "<< Back", onClick: handleClick, underline: "hover" },
                {
                  text: "Administration",
                  onClick: undefined,
                  underline: "none",
                },
              ]}
              currentCrumb={"Organisations"}
            ></Breadcrumb>
          </Grid>
          <Grid container spacing={2} padding={"10px"}>
            <Grid item xs={12}>
              {organizations.length === 0 && (
                <Paper
                  sx={{
                    padding: "20px",
                    margin: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ m: 2 }}>
                    You do not have any organisations. Please click the button
                    below to add an organisation.
                  </Typography>
                  <Button
                    sx={{ m: 2 }}
                    variant="contained"
                    onClick={handleOpenForm}
                    color="primary"
                  >
                    Add Organisation
                  </Button>
                  <OrganizationDialog
                    isOpen={formOpen}
                    onClose={() => handleCloseForm()}
                    onSubmit={handleSubmit}
                    formData={selectedOrg}
                    legalEntities={legalEntities}
                  />
                </Paper>
              )}
            </Grid>
            {organizations.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    onClick={() => handleOpenForm()}
                    color="primary"
                  >
                    Add Organisation
                  </Button>
                  <OrganizationDialog
                    isOpen={formOpen}
                    onClose={() => handleCloseForm()}
                    onSubmit={handleSubmit}
                    formData={selectedOrg}
                    legalEntities={legalEntities}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DynamicTable
                    data={organizations}
                    columns={myColumns}
                    rowsPerPage={5}
                  />
                  <GenericConfirmDialog
                    open={confirmOpen}
                    onCancel={() => setConfirmOpen(false)}
                    onConfirm={handleConfirm}
                    title="Confirm Deletion"
                    content="Are you sure you want to delete this organisation?"
                  />
                  <NotificationDialog
                    open={notificationOpen}
                    handleClose={() => setNotificationOpen(false)}
                    title={notificationTitle}
                    subtitle="" // Optionally provide a subtitle if needed
                    text={notificationMessage}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default OrganizationSettings;
