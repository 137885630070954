import React, { useEffect, useMemo, useState, forwardRef, useImperativeHandle } from 'react';
import { FormControlLabel, Checkbox, Paper, Typography, Box } from "@mui/material";
import { useFetchData } from "../../hooks/useFetchData";
import { Farm } from "../../models/farm.interface";
import { Controller, useFormContext } from "react-hook-form";
import FormSection from "../molecules/DynamicFormSection";
import { getOrganizationFarms } from "../../services/api-ffm-service";
import { Organization } from '../../models/organization.interface';

interface FieldFormProps {
  initialFieldData: any;
  onFieldDataChange: (data: any) => void;
  polygonData: any;
  control: any;
  errors: any;
  setValue: any;
  selectedOrganization: Organization | undefined;
}

const IrrigatedFieldController: React.FC<{ control: any }> = ({ control }) => (
  <Controller
    name="irrDry"
    control={control}
    defaultValue="Dry"
    render={({ field }) => (
      <FormControlLabel
        control={
          <Checkbox
            {...field}
            checked={field.value === "Irrigated"}
            onChange={(e) =>
              field.onChange(e.target.checked ? "Irrigated" : "Dry")
            }
            color="primary"
          />
        }
        label="Irrigated"
      />
    )}
  />
);

const SeasonalFieldController: React.FC<{ control: any }> = ({ control }) => (
  <Controller
    name="seasonalField"
    control={control}
    render={({ field }) => (
      <FormControlLabel
        control={
          <Checkbox
            {...field}
            checked={field.value}
            onChange={(e) => field.onChange(e.target.checked)}
            color="primary"
          />
        }
        label="Seasonal"
      />
    )}
  />
);

const OnBoardingFieldForm: React.ForwardRefExoticComponent<FieldFormProps & React.RefAttributes<any>> = forwardRef(({
  initialFieldData,
  onFieldDataChange,
  polygonData,
  control,
  errors,
  setValue,
  selectedOrganization
}, ref) => {
  const [farms, setFarms] = useState<Farm[]>([]);

  const { handleSubmit, trigger } = useFormContext();
  
  useFetchData(getOrganizationFarms, setFarms, undefined, [
    selectedOrganization?.organizationId ?? 0,
  ]);

  useEffect(() => {
    if (initialFieldData?.area) {
      setValue("size", `${initialFieldData.area} ha`);
    }
  }, [initialFieldData, setValue]);

  useEffect(() => {
    if (polygonData?.area) {
      setValue("size", polygonData.area);
    }
  }, [polygonData, setValue]);

  useImperativeHandle(ref, () => ({
    resetForm: () => {
      // Logic to reset the form
      setValue("fieldName", initialFieldData?.fieldName || "");
      setValue("farmId", initialFieldData?.farmId || "");
      setValue("irrDry", initialFieldData?.irrDry || "Dry");
      setValue("area", initialFieldData?.area || "");
    },
    submitForm: async () => {
      const isValid = await trigger();
      if (isValid) {
        handleSubmit(onFieldDataChange)();
      }
    },
    selectedOrganization: selectedOrganization
  }));

  const fieldDefinitions = useMemo(() => ({
    fieldDetails: [
      { id: "fieldName", label: "Field Name", type: "text" },
      { id: "size", label: "Size", type: "text", readOnly: true },
    ],
    irrDry: [{ id: "irrDry", label: "Irrigated", type: "checkbox" }],
    farm: [
      {
        id: "farmId",
        label: "Farm",
        type: "select",
        options: farms.map((farm) => ({
          value: farm.farmIdentifier,
          label: farm.farm,
          id: farm.farmId,
        })),
      },
    ],
    seasonalField: [
      { id: "seasonalField", label: "Seasonal", type: "checkbox" },
    ],
    fieldHistory: [
      { id: "activities", label: "Activities", type: "text" },
      { id: "cropHistory", label: "Crop History", type: "text" },
      { id: "notes", label: "Notes", type: "text" },
    ],
  }), [farms]);

  return (
    <Paper elevation={0} sx={{ marginTop: 1, padding: 1, minHeight: "450px" }}>
      <Typography variant="h6">Field Details</Typography>
      <Box sx={{ overflow: "auto" }}>
        <FormSection
          fields={fieldDefinitions.fieldDetails}
          control={control}
          errors={errors}
          columns={1}
          title=""
        />
        <IrrigatedFieldController control={control} />
        <SeasonalFieldController control={control} />
        <FormSection
          title=""
          fields={fieldDefinitions.farm}
          control={control}
          errors={errors}
          columns={1}
        />
      </Box>
    </Paper>
  );
});

export default OnBoardingFieldForm;
