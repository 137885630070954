import React, { useRef, useState } from "react";
import { Grid, Button, Paper, Typography, Chip, Box, AlertColor } from "@mui/material";
import {
  getNotes,
  deleteNote,
  createNote,
  updateNote,
} from "../../services/api-ffm-service";
import NotesDialog from "../organisms/notesDialog";
import GenericConfirmDialog from "../organisms/genericConfirmDialog";
import moment from "moment";
import DynamicChip from "../atom/dynamicChip";
import FileDisplay from "../organisms/fileDisplay";
import Loading from "./loading";
import ActionButtons from "../molecules/actionButtons";
import { Breadcrumb } from "../atom/breadcrumbs";
import { useNavigate } from "react-router-dom";
import { fetchData, useFetchData } from "../../hooks/useFetchData";
import CardList from "../organisms/cardTiles";
import { handleApiResponse } from "../../utils/Utilities";
import CustomSnackbar from "../atom/CustomSnackbar";
import { useConfig } from "../../context/ConfigContextTest";

const Notes: React.FC = () => {
  const [notes, setNotes] = useState<any[]>([]);
  const [formOpen, setFormOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedNote, setSelectedNote] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { selectedOrganization, noteTypes, mappedfields } = useConfig();
  const snackbarRef = useRef<{ handleOpen: (title: string, msg: string | null, sev: AlertColor) => void }>(null);

  useFetchData(getNotes, setNotes, setIsLoading, [
    selectedOrganization?.organizationId ?? 0,
  ]);

  const handleOpenForm = () => {
    setFormOpen(true);
    setSelectedNote(null);
  };

  const handleCloseForm = () => {
    setSelectedNote(null);
    setFormOpen(false);
    setIsLoading(false);
    setConfirmOpen(false);
  };

  const handleEdit = (note) => {
    setSelectedNote(note);
    setFormOpen(true);
  };

  const handleDelete = (note) => {
    setSelectedNote(note);
    setConfirmOpen(true);
  };

  const showSnackbar = (title, message, severity) => {
    if (snackbarRef.current) {
      snackbarRef.current.handleOpen(title, message, severity);
    }
  };

  const handleSubmit = async (formData: any) => {
    setIsLoading(true);

    const newFormData: any = {
      azureUserId: selectedOrganization?.azureUserId,
      noteId: formData.noteId,
      partyId: selectedOrganization?.partyId,
      Properties: formData.noteProperty,
      noteDocuments: [],
    };

    const convertFileToBase64 = (file: File): Promise<string> =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>
          resolve(reader.result?.toString().split(",")[1] || "");
        reader.onerror = (error) => reject(error);
      });

    if (formData.noteDocuments) {
      for (const doc of formData.noteDocuments) {
        const newDoc: any = {
          name: doc.name,
          noteDocumentId: doc.noteDocumentId,
          documentExtention: doc.documentExtention
        };

        if (doc.attachment && doc.noteDocumentId === 0) {
          newDoc.attachment = await convertFileToBase64(doc.attachment);
        }

        newFormData.noteDocuments.push(newDoc);
      }
    }

    newFormData.title = formData.title;
    newFormData.description = formData.description;
    newFormData.noteTypeId = formData.noteTypeId;
    newFormData.fieldId = formData.fieldId;

    let response, title;
    console.log(newFormData);

    if (selectedNote) {
      try {
        response = await updateNote(newFormData);
        title = "Note successfully updated";
      } catch (error) {
        console.error("Error updating note:", error);
      }
    } else {
      try {
        response = await createNote(newFormData);
        title = "Note successfully created";
      } catch (error) {
        console.error("Error creating note:", error);
      }
    }

    fetchData(getNotes, setNotes, setIsLoading, [
      selectedOrganization?.organizationId ?? 0,
    ]);

    handleApiResponse(response, showSnackbar, {title: title});
    handleCloseForm();
  };

  const handleConfirmDelete = async () => {
    let response;

    if (selectedNote) {
      try {
        setIsLoading(true);
        response = await deleteNote(selectedNote.noteId);

        if(response.status === 200){
          setNotes(notes.filter((note) => note.noteId !== selectedNote.noteId));
        }
        setConfirmOpen(false);
        handleCloseForm();
        setIsLoading(false);

      } catch (error) {
        console.error("Failed to delete note - notesPage.tsx:", error);
      }

      handleApiResponse(response, showSnackbar, {title: "Note successfully deleted"});
    }
  };

  const myColumns = [
    {
      label: "Title",
      dataKey: "title",
      renderCell: (item) => <span>{item.title}</span>,
    },

    {
      label: "Description",
      dataKey: "description",
      renderCell: (item) => <span>{item.description}</span>,
    },
    {
      label: "Type",
      dataKey: "noteType",
      renderCell: (item) => (
        <DynamicChip name={item.noteType} types={noteTypes} />
      ),
    },
    {
      label: "Field",
      dataKey: "field",
      renderCell: (item) => {
        const field = mappedfields.find((field) => field.fieldId === item.fieldId);
        return field && <Chip label={field ? field.name : item.fieldId}></Chip>;
      },
    },
    {
      label: "Attachments",
      dataKey: "attachments",
      renderCell: (item) =>
        item.noteDocuments && item.noteDocuments.length > 0 ? (
          <Box style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
            {item.noteDocuments.map((doc, index) => (
              <FileDisplay
                key={index}
                fileType={doc.documentExtention || "unknown"}
                fileUrl={doc.documentLink || ""}
                fileName={doc.name}
                showTooltip={true}
              />
            ))}
          </Box>
        ) : null,
    },
    {
      label: "Date",
      dataKey: "date",
      renderCell: (item) => (
        <p>{moment(item.createdDate).format("DD MMMM YYYY")}</p>
      ),
    },
    {
      label: "",
      dataKey: "actionBtns",
      renderCell: (item) => (
        <ActionButtons
          onEdit={() => handleEdit(item)}
          onDelete={() => handleDelete(item)}
        ></ActionButtons>
      ),
    },
  ];

  const navigate = useNavigate();
  function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();
    navigate(-1);
  }

  const sortedNotes = notes.sort((a, b) => moment(b.createdDate).diff(moment(a.createdDate)));

  return (
    <>
      <CustomSnackbar ref={snackbarRef} />
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <Grid item xs={12}>
            <Breadcrumb
              crumbs={[
                { text: "<< Back", onClick: handleClick, underline: "hover" },
                {
                  text: "Operations",
                  onClick: undefined,
                  underline: "none",
                },
              ]}
              currentCrumb={"Notes"}
            ></Breadcrumb>
          </Grid>
          <Grid container spacing={2} padding={"10px"}>
            <Grid item xs={12}>
              {notes.length === 0 && (
                <Paper
                  sx={{
                    padding: "20px",
                    margin: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ m: 2 }}>
                    You do not have any notes. Please click the button below to
                    add a note.
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={handleOpenForm}
                    color="primary"
                  >
                    Add Note
                  </Button>
                </Paper>
              )}
              <NotesDialog
                isOpen={formOpen}
                onClose={handleCloseForm}
                onSubmit={handleSubmit}
                formData={selectedNote}
              />
            </Grid>
            {notes.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Button
                    sx={{ ml: 2 }}
                    variant="contained"
                    onClick={handleOpenForm}
                    color="primary"
                  >
                    Add Note
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <CardList
                    data={sortedNotes}
                    columns={myColumns}
                    rowsPerPage={8}
                    notes={true}
                  />
                  <GenericConfirmDialog
                    open={confirmOpen}
                    onCancel={() => setConfirmOpen(false)}
                    onConfirm={handleConfirmDelete}
                    title="Confirm Deletion"
                    content="Are you sure you want to delete this note?"
                  />
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default Notes;
