import React from "react";
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  CircularProgress,
  Chip,
  Grid,
} from "@mui/material";
import FFheader from "../../assets/logos/headerReport.png";
import FFfooter from "../../assets/logos/footerReport.png";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Link,
  Image,
} from "@react-pdf/renderer";

interface SummaryProps {
  selectCategory: string[];
  selectedFields: any[];
  dateRange: string;
  activities: any[];
  date: string;
}

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
  },
  paddedContainer: {
    padding: 20,
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
    fontWeight: "bold",
    color: "#333",
  },
  caption: {
    fontSize: 10,
    marginTop: 2,
    marginBottom: 5,
    textAlign: "right",
    color: "#666",
  },
  headerImage: {
    width: "100%",
    height: 90,
    marginBottom: 10,
  },
  footerImage: {
    width: "100%",
    height: 78,
    marginTop: 10,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 300,
    marginBottom: 5,
  },
  chipContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 5,
  },
  chip: {
    backgroundColor: "#e0e0e0",
    borderRadius: 16,
    padding: "5px 10px",
    margin: "0 4px 4px 0",
    fontSize: 12,
  },
  table: {
    width: "100%",
    marginTop: 15,
    border: "1px solid #ddd",
    borderRadius: 5,
    overflow: "hidden",
  },
  tableRow: {
    flexDirection: "row",
    borderBottom: "2px solid #ddd",
  },
  tableCell: {
    flex: 1,
    padding: 8,
    fontSize: 12,
    color: "#444",
  },
  footer: {
    marginTop: "auto",
    fontSize: 12,
    textAlign: "center",
  },
  link: {
    color: "#3C4F1E",
    textDecoration: "underline",
    fontStyle: "italic",
  },
});

const ActivitySummaryReport: React.FC<SummaryProps> = ({
  dateRange,
  activities,
  selectCategory,
  selectedFields,
  date,
}) => {
  return (
    <Paper elevation={3} style={{ padding: "16px" }}>
      <Typography variant="h5" gutterBottom>
        Activity Summary Report
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        <b> Category(s):</b>
        {selectCategory?.map((category, index) => (
          <Chip key={index} label={category} sx={{ ml: 1, mb: 1 }} />
        ))}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        <b> Field(s):</b>
        {selectedFields?.map((field, index) => (
          <Chip key={index} label={field.name} sx={{ ml: 1, mb: 1 }} />
        ))}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        <b> Date:</b> {dateRange}
      </Typography>

      <TableContainer component={Paper} style={{ marginTop: "16px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Activity</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Cost (R)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activities?.map((activity, activityIndex) =>
              activity.activityDetails?.map((detail, detailIndex) => (
                <TableRow key={`${activityIndex}-${detailIndex}`}>
                  <TableCell>{detail.activity}</TableCell>
                  <TableCell>{activity.activityCategory}</TableCell>
                  <TableCell>{detail.date}</TableCell>
                  <TableCell>R {detail.cost}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container>
        <Grid item xs={10.5}>
          <PDFDownloadLink
            document={
              <MyDocument
                selectCategory={selectCategory}
                selectedFields={selectedFields}
                dateRange={dateRange}
                activities={activities}
                date={date}
              />
            }
            fileName="activity_summary_report.pdf"
          >
            {({ blob, url, loading, error }) => (
              <Button
                sx={{ marginTop: 1 }}
                variant="contained"
                color="primary"
                startIcon={loading ? <CircularProgress size={20} /> : null}
              >
                {loading ? "Loading..." : "Export as PDF"}
              </Button>
            )}
          </PDFDownloadLink>
        </Grid>
        <Grid item xs={1.5}>
          <Typography variant="caption" gutterBottom>
            Generated {date}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

const MyDocument: React.FC<SummaryProps> = ({
  selectCategory,
  selectedFields,
  dateRange,
  activities,
  date,
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Image style={styles.headerImage} src={FFheader} />
      <View style={styles.paddedContainer}>
        <Text style={styles.title}>Activity Summary Report</Text>
        <Text style={styles.subtitle}>Category(s): </Text>
        <View style={styles.chipContainer}>
          {selectCategory?.map((category, index) => (
            <View key={index} style={styles.chip}>
              <Text>{category}</Text>
            </View>
          ))}
        </View>
        <Text style={styles.subtitle}>Field(s):</Text>
        <View style={styles.chipContainer}>
          {selectedFields?.map((field, index) => (
            <View key={index} style={styles.chip}>
              <Text>{field.name}</Text>
            </View>
          ))}
        </View>
        <Text style={styles.subtitle}>Date Range: {dateRange}</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Activity</Text>
            <Text style={styles.tableCell}>Category</Text>
            <Text style={styles.tableCell}>Date</Text>
            <Text style={styles.tableCell}>Cost (R)</Text>
          </View>
          {activities?.map((activity, activityIndex) =>
            activity.activityDetails?.map((detail, detailIndex) => (
              <View
                key={`${activityIndex}-${detailIndex}`}
                style={styles.tableRow}
              >
                <Text style={styles.tableCell}>{detail.activity}</Text>
                <Text style={styles.tableCell}>
                  {activity.activityCategory}
                </Text>
                <Text style={styles.tableCell}>{detail.date}</Text>
                <Text style={styles.tableCell}>R {detail.cost}</Text>
              </View>
            ))
          )}
        </View>
        <Text style={styles.caption}>Generated {date} </Text>
      </View>

      <Text style={styles.footer}>
        Like what you see? Visit{" "}
        <Link src="http://farmersfriend.co/" style={styles.link}>
          Farmers Friend
        </Link>{" "}
        today.
      </Text>
      <Image style={styles.footerImage} src={FFfooter} />
    </Page>
  </Document>
);

export default ActivitySummaryReport;
