import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../auth-config";
import { setAzureUserId } from "../../services/api-ffm-service";
import {
  Box,
  Paper,
  Typography,
  Button,
  Container,
  CircularProgress,
  Grid,
  Link,
} from "@mui/material";
import logo from "../../logo.svg";

interface LogoutPageProps {
  onSignUpClick: () => void;
}

const LogoutPage: React.FC<LogoutPageProps> = ({ onSignUpClick }) => {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const activeAccount = instance.getActiveAccount();
    if (activeAccount) {
      setAzureUserId(activeAccount.localAccountId);
    }
  }, [instance]);

  const handleLoginRedirect = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      await instance.loginRedirect(loginRequest);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 4,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Welcome to
        </Typography>
        <Box
          component="img"
          src={logo}
          alt="logo"
          sx={{ height: 150, mb: 4 }}
        />
        <Typography variant="body1" gutterBottom>
          Please sign in or sign up by clicking the button below.
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleLoginRedirect}
              sx={{ mt: 2 }}
              disabled={isLoading}
            >
              Sign in
              {isLoading && (
                <CircularProgress color="inherit" size={15} sx={{ ml: 2 }} />
              )}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              sx={{ mt: 2 }}
              disabled={isLoading}
              onClick={onSignUpClick}
            >
              Sign Up
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              mt: 2,
            }}
          >
            <Typography variant="caption" gutterBottom>
              By clicking Sign Up or Sign In, you agree to our{" "}
              <Link
                href="https://stfarmersfrienddev.blob.core.windows.net/b2c-documents/FF_Terms_and_Conditions_20240730_v1.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms, Privacy Policy, and Cookies Policy.{" "}
              </Link>
              You may receive SMS and email notifications from us.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default LogoutPage;
