import React, { useState, useEffect } from 'react';
import { Grid, Typography } from "@mui/material";
import FieldMapComponent from "../molecules/FieldMapComponent";
import FarmFieldManagement from "../organisms/farmManageCard";
import { Breadcrumb } from "../atom/breadcrumbs";
import { useNavigate } from "react-router-dom";

import { getOrganizationFarms } from "../../services/api-ffm-service";
import { getLinkedFields } from "../../services/api-gs-service"; 


import { Farm } from "../../models/farm.interface";

import { useConfig } from "../../context/ConfigContextTest";

import { usePopup } from '../../context/PopupContext';



const FarmManagement = () => {

  const [farms, setFarms] = useState<Farm[]>([]);
  const [mapLoaded, setMapLoaded] = useState(false);
  const { selectedOrganization } = useConfig();

  const navigate = useNavigate();
  const { setShowMappingPopup } = usePopup();

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();
    navigate(-1);
  }

  const isFarmOverviewPage = true;
  const isCropDataPage = false;

  useEffect(() => {
    const fetchFarms = async () => {
      if (selectedOrganization?.organizationId) {
        const farmsData = await getOrganizationFarms(selectedOrganization.organizationId);
        setFarms(farmsData);
      }
    };

    fetchFarms();
  }, [selectedOrganization]);

  useEffect(() => {
    const checkLinkedFields = async () => {
      for (const farm of farms) {
        const fieldsData = await getLinkedFields(farm.farmIdentifier);
        if (fieldsData.length > 0) {
          return; 
        }
      }
      
      if (farms.length > 0) {
        navigate("/fields", { state: { isAddingField: true } });
        setShowMappingPopup(true);
      }
    };
    
    if (farms.length > 0) {
      checkLinkedFields();
    }
  }, [farms, navigate, setShowMappingPopup]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Breadcrumb
          crumbs={[
            { text: "<< Back", onClick: handleClick, underline: "hover" },
            { text: "My Farm", onClick: undefined, underline: "none" },
          ]}
          currentCrumb={"Farm Manager"}
        ></Breadcrumb>
      </Grid>
      <Grid item xs={12} sx={{ p: 2, pb: 0 }}>
        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          Farm Manager
        </Typography>
      </Grid>
      <Grid container>
        <Grid id="farm-field-manage-wrapper" item xs={2.5}>
          <FarmFieldManagement></FarmFieldManagement>
        </Grid>
        <Grid item xs={9.5}>  
          <FieldMapComponent
            height="550px"
            onLoad={handleMapLoad}
            isFarmOverviewPage={isFarmOverviewPage}
            isCropDataPage={isCropDataPage}
          ></FieldMapComponent>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FarmManagement;