import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface CheckboxesTagsProps {
  types: any[];
  label: string;
  onChange: any;
  value: any;
  multiple?;
}

const CheckboxesTags: React.FC<CheckboxesTagsProps> = ({
  types,
  label,
  onChange,
  value,
  multiple,
}) => {
  const defaultOptions = types || [];
  return (
    <Autocomplete
      multiple={multiple}
      limitTags={5}
      id="checkboxes-tags-demo"
      options={defaultOptions}
      disableCloseOnSelect
      getOptionLabel={(option) => option?.name || option?.date}
      isOptionEqualToValue={(option, value) => 
        option?.fieldId === value?.fieldId && option?.date === value?.date}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option?.name || option?.date}
        </li>
      )}
      fullWidth
      value={multiple ? types.filter((type) => value.includes(type.fieldId)) : defaultOptions.find((option) => option.fieldId === value) || null} //idk how this works
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          margin="dense"
          label={label}
        />
      )}
      onChange={onChange}
    />
  );
};

export default CheckboxesTags;
