import * as React from "react";
import {
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import FormSection from "../molecules/DynamicFormSection";
import { ForwardRefRenderFunction, useImperativeHandle } from "react";
import { GoogleLocation } from "../../interfaces/googleLocation.interface";
import GoogleAutoSearchBar from "../molecules/GoogleAutoSearchBar";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = z.object({
    partyId: z.number().optional(),
    contactName: z.string().min(1, "Name is required"),
    contactSurname: z.string().min(1, "Surname is required"),
    vatNumber: z.string().optional(),
    farmName: z.string().optional(),
    contactNumber: z
    .string()
    .length(10, "Mobile Phone must be exactly 10 digits")
    .regex(phoneRegExp, "Please enter a valid Mobile Phone"),
    contactEmail: z.string().email("Invalid email address").min(1, "Email is required"),
    orgName: z.string().min(1, "Name is required"),
    registrationNumber: z.string().min(1, "Registration number/RSAID is required"),
    legalEntityTypeId: z.number().min(1, "Legal entity name is required"),
    selectedLocation: z
    .object({
      address: z.string(),
      coordinates: z.object({
        lat: z.number(),
        lng: z.number(),
      }),
      components: z.object({
        street: z.string(),
        city: z.string(),
        postalCode: z.string(),
      }),
    })
    .optional(),
  });

type OnBoardingOrganisationFormProps = {
  onSubmit: (formData: any) => Promise<void>;
  legalEntities: any[];
};

interface OrganizationFormData {
  partyId?: number;
  contactName: string;
  contactSurname: string;
  vatNumber: string;
  contactNumber: string;
  contactEmail: string;
  orgName: string;
  registrationNumber: string;
  legalEntityTypeId: number;
  farmName: string;
  selectedLocation?: GoogleLocation;
}

const OnBoardingOrganisationForm: ForwardRefRenderFunction<
  unknown,
  OnBoardingOrganisationFormProps
> = ({ onSubmit, legalEntities }, ref) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<OrganizationFormData>({
    resolver: zodResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      contactName: "",
      contactSurname: "",
      contactNumber: "",
      contactEmail: "",
      vatNumber: "",
      orgName: "",
      farmName: "",
      registrationNumber: "",
      legalEntityTypeId:
      legalEntities.length > 0 ? legalEntities[0].legalEntityTypeId : 0,
    },
  });

  useImperativeHandle(ref, () => ({
    submitForm: handleSubmit(onSubmit),
  }));

  const handleLocationChange = (location: GoogleLocation) => {
    setValue("selectedLocation", location);
  };
  
  const fieldDefinitions = {
    organizationDetails: [
      { id: "orgName", label: "Organisation Name", type: "text" },
      { id: "vatNumber", label: "VAT Number", type: "text" },
      { id: "registrationNumber", label: "Registration Number/RSAID", type: "text" },
      {
        id: "legalEntityTypeId",
        label: "Legal Entity Type",
        type: "select",
        options: legalEntities.map((entity) => ({
          id: entity.legalEntityTypeId,
          label: entity.name,
          value: entity.legalEntityTypeId,
        })),
      },
    ],
    contact: [
      { id: "contactName", label: "Name", type: "text" },
      { id: "contactSurname", label: "Surname", type: "text" },
      { id: "contactNumber", label: "Contact Number", type: "text" },
      { id: "contactEmail", label: "Email", type: "email" },
    ]
  };

  return (
    <Container> 
      <Typography variant="h5">Add Organisation</Typography>
      <form>
        <FormSection
          title="Organisation Details"
          fields={fieldDefinitions.organizationDetails}
          control={control}
          errors={errors}
          columns={2}
        />
        <FormSection
          title="Contact Details"
          fields={fieldDefinitions.contact}
          control={control}
          errors={errors}
          columns={2}
        />
        <Grid item xs={12}>
              <Typography variant="subtitle1">Location</Typography>
            </Grid>
            <Controller
              name="selectedLocation"
              control={control}
              render={({ field }) => (
                <GoogleAutoSearchBar
                  onLocationChange={(location) => {
                    const updatedLocation: GoogleLocation = {
                      ...location,
                      components: {
                        street: location.components?.street || "",
                        city: location.components?.city || "",
                        postalCode: location.components?.postalCode || "",
                      },
                    };
                    field.onChange(location.address);
                    handleLocationChange(updatedLocation);
                  }}
                />
              )}
            />

        <Typography variant="h5">Add Farm</Typography>
          <FormSection
            fields={[{ id: "farmName", label: "Farm Name", type: "text" }]}
            control={control}
            errors={errors}
            columns={2}
          />
      </form>
    </Container>
  );
};

export default React.forwardRef(OnBoardingOrganisationForm);
